// SOP-97 Included by PCS [Ticketing Masters -Closure Information]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
   
} from '@mui/material';
import Typography from '@mui/material/Typography';

import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import './EditTicketingClosureInformation.scss'
import Swal from "sweetalert2";

// Page start
const EditTicketingClosureInformation = 
// To get handleEditClosureInformationClose & getclosureinformationlistsearch from closureinformation page
({
    handleEditClosureInformationClose,
    getclosureinformationlistsearch,
    ClosureInformationId
}: {
    handleEditClosureInformationClose: (event: React.MouseEvent) => void,
    getclosureinformationlistsearch: (event: React.MouseEvent) => void,
    ClosureInformationId: any;
}) =>{

    //  use state declaration
    const [ClosureInformationError, setClosureInformationError]=useState(false)
    const [ClosureInformationValue, setClosureInformationValue]=useState('')
    const [ClosureInformationErrorMessage, setClosureInformationErrorMessage]=useState('')
    const [isActive, setIsActive] = useState(true); //SOP-97 Included By PCS
    
    //[ to avoid duplicate ClosureInformation type]
    const [ClosureInformationValueEndTrim, setClosureInformationValueEndTrim]=useState('')
     
    //SOP-97 Inlcuded By PCS [toggle button]
    const handleToggleActive = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };
    //EOF SOP-97 Included By PCS
 
    //  Handle ClosureInformation Type while typing ClosureInformation and validations
    const handleClosureInformationChange = (event:any) => {
        const trimmedClosureInformationValue = event.target.value.trim();
        setClosureInformationValue(event.target.value);

         //[ to avoid duplicate ClosureInformation ]
         setClosureInformationValueEndTrim(trimmedClosureInformationValue);
        

        if (trimmedClosureInformationValue === '') {
            setClosureInformationError(true);
            setClosureInformationErrorMessage("Closure Information is required");
        } 
        else if( trimmedClosureInformationValue.length >255 ){
            setClosureInformationError(true);
            setClosureInformationErrorMessage("Max 255 characters");
        }
        else {
            setClosureInformationError(false);
            setClosureInformationErrorMessage("");
        }
    };

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditClosureInformationClose && typeof handleEditClosureInformationClose === 'function') {
            handleEditClosureInformationClose(event);
        }
    };

    // to render handlegetclosureinformationlistsearch function after closing dialouge box
    const handlegetclosureinformationlistsearch= (event: React.MouseEvent) => {
        if (getclosureinformationlistsearch && typeof getclosureinformationlistsearch === 'function') {
            getclosureinformationlistsearch(event);
        }
    };

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId; //SOP-97 Included by PCS
    const UserId = GlobalData.GlobalUserId;//SOP-97 Included by PCS
   

    // To get ClosureInformation  details as per the id
    const GetClosureInformationListById =()=>{
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_closureinformation_list_by_id`, {
            ClosureInformationId:ClosureInformationId,
            CompanyId:CompanyId//SOP-97 Inlcuded By PCS
           
         })
         .then((res: any) => 
         { 
           // console.log("res",res)
            const ClosureInformationData = res.data.data[0]
            //console.log("ClosureInformationData",ClosureInformationData)
            setClosureInformationValue(ClosureInformationData.sys_ticket_closure_information_name)
            //SOP-97 Inlcuded By PCS
            if(ClosureInformationData.active === 'Y'){
                setIsActive(true)
            }
            else{
                setIsActive(false)
            }
             //EOF SOP-97 Inlcuded By PCS
           
         }).catch((error) =>  
         { 
          Failed(error.response.message);
         });
    }

    // Exist ClosureInformation name API
        const GetClosureInformationNameExist = async () => {   
        
            const  ExistClosureInformationNamePayload = 
            {
               ExistClosureInformationName:ClosureInformationValueEndTrim,
               ClosureInformationId:ClosureInformationId,
               CompanyId:CompanyId //SOP-97 Included By PCS
      
            }
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_closureinformation_name_exists`,ExistClosureInformationNamePayload);
            //console.log(res.data.data);
            return res.data.data || [];
            } 
            catch (err) {
            console.log( "co",err); 
            }
        };


    // To Update ClosureInformation 
      // Define a type for errors
     type Errors = {
        closureinformation?:boolean;
                  };

    const HandleUpdateClosureInformation = async (event:any)=>
    {
        event.preventDefault();
    
        let errors: Errors = {};

        const ClosureInformationNameList = await GetClosureInformationNameExist();
        if (ClosureInformationNameList.length > 0) {
            setClosureInformationError(true);
            setClosureInformationErrorMessage("Closure Information already exists");
            errors.closureinformation = true;
            return;
        }
    
        const trimmedClosureInformationValue = ClosureInformationValue.trim();
        if (trimmedClosureInformationValue === '') {
            errors.closureinformation = true;
            setClosureInformationErrorMessage("Closure Information is required");
        } else if (trimmedClosureInformationValue.length > 255) {
            errors.closureinformation = true;
            setClosureInformationErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setClosureInformationError(errors.closureinformation || false);
            return;
        }

        
         //SOP-97 Inlcuded By PCS
         let EditActive ;
    
         if( isActive === true){
            EditActive = "Y"
         }
         else{
             EditActive='N'
         }
         //EOF SOP-97 Inlcuded By PCS

    
        const UpdateClosureInformationPayload =
        {
             SaveUpdateKey: "update",
             ClosureInformationName: ClosureInformationValue,
             ClosureInformationId: ClosureInformationId,
            //SOP-97 Included by PCS
            CompanyId:CompanyId,
            Active: EditActive,
            LastModifiedBy: UserId,
            IsRemove: null
            //EOF SOP-97 Included by PCS
        }
    
      //  console.log("Update Payload", UpdateClosureInformationPayload);
    

           Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
            if (result.isConfirmed)
            {
                commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_closure_information_create_update`, UpdateClosureInformationPayload)
                    .then((res) => {
                        if (res.status === 200) {
                            Success("Closure Information Updated successfully");
                            handleClose(event);
                            handlegetclosureinformationlistsearch(event);
                        }
                        }).catch((error) => {
                            console.error("Error:", error);
                        });
            } 
            else  
            {
            return
            }
      });

  

    }
    

    // UseEffect declaration
    useEffect(() => {
        GetClosureInformationListById();
      }, []); 
 
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

// Design start
    return(
        <Box>
            <Box>
            <Box display={'flex'} justifyContent={'center'}>
                    <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'>
                        Edit Ticketing Closure Information
                        </Typography>
                    </Box>
                        <Box p={3}>
                            <form  noValidate>
                            <Grid container spacing={2}>
                            <Grid item lg={6} md={6} xs={12}>
                                <Box>
                                    <TextField
                                        onKeyDown={handleKeyDown}
                                        autoFocus
                                        type='text'
                                        variant="standard"
                                        label='Closure Information'
                                        name='ClosureInformation '
                                        style={{ margin: '0 10px' }}
                                        fullWidth
                                        required
                                        error={ClosureInformationError}
                                        onChange={handleClosureInformationChange}
                                        value={ClosureInformationValue}
                                        helperText={ClosureInformationErrorMessage}
                                    /> 
                                </Box>
                            </Grid>
                            </Grid>
                        {/*//SOP-97 Included By PCS  */}
                            <Box mt={2} ml={1}>
                            <Typography className="label_design" sx={{mt:1.5}}>
                            <label style={{fontSize:'0.7rem'}}>Active State</label>
                            </Typography>
                            <label className="switchToggle">
                            <input
                                type="checkbox"
                                checked={isActive}
                                onChange={handleToggleActive} 
                            />
                            <span className="sliderToggle round"></span>
                            </label>
                            </Box> 
                       {/* EOF SOP-97 Included By PCS  */}  
                        <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Button
                            sx={{
                                background: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                padding:{
                                    lg: "0 70px",
                                    md: "0 70",
                                    xs:'0 50px'},
                                       
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}

                            onClick={handleClose} 
                            >
                            Back
                            </Button>
                            <Button
                            sx={{
                                background: '#25425F',
                                border: 'none',
                                borderRadius: '5px',
                                padding:{
                                    lg: "0 70px",
                                    md: "0 70",
                                    xs:'0 50px'
                                },
                                "&:hover":{
                                    backgroundColor:'#25425F',
                                    color:'white',
                                },
                                color: 'white',
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                            type='submit'
                            onClick={(event)=>{
                                HandleUpdateClosureInformation(event);
                            }}
                            >
                            Update
                            </Button>
                        </Box>
                        </form>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}


export default EditTicketingClosureInformation;
// EOF SOP-97 Included by PCS [Ticketing Masters- Closure Information]