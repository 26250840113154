// SOP-92 Included By PCS [In Homepage ,to View asset details from ' My Asset' Grid]
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormHelperText,
    useTheme,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FcInfo } from "react-icons/fc";
import { FaUserGear } from "react-icons/fa6";
import { BsBuildingsFill } from "react-icons/bs";
import { IoMdPhotos } from "react-icons/io";
import Swal from "sweetalert2";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { GrSystem } from "react-icons/gr";
import { PiMedalFill } from "react-icons/pi";
import { LiaEditSolid } from "react-icons/lia";
import { set } from 'date-fns';

const ViewAssetPage =()=>{
  
     const navigate = useNavigate();
     const {id} = useParams();
     const AssetId = id

    // UseState declaration..
    const [CategoryValue, setCategoryValue] = useState('')
    const [SubCategoryValue, setSubCategoryValue] = useState('')
    const [AssetNameValue, setAssetNameValue]=useState('')
    const [ModelNumberValue, setModelNumberValue]=useState('')
    const [SerialNumberValue, setSerialNumberValue]=useState('')
    const [ManufacturerValue, setManufacturerValue] = useState('')
    const [PurchaseDateValue, setPurchaseDateValue] = useState<dayjs.Dayjs | null>(null);
    const [InvoiceNumberValue, setInvoiceNumberValue] = useState('')
    const [InvoiceDateValue, setInvoiceDateValue] = useState<dayjs.Dayjs | null>(null);
    const [PriceValue, setPriceValue] = useState('0.00')
    const [ShipDateValue, setShipDateValue] = useState<dayjs.Dayjs | null>(null);
    const [ServiceTagNumberValue, setServiceTagNumberValue]=useState('')
    const [IpAddressValue, setIpAddressValue] = useState('')
    const [OperatingSystemValue, setOperatingSystemValue] = useState('')
    const [ProcessorTypeValue, setProcessorTypeValue] = useState('')
    const [MemoryInGbValue, setMemoryInGbValue] = useState('')
    const [ExpressServiceCodeValue, setExpressServiceCodeValue] = useState('')
    const [LocationValue, setLocationValue] = useState('')
    const [WarrantyStartDateValue, setWarrantyStartDateValue] = useState<dayjs.Dayjs | null>(null);
    const [WarrantyEndDateValue, setWarrantyEndDateValue] = useState<dayjs.Dayjs | null>(null);
    const [AMCStartDateValue, setAMCStartDateValue] = useState<dayjs.Dayjs | null>(null);
    const [AMCEndDateValue, setAMCEndDateValue] = useState<dayjs.Dayjs | null>(null);
    const [ToSetWarranty, setToSetWarranty] = useState(false)
    const [ToSetAMC, setToSetAMC] = useState(false)
    const [disableWarrantyDate,setDisableWarrantyDate] = useState(true)
    const [disableAMCDate,setDisableAMCDate] = useState(true)
    const [NotesValue, setNotesValue] = useState('')

    // Global data from session storage
        const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
        const GlobalData = JSON.parse(GlobalDataFromSession);
        let CompanyId = GlobalData.GlobalCompanyId
        let CreatedBy = GlobalData.GlobalUserId
          
   // To get Asset details by assetid
      const GetAssetDetailsById =()=>
                {
                    commonService.postService(`${process.env.REACT_APP_BACKEND_URL}/master/getAssetById`, {
                    AssetId:AssetId,
                    CompanyId:CompanyId,
                    })
                   .then((res: any) => 
                   { 
                   const AssetData = res.data.data[0]
                  //  console.log("View AssetDetails PCS..",AssetData)
                   setCategoryValue(AssetData.categoryname)
                   setSubCategoryValue(AssetData.subcategoryname)
                   setAssetNameValue(AssetData.assetname)
                   setModelNumberValue(AssetData.modelnumber)
                   setSerialNumberValue(AssetData.serialnumber)
                   setManufacturerValue(AssetData.manufacturer)

                   setPurchaseDateValue(AssetData.purchasedate)
                   setInvoiceNumberValue(AssetData.invoicenumber)
                   setInvoiceDateValue(AssetData.invoicedate)
                   setPriceValue(AssetData.price)
                   setShipDateValue(AssetData.shipdate)

                   setServiceTagNumberValue(AssetData.servicetagnumber) 
                   setIpAddressValue(AssetData.ipaddress)
                   setOperatingSystemValue(AssetData.operatingsystem)
                   setProcessorTypeValue(AssetData.processortype)
                   setMemoryInGbValue(AssetData.memoryingb)
                   setExpressServiceCodeValue(AssetData.expressservicecode)
                   setLocationValue(AssetData.location)

                   setWarrantyStartDateValue(AssetData.warrantystartdate)
                   setWarrantyEndDateValue(AssetData.warrantyenddate)
                   setAMCStartDateValue(AssetData.amcstartdate)
                   setAMCEndDateValue(AssetData.amcenddate)
                   setNotesValue(AssetData.notes)

                   if (AssetData.isunderwarranty === "Y")
                    {
                   setToSetWarranty(true)
                   setDisableWarrantyDate(false);
                    } 
                    else {
                    setToSetWarranty(false)
                    setDisableWarrantyDate(true)
                        }
            
                   if(AssetData.isunderamc === "Y")
                      {
                     setToSetAMC(true) 
                     setDisableAMCDate(false)
                      }
                    else {
                     setToSetAMC(false)
                     setDisableAMCDate(true)
                         }
            
                })
                }

    // UseEffect declaration
    useEffect(() => {
               GetAssetDetailsById(); 
       }, []);


return(
<>
<MainContainerWrapper
  headingText='View Asset'>
  <Box>
  <form noValidate>
{/* General Information Accordian */}
<Box>
<Accordion defaultExpanded sx={{borderRadius:'30px' }}>
<AccordionSummary
  expandIcon={<ArrowDropDownIcon />}
  aria-controls="panel1-content"
  id="panel1-header"
>
<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
<FcInfo height={'1rem'} width={'1rem'} fontSize={'1.5rem'} /> 
<Typography fontSize={'1rem'} ml={2}>General Information</Typography> 
</Box>
</AccordionSummary>
<AccordionDetails>
<Box >
       {/*  first row */}
        <Grid container mb={5} spacing={5} display={'flex'} justifyContent={'center'} >
           <Grid item lg={3.5} md={12} xs={12} >
                <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Category'
                name='Category'
                fullWidth
                required
                value={CategoryValue}
                /> 
          </Grid>
          <Grid item lg={3.5} md={12} xs={12} >
                <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Sub-Category'
                name='Sub-Category'
                fullWidth
                required
                value={SubCategoryValue}
                /> 
          </Grid>
          <Grid item lg={3.5} md={12} xs={12} >
                <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Asset Name'
                name='AssetName'
                fullWidth
                required
                value={AssetNameValue}
                /> 
           </Grid>
           </Grid>
      {/* End of 1st row*/}
      {/* Second row */}
           <Grid container mb={5} spacing={5} display={'flex'} justifyContent={'center'} >
            <Grid item lg={3.5} md={12} xs={12} >
                <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Model Number'
                name='ModelNumber'
                fullWidth
                value={ModelNumberValue}
                /> 
          </Grid>
          <Grid item lg={3.5} md={12} xs={12} >
                <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Serial Number'
                name='SerialNumber'
                fullWidth
                value={SerialNumberValue}
                /> 
          </Grid>
          <Grid item lg={3.5} md={12} xs={12} >
                <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Manufacturer'
                name='Manufacturer'
                fullWidth
                value={ManufacturerValue}
                /> 
          </Grid>
          </Grid>
       {/* End of 2nd row*/}
</Box>
</AccordionDetails>
</Accordion>
</Box>
{/* End of General Information Accordian */}
{/* Purchase Information Accordian */}
<Box mt={1}>
<Accordion defaultExpanded sx={{borderRadius:'30px'}}>
<AccordionSummary
    expandIcon={<ArrowDropDownIcon />}
    aria-controls="panel1-content"
    id="panel1-header"
>
<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
<BiSolidPurchaseTag height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
<Typography fontSize={'1rem'} ml={2}>Purchase Information</Typography> 
</Box>
</AccordionSummary>
<AccordionDetails>
<Box sx={{
         ml:{
         xl:12,
         lg:10,
         md:0,
         xs:0
           }
       }} >
 {/* Grid first row */}
      <Grid container mb={5} spacing={5} >
       <Grid item lg={3.7} md={12} xs={12} >
       <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
           <DatePicker
                  // readOnly
                  disabled //SOp-92_A testing feedback correction

                  label="Purchase Date"
                  value={PurchaseDateValue ? dayjs(PurchaseDateValue) : null}
                  format="DD-MM-YYYY"
           />                                                        
          </LocalizationProvider>
        </FormControl>
       </Grid>
       <Grid item lg={3.7} md={12} xs={12} >
         <TextField
                  type='text'
                  disabled //SOp-92_A testing feedback correction
                  variant="standard"
                  label='Invoice Number'
                  name='InvoiceNumber'
                  fullWidth
                  value={InvoiceNumberValue}
        />  
       </Grid>
       <Grid item lg={3.7} md={12} xs={12} >
          <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // readOnly
                  disabled //SOp-92_A testing feedback correction
                  label="Invoice Date"
                  value={InvoiceDateValue ? dayjs(InvoiceDateValue) : null}
                  format="DD-MM-YYYY"
                />                                                        
         </LocalizationProvider>
         </FormControl>
       </Grid>
       </Grid>
{/* End of Grid first row */}
{/* Grid Second row */}
 <Grid container mb={5} spacing={5}>
      <Grid item lg={3.7} md={12} xs={12} >
       <TextField
                 type='text'
                 disabled //SOp-92_A testing feedback correction
                 variant="standard"
                 label='Price'
                 name='Price'
                 fullWidth
                 value={PriceValue || ''}
        /> 
        </Grid>
        <Grid item lg={3.7} md={12} xs={12} >
        <FormControl fullWidth>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
         <DatePicker
                // readOnly
                disabled //SOp-92_A testing feedback correction
                label="Ship Date"
                value={ShipDateValue ? dayjs(ShipDateValue) : null}
                format="DD-MM-YYYY"
                />                                                        
        </LocalizationProvider>
        </FormControl>
        </Grid>
        </Grid>
 {/* End of Grid Second row */}
</Box>
</AccordionDetails>
</Accordion>
</Box>
{/* End of Purchase Information Accordian */}
{/* System Information Accordian */}
 <Box mt={1}>
<Accordion defaultExpanded sx={{ borderRadius:'30px' }}>
<AccordionSummary
expandIcon={<ArrowDropDownIcon />}
aria-controls="panel1-content"
id="panel1-header"
>
<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
 <GrSystem height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
<Typography fontSize={'1rem'} ml={2}>System Information</Typography> 
</Box>
</AccordionSummary>
<AccordionDetails>
<Box sx={{
ml:{
xl:12,
lg:10,
md:0,
xs:0
}
}}>
{/* Grid first row */}
      <Grid container mb={5} spacing={5} >
      <Grid item lg={3.7} md={12} xs={12} >
       <TextField
         type='text'
         disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Service Tag Number'
                name='ServiceTagNumber'
                fullWidth
                value={ServiceTagNumberValue}
       /> 
       </Grid>
       <Grid item lg={3.7} md={12} xs={12} >
        <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Ip Address'
                name='IpAddress'
                fullWidth
                value={IpAddressValue}
          /> 
          </Grid>
          <Grid item lg={3.7} md={12} xs={12} >
           <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Operating System'
                name='OperatingSystem'
                fullWidth
                value={OperatingSystemValue}
            /> 
             </Grid>
             </Grid>
            {/* End of Grid first row */}
            {/* Grid Second row */}
             <Grid container mb={5} spacing={5} >
             <Grid item lg={3.7} md={12} xs={12} >
                <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Processor Type'
                name='ProcessorType'
                fullWidth
                value={ProcessorTypeValue}
                /> 
              </Grid> 
             <Grid item lg={3.7} md={12} xs={12} >
                <TextField
                type='text'
                disabled //SOp-92_A testing feedback correction
                variant="standard"
                label='Memory In GB'
                name='MemoryInGb'
                fullWidth
                value={MemoryInGbValue}
                 /> 
              </Grid>
              <Grid item lg={3.7} md={12} xs={12} >
                <TextField
                  type='text'
                  disabled //SOp-92_A testing feedback correction
                  variant="standard"
                  label='Express Service Code'
                  name='ExpressServiceCode'
                  fullWidth
                  value={ExpressServiceCodeValue}
                                
                /> 
                </Grid>
                </Grid>
 {/* End of Grid Second row */}
{/* Grid Third row */}
                <Grid container mb={5} spacing={5} >
                <Grid item lg={3.7} md={12} xs={12} >
                <TextField
                  type='text'
                  disabled //SOp-92_A testing feedback correction
                  variant="standard"
                  label='Location'
                  name='Location'
                  fullWidth
                  value={LocationValue}
                /> 
                </Grid>
                </Grid>
{/* End of Grid Third row */}
</Box>
</AccordionDetails>
</Accordion>
</Box>
{/* End of System Information Accordian */}
{/* Warranty / AMC Accordian */}
<Box mt={1}>
<Accordion defaultExpanded sx={{ borderRadius:'30px'}}>
<AccordionSummary
expandIcon={<ArrowDropDownIcon />}
aria-controls="panel1-content"
id="panel1-header"
>
<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
<PiMedalFill height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
<Typography fontSize={'1rem'} ml={2}>Warranty / AMC</Typography> 
</Box>
</AccordionSummary>
<AccordionDetails>
<Box sx={{
ml:{
xl:12,
lg:10,
md:0,
xs:0
}
}} >
{/* Grid first row */}
         <Grid container mb={5} spacing={2}>
          <Grid item lg={3.7} md={12} xs={12}>
          <Box>
            <FormControlLabel
              sx={{ 
              fontSize:'3rem',
              color:'#2196F3' }}
              label="Warranty"
              checked={ToSetWarranty}
              control={<Checkbox  
              sx={{color:'#2196F3',
              '&.Mui-checked': {
              color: '#2196F3',
              },
              }}/>}
              />   
            </Box>                                            
            </Grid>
           </Grid>
           <Grid container mb={5} spacing={2}>
            <Grid item lg={3.7} md={12} xs={12} >
              <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
              // readOnly
              disabled
              label="Warranty Start Date"
              value={WarrantyStartDateValue ? dayjs(WarrantyStartDateValue) : null}
              format="DD-MM-YYYY"
              // disabled={disableWarrantyDate}
              />                                                        
              </LocalizationProvider>
              </FormControl>
                </Grid>
                <Grid item lg={3.7} md={12} xs={12} >
                <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                // readOnly
                disabled
                label="Warranty End Date"
                value={WarrantyEndDateValue ? dayjs(WarrantyEndDateValue) : null}
                // disabled={disableWarrantyDate}
                format="DD-MM-YYYY"
                />                                                        
                </LocalizationProvider>
                </FormControl>
                </Grid>
                </Grid>
{/* End of Grid first row */}
{/* Grid Second row */}
                <Grid container mb={5} spacing={2}>
                <Grid item lg={3.7} md={12} xs={12} >
                <Box>
                <FormControlLabel
                sx={{ 
                fontSize:'3rem',
                color:'#2196F3' }}
                label="AMC"
                checked={ToSetAMC}
                control={<Checkbox  
                sx={{color:'#2196F3',
                '&.Mui-checked': {
                color: '#2196F3',
                },
                }}/>}
                />   
                </Box> 
                </Grid>
                </Grid>
                <Grid container spacing={2} display={'flex'}>
                <Grid item lg={3.7} md={12} xs={12} >
                <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                // readOnly
                disabled
                label="AMC Start Date"
                value={AMCStartDateValue ? dayjs(AMCStartDateValue) : null}
                // disabled={disableAMCDate}
                format="DD-MM-YYYY"
                
                />                                                        
                </LocalizationProvider>
                </FormControl>
                </Grid>
                 <Grid item lg={3.7} md={12} xs={12} >
                <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                // readOnly
                disabled
                label="AMC End Date"
                value={AMCEndDateValue ? dayjs(AMCEndDateValue) : null}
                // disabled={disableAMCDate}
                format="DD-MM-YYYY"
                />                                                        
              </LocalizationProvider>
                </FormControl>
                </Grid>
                </Grid>
                {/* End of Grid Second row */}                                       
</Box>
</AccordionDetails>
</Accordion>
</Box>
{/* End of Warranty / AMC Accordian */}
{/* Notes Accordian */}
<Box mt={1}>
<Accordion defaultExpanded sx={{
borderRadius:'30px',
}}>
<AccordionSummary
expandIcon={<ArrowDropDownIcon />}
aria-controls="panel1-content"
id="panel1-header"
>
<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
<LiaEditSolid height={'1rem'} width={'1rem'} color='#2196F3' fontSize={'1.5rem'} /> 
<Typography fontSize={'1rem'} ml={2}>Notes</Typography> 
</Box>
</AccordionSummary>
<AccordionDetails>
<Box sx={{
ml:{
xl:12,
lg:10,
md:0,
xs:0
}
}} >
{/* Grid first row */}
  <Grid container mb={5} spacing={5} display={'flex'} justifyContent={'center'} >
  <Grid item lg={12} md={12} xs={12} >
   <TextField
      type='text'
      disabled //SOp-92_A testing feedback correction
      variant="standard"
      label='Notes'
      name='Notes'
      fullWidth
      multiline
      value={NotesValue}
    /> 
</Grid>
</Grid>
</Box>
</AccordionDetails>
</Accordion>
</Box>
{/* End of Notes Accordian */}
<Box>                    
<Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
<Button
    sx={{
    background: 'white',
    border: 'none',
    borderRadius: '5px',
    padding:{
    lg: "0 70px",
    md: "0 70",
    xs:'0 50px'

    },
height: "3rem",
boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
}}

  onClick={()=>{
  navigate('/home')
  }} 
>
Back
</Button>
</Box>
</Box>
</form>
</Box>
</MainContainerWrapper>
</>)}

export default ViewAssetPage;

//EOF SOP-92 Included By PCS