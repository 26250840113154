// SOP-97 Included by PCS [Ticketing Masters -Closure Information]

// Imports
import { useState,useRef,useEffect } from 'react'; 
import React from 'react';
import {
    Box,
    Button,
    TextField,
   
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import Swal from "sweetalert2";


// Page start
const AddTicketingClosureInformation = 
// To get handleAddClosureInformationClose & getclosureinformationlistsearch from closureinformation  page
    ({
        handleAddClosureInformationClose,
        getclosureinformationlistsearch
    }: {
        handleAddClosureInformationClose: (event: React.MouseEvent) => void,
        getclosureinformationlistsearch:  (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

      // SOP-97 Inlcuded By PCS
      const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
      const GlobalData = JSON.parse(GlobalDataFromSession);
      //console.log("GlobalData",GlobalData)
      let CompanyId = GlobalData.GlobalCompanyId;
      const UserId = GlobalData.GlobalUserId;

    const [ClosureInformationError, setClosureInformationError]=useState(false)
    const [ClosureInformationValue, setClosureInformationValue]=useState('')
    const [ClosureInformationErrorMessage, setClosureInformationErrorMessage]=useState('')

    //[to avoid duplicate ClosureInformation type name]
      const [ClosureInformationValueEndTrim, setClosureInformationValueEndTrim]=useState('')
    

    //  Handle ClosureInformation  while typing ClosureInformation  and validations
     const handleClosureInformationChange = (event:any) => {
         const trimmedClosureInformationValue = event.target.value.trim();
         setClosureInformationValue(event.target.value);

         //[to avoid duplicate ClosureInformation name]
         setClosureInformationValueEndTrim(trimmedClosureInformationValue);
        
        if (trimmedClosureInformationValue === '') {
            setClosureInformationError(true);
            setClosureInformationErrorMessage("Closure Information is required");
                                           } 
        else if( trimmedClosureInformationValue.length >255 ){
            setClosureInformationError(true);
            setClosureInformationErrorMessage("Max 255 characters");
                                                      }
        else {
            setClosureInformationError(false);
            setClosureInformationErrorMessage("");
             }
                                                  };

    // Exist ClosureInformation  name API
    const GetClosureInformationNameExist = async () =>
   {   
        
        const  ExistClosureInformationNamePayload = {
            ExistClosureInformationName:ClosureInformationValueEndTrim,
            ClosureInformationId:null,
            CompanyId:CompanyId //SOP-97 Included By PCS
                                         }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_closureinformation_name_exists`,ExistClosureInformationNamePayload);
        //console.log(res.data.data);
        return res.data.data || [];
            } 
        catch (err) {
        console.log( "co",err); 
                   }
    };


    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddClosureInformationClose && typeof handleAddClosureInformationClose === 'function') {
            handleAddClosureInformationClose(event);
        }
    };

    // to render getclosureinformationlistsearch function after closing dialouge box
    const handlegetclosureinformationlistsearch= (event: React.MouseEvent) => {
        if (getclosureinformationlistsearch && typeof getclosureinformationlistsearch === 'function') {
            getclosureinformationlistsearch(event);
        }
    };

    // To add ClosureInformation type

     // Define a type for errors
     type Errors = {
        closureinformation?:boolean;
    };
    const HandleAddClosureInformation = async (event:any)=>
    {
         event.preventDefault();
         let errors: Errors = {};

            const ClosureInformationNameList = await GetClosureInformationNameExist();
            if (ClosureInformationNameList.length > 0) {
                setClosureInformationError(true);
                setClosureInformationErrorMessage("Closure Information already exists");
                errors.closureinformation = true;
                return;
                                            }
        
            const trimmedClosureInformationValue = ClosureInformationValue.trim();
            if (trimmedClosureInformationValue === '') {
                errors.closureinformation = true;
                setClosureInformationErrorMessage("Closure Information is required");
            } 
            else if (trimmedClosureInformationValue.length > 255) {
                errors.closureinformation = true;
                setClosureInformationErrorMessage("Max 255 characters");
            }
    
            if (Object.keys(errors).length > 0) {
                // If there are errors, update the state with all the errors
                setClosureInformationError(errors.closureinformation || false);
                return;
                                            }
    
        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Year}-${Month}-${Day}`;
       // console.log("FormattedDate",FormattedDate)
    
        const AddClosureInformationPayload = {
             SaveUpdateKey: "create",
             ClosureInformationName: ClosureInformationValue,
             ClosureInformationId: null,
            //SOP-97 Included By PCS
            CompanyId:CompanyId,
            Active: "Y",
            CreatedDateTime: null,
            CreatedBy: UserId,
            IsRemove: null
            //EOF SOP-97 Included By PCS
                                 }
    
        //console.log("Add payload", AddClosureInformationPayload);

          Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor:"#25425f",
                allowOutsideClick: false,
                })
                .then((result) =>
                {
                    if (result.isConfirmed)
                    {
                    commonService
                            .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_closure_information_create_update`, AddClosureInformationPayload)
                            .then((res) => {
                            if (res.status === 200) {
                                Success("Closure Information created successfully");
                                handleClose(event);
                                handlegetclosureinformationlistsearch(event);
                            }
                            }).catch((error) => {
                                console.error("Error:", error);
                            });
                    } 
                    else  
                    {
                    return
                    }
                });
     
    }
   
    // UseEffect Declaration
    useEffect(() => {
         }, []);
     
const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => 
    {
        if (event.key === 'Enter') {
            event.preventDefault();
                                    }
    };

    return(
        <>
        <Box>
            <Box display={'flex'} justifyContent={'center'}>
                <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:900,
                            md:600,
                            xs:700
        
                        }}}
                    >
                    <Box p={3}>
                        <Typography variant='h1'>
                        Add Ticketing Closure Information
                        </Typography>
                    </Box>
                        <Box p={3}>
                        <form  noValidate>
                        <Box>
                        <TextField
                            onKeyDown={handleKeyDown} 
                            autoFocus
                            type='text'
                            variant="standard"
                            label='Closure Information'
                            name='ClosureInformation'
                            style={{ margin: '0 10px' }}
                            fullWidth
                            required
                            error={ClosureInformationError}
                            onChange={handleClosureInformationChange}
                            value={ClosureInformationValue}
                            helperText={ClosureInformationErrorMessage}
                        /> 
                        </Box>
                        <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Button
                            sx={{
                                background: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                padding:{
                                    lg: "0 70px",
                                    md: "0 70",
                                    xs:'0 50px'
        
                                },
                                height: "3rem",
                                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                }}

                        onClick={handleClose} 
                        >
                        Back
                        </Button>
                        <Button
                            sx={{
                                background: '#25425F',
                                border: 'none',
                                borderRadius: '5px',
                                padding:{
                                    lg: "0 70px",
                                    md: "0 70",
                                    xs:'0 50px'
        
                                },
                                "&:hover":{
                                    backgroundColor:'#25425F',
                                    color:'white',
                                },
                                color: 'white',
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                                type='submit'

                            onClick={(event)=>{
                                HandleAddClosureInformation(event);
                            }}
                        >
                        Save
                        </Button>
                        </Box>
                         </form>
                        </Box>
                </Box>
            </Box>
        </Box>
        </> )

}

export default AddTicketingClosureInformation;

//EOF SOP-97 Included by PCS [Ticketing Masters - Closure Inofrmation]