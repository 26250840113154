import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    
} from '@mui/material';
import Typography from '@mui/material/Typography';
import SmartOpzLogo from '../../asset/images/Logo/SmartOpz_Logo.jpg'
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkIcon from '@mui/icons-material/Link';
import axios from "axios";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

//SOP-50 Modified By PCS ['Failed' Toaster included ]
//import { Success  } from '../../atic-common-helpers/helpers/toast.helper';
  import { Success ,Failed } from '../../atic-common-helpers/helpers/toast.helper';
//EOF SOP-50 Modified By PCS

//SOP-78 Included By PCS [Including Pricing Sign up page]
import PricingSignUpPage from '../Profile/PricingSignUp';
import { Global } from '@emotion/react';

//SOP-35 Inlcuded By PCS
import Swal from "sweetalert2";
//EOF SOP-35

//SOP-11 - TS (Testing Point -03) - start
//Need Eye visible on the right side of the password and confirm password text box
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
//SOP-11 - TS (Testing Point -03) - end


//SOP-32 - TS - start
//User Management - Forget Password
import Dialog from '@mui/material/Dialog';
import ForgotPassword from '../ForgotPassword/ForgotPassword'
//SOP-32 - TS - end

//SOP-50 Included By PCS 
import OtpInput from 'react-otp-input';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
//EOF SOP-50 Included By PCS
import LoginscreenImage from '../../asset/images/LoginscreenImage.png'; // SOP-93 Included by PCS
import ErrorInformation from '../../asset/images/ErrorInformation.jpg'; // SOP-93 Included by PCS

const LoginPage = () => {

    // const classes = useStyles();

   //SOP-78 Included By PCS [ Plan renewal page open & Close]
   const [signUpOpen, setSignUpOpen] = React.useState(false);

   const handleSignUpOpen = () => {
       setSignUpOpen(true);
   };
   
   const handleSignUpClose = () => {
       setSignUpOpen(false);
   };
//EOF SOP-78 Included By PCS [ Plan renewal page open & Close]

// use navigate declaration
const navigate = useNavigate();

const [EmailError, setEmailError]=useState(false)
const [EmailValue, setEmailValue]=useState('')
const [EmailErrorMessage, setEmailErrorMessage]=useState('')    
const [PasswordError, setPasswordError]=useState(false)
const [PasswordValue, setPasswordValue]=useState('')
const [PasswordErrorMessage, setPasswordErrorMessage] = useState('')     

//SOP-32 - TS - start
//User Management - Forget Password
const [ForgotPasswordOpen, setForgotPasswordOpen] = React.useState(false);
//SOP-32 - TS - end

//SOP-11 - TS (Testing Point -03) - start
//Need Eye visible on the right side of the password and confirm password text box
 const [showPassword, setShowPassword] = useState(false);

//SOP-50 Included By PCS
const [signUpOTPOpen , setsignUpOTPOpen]= useState(false)
const [OTPValue, setOTPValue] = useState('');
const [timer, setTimer] = useState("00:00:00");
const Ref = useRef<NodeJS.Timeout | undefined>(undefined);

// const [ResendDisabel, setResendDisabel] = useState(true)
const [ResendDisable, setResendDisable] = useState(true)
const GlobalOTPFromSession = sessionStorage.getItem("GlobalOTP"); // Gets the Otp from session

// To set Otp dialog box open and close
const handleSignUpOTPOpen = () =>{
setsignUpOTPOpen(true)
                            };

const handleSignUpOTPClose = () =>{
setsignUpOTPOpen(false)
                             };

// Resend OTP
const ResendOTP =()=>
{
// Declared variable 'digits'

let digits = '0123456789'; 
let FormatedOTP = ''; 
let len = digits.length 
for (let i = 0; i < 4; i++) { 
   FormatedOTP += digits[Math.floor(Math.random() * len)]; 
                           } 

// console.log(FormatedOTP)
let OTP = FormatedOTP

// SOP-77 Included by PCS [Resend OTP- to encrypt OTP before storing in session]
//sessionStorage.setItem("GlobalOTP",FormatedOTP) Commented By PCS
 const dataOTP = CryptoJS.AES.encrypt(JSON.stringify(FormatedOTP),secretPass).toString();
//console.log("Encrypted OTP ata:", dataOTP);
  sessionStorage.setItem("GlobalOTP",dataOTP) 
//EOF SOP-77 Included by PCS  [Resend OTP  to encrypt OTP before storing in session]

const OTPPayload ={
   RegisterdEmail:EmailValue,
   OTP:OTP
               }

commonService
.postService(`${process.env.REACT_APP_BACKEND_URL}/company/OTPMailLogin`, OTPPayload)
.then((res) => {
   if (res.status === 200) {
   }
               })
.catch((error:any) => {
   console.error("Error:", error);
                     });
}
// EOF Resend OTP


// to set timer
const getTimeRemaining = (endTime: Date) => 
{
const total = endTime.getTime() - new Date().getTime();
const seconds = Math.floor((total / 1000) % 60);
const minutes = Math.floor((total / 1000 / 60) % 60);
const hours = Math.floor((total / 1000 / 60 / 60) % 24);
return { total, hours, minutes, seconds };
};


const startTimer = (e:any) => 
{

let { total, hours, minutes, seconds } =
   getTimeRemaining(e);
if (total >= 0) {
   // update the timer
   // check if less than 10 then we need to
   // add '0' at the beginning of the variable
   setTimer(
       (hours > 9 ? hours : "0" + hours) +
       ":" +
       (minutes > 9
           ? minutes
           : "0" + minutes) +
       ":" +
       (seconds > 9 ? seconds : "0" + seconds)
   );
}
else 
{
   // Time is up
   setTimer("00:00:00"); // Set timer to 00:00:00 or any message you want to display
   clearInterval(Ref.current); // Stop the timer
   sessionStorage.removeItem("GlobalOTP")//SOP-76 feedback points L2 (6) Included By PCS [to remove otp from session after 5 mins]
   setResendDisable(false)

}
};

const FormatedTimer = (e:any) =>
{
// If you adjust it you should also need to
// adjust the Endtime formula we are about
// to code next
       setTimer(`00:0${process.env.REACT_APP_SAMRTOPZ_OTP_TIMER}:00`);

// If you try to remove this line the
// updating of timer Variable will be
// after 1000ms or 1sec
       if (Ref.current) clearInterval(Ref.current);
       const id = setInterval(() => {
           startTimer(e);
       }, 1000);
       Ref.current = id;
};

//To set deadline time
const getDeadTime = () => 
{
let deadline = new Date();

// Get the OTP timer from environment variables
const otpTimerString = process.env.REACT_APP_SAMRTOPZ_OTP_TIMER;

// Use a default value if otpTimerString is undefined
const otpTimer = parseInt(otpTimerString || "5", 10);

// Set the deadline 
deadline.setMinutes(deadline.getMinutes() + otpTimer);
return deadline;

};

//To reset timer  
const Reset = () => 
{
// Stop the timer
  clearInterval(Ref.current);
// Reset the timer display
  setTimer('00:00:00');
};

//E0F SOP-50 Included By PCS


const handleTogglePasswordVisibility = () => {
setShowPassword((prev) => !prev);
                                        };

//SOP-11 - TS (Testing Point -03) - end

//SOP-32 - TS - start
// User Management - Forget Password
//ForgotPassword open & close
const handleForgotPasswordOpen = () => {
setForgotPasswordOpen(true);
};

const handleForgotPasswordClose = () => {
setForgotPasswordOpen(false);
};
//SOP-32 - TS - end

// Handle email while typing data
const handleEmailChange = (event:any) => {
const trimmedEmailValue = event.target.value.trim();
setEmailValue(event.target.value);
if (trimmedEmailValue === '') {
   setEmailError(true);
   setEmailErrorMessage("Email is required");
} 
else if( trimmedEmailValue.length >255 ){
   setEmailError(true);
   setEmailErrorMessage("Max 255 characters");
}
else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmailValue) ){
   setEmailError(true);
   setEmailErrorMessage("Invalid Email");
}
else {
   setEmailError(false);
   setEmailErrorMessage("");
}
};

// Handle password while typing data
const handlePasswordChange = (event:any) => {
const trimmedPasswordValue = event.target.value.trim();
setPasswordValue(event.target.value);

if (trimmedPasswordValue === '') {
 setPasswordError(true);
 setPasswordErrorMessage("Password is required");
} 
else if( trimmedPasswordValue.length >12 ){
 setPasswordError(true);
 setPasswordErrorMessage("Max 12 characters");
}
else {
 setPasswordError(false);
 setPasswordErrorMessage("");
}
};


//SOP-93 Included By PCS [to have signup & Contact Support link navigate ]
// SignUp link 
const handleSignupClick = () => {
     const googleLink = 'https://smartopz.com/' 
     window.open(googleLink, '_blank');
    };

// ContactSupport link 
const handleContactSupportClick = () => {
    const googleLink = 'https://smartopz.com/' 
    window.open(googleLink, '_blank');
    };

//EOF SOP-93 Included By PCS


// To get company user details API as per the email 
const ToGetCompanyUserDetails = async ()=>{

const  CompanyUserDetailsPayload = {
UserName:EmailValue,
Active:"Y"
}
//console.log("CompanyUserDetailsPayload",CompanyUserDetailsPayload)
try {
const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/company/getCompanyUserDetails`,CompanyUserDetailsPayload);
// console.log(res.data.data);
return res.data.data[0] || [];

} catch (err) {
console.log( "co",err); 
} 

}

// Incuded by sanjana SOP-52 to get menu list from DB
const GetEmployeeMenuList = async (CompanyId:any,EmployeeId:any) => {
const MenuPayload = {
CompanyId: CompanyId,
EmployeeId:EmployeeId
};

// console.log("MenuPayload",MenuPayload)

try {
const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/employee/getEmployeeAllMenuList`, MenuPayload);
if (res.data && Array.isArray(res.data.data)) {
 // Map and return the array of objects containing only menuname and menuid
 return res.data.data.map((item:any) => ({
   menuname: item.menuname,
   menuid: item.menuid
 }));
} else {
 // If data format is unexpected, return an empty array or handle accordingly
 return [];
}
} catch (error) {
console.error("Error:", error);
}
};
// End of SOP-52

// Login button function 
const handleSubmit = async (event :any)=>
{
event.preventDefault();

let errors: any = {};

//SOP-78 Included By PCS [to format Validity Date]
       //Formatting Date..
       function formattedDate(date: Date): string {
           // Extract day, month, and year
           const day = date.getDate();
           const month = date.getMonth() + 1; // Months are 0-based, so add 1
           const year = date.getFullYear();
           
           // Pad day and month with leading zeros if necessary
           const dayStr = day.toString().padStart(2, '0');
           const monthStr = month.toString().padStart(2, '0');
           
           // Format in YYYY-MM-DD
           return `${year}-${monthStr}-${dayStr}`;
               }

//EOF SOP-78 Included By PCS [to format Validity Date]


const CompanyDetails = await ToGetCompanyUserDetails();

// Included by sanjana SOP-52 to store menu details in session storage
// GetEmployeeMenuList(CompanyDetails.companyid,CompanyDetails.employee_id)
const EmployeeMenuDetails = await GetEmployeeMenuList(CompanyDetails.companyid,CompanyDetails.employee_id);
// console.log("EmployeeMenuDetails",EmployeeMenuDetails)
sessionStorage.setItem("EmployeeMenuDetails",JSON.stringify(EmployeeMenuDetails))
// End of SOP-52

//SOP-78 Included By PCS
//   console.log('CompanyDetails Role Id',CompanyDetails.employee_role_id)


//To get details of company from 'CompanyDetails'
  let UserCompanyId = CompanyDetails.companyid;
  let UserUserId = CompanyDetails.userid;
  let UserEmail = CompanyDetails.usernameemail;

//Encrypting the data and storing in session
  const secretPass = "XkhZG4fW2t2W";  
  let SessionCompanyId = CryptoJS.AES.encrypt(JSON.stringify(UserCompanyId),secretPass).toString();
  let SessionUserId = CryptoJS.AES.encrypt(JSON.stringify(UserUserId),secretPass).toString();
  let SessionEmail = CryptoJS.AES.encrypt(JSON.stringify(UserEmail),secretPass).toString();

  sessionStorage.setItem("SessionCompanyId",SessionCompanyId)
  sessionStorage.setItem("SessionUserId",SessionUserId)
  sessionStorage.setItem("SessionEmail",SessionEmail)

//EOF SOP-78 Included By PCS

//SOP-35 Inlcuded By PCS [To restrict login access if validity date is expired]

//Getting Validtill date from Global Variable
//    Modified by sanjana SOP-44 to solve the issue of Cannot read properties of undefined (reading 'split')
//    let ValidTillDate = CompanyDetails.validtilldate.split('T')[0]
   
//    //Getting Current date and formatting it.
//     const todayDate = new Date();
//     const day = String(todayDate.getDate()).padStart(2, '0');
//     const month = String(todayDate.getMonth() + 1).padStart(2, '0');
//     const year = todayDate.getFullYear();
   
//    const formattedTodayDate = `${year}-${month}-${day}`;

//     if(ValidTillDate < formattedTodayDate)
//     {
//       Swal.fire({
//         html: '<span style="font-size: 1.3rem;">Validity Expired</span>',
//         confirmButtonText: 'OK',
//         allowOutsideClick:false
//             });
   
//       return;
//     }

if (CompanyDetails && CompanyDetails.validtilldate) 
{
       // Getting Current date and formatting it.
           const todayDate = new Date();
           const day = String(todayDate.getDate()).padStart(2, '0');
           const month = String(todayDate.getMonth() + 1).padStart(2, '0');
           const year = todayDate.getFullYear();
           const formattedTodayDate = `${year}-${month}-${day}`;

       // Getting Validtill date from Global Variable

       //SOP-78 Included by PCS [modified by PCS ]
           // let ValidTillDate = CompanyDetails.validtilldate.split('T')[0]; //Commented by PCS
           let ValidTillDate = CompanyDetails.validtilldate
           let currentVDate = new Date(ValidTillDate) // to solve UTC Issue
           let formattedValidityDate = formattedDate(currentVDate) //Formatting to YYYY-MM-DD format
           // console.log('formattedValidityDate',formattedValidityDate)
           // console.log('formattedTodayDate',formattedTodayDate)
       //EOF SOP-78 Included by PCS [modified by PCS ]
      
       //SOP-78 Included By PCS [reversing Date to display in Swal]
        let formattedValidityDateForSwal = formattedValidityDate.toString()
        let reversedformattedValidityDateForSwal = formattedValidityDateForSwal.split('-').reverse().join('-');
       //EOF SOP-78 Included By PCS [reversing Date to display in Swal]


   //SOP-80 Included By PCS [Renew Plan alert- to eb display only for 'Super Admin']
   const isEmployeeRoleOne = CompanyDetails.employee_role_id === 1;
  //EOF SOP-80 Included By PCS [Renew Plan alert- to  display only for 'Super Admin']
   
       //SOP-78 Included by PCS [modified by PCS ]
           // if (ValidTillDate < formattedTodayDate) {
               if (formattedValidityDate < formattedTodayDate) 
                 {

                   // const cancelButtonText = isEmployeeRoleOne ? 'Later' : 'Ok';
                   //EOF SOP-78 Included by PCS [modified by PCS]
                           Swal.fire({

                       //SOP-78 Modified By PCS [Swal design changed]
                                   // html: '<span style="font-size: 1.3rem;">Validity Expired</span>',
                                   // confirmButtonText: 'OK',
                                   // allowOutsideClick: false
                               html: `<span style="font-size: 1.3rem;">Your validity expired on ${reversedformattedValidityDateForSwal}</span>`,
                               showCancelButton: true,
                           //SOP-80 modified By PCS [Renew Plan alert]
                               //confirmButtonText: 'Renew Now',
                               //cancelButtonText: 'Later',
                               showConfirmButton:isEmployeeRoleOne,
                               confirmButtonText: isEmployeeRoleOne ? 'Renew Now' : undefined,
                               cancelButtonText: isEmployeeRoleOne ? 'Later' : 'OK', 
                           //EOF SOP-80 commented By PCS [Renew Plan alert]

                               allowOutsideClick: false,
                               cancelButtonColor: '#d33',
                               confirmButtonColor:"#25425f",
                       
                           }).then((result) => {
                                   if (result.isConfirmed) {
                                   handleSignUpOpen();
                                   } 
                                   else if (result.isDismissed) 
                                   {
                                   sessionStorage.removeItem("SessionCompanyId")
                                   sessionStorage.removeItem("SessionUserId")
                                   sessionStorage.removeItem("SessionEmail")
                               }
                               });
       
                         return;
                       //EOF SOP-78 Modified By PCS [Swal design changed]
                   }

       
}
//   End of SOP-44
//EOF SOP-35 Inlcuded By PCS [To restrict login access if validity date is expired]

if(CompanyDetails.length === 0){
   errors.email = true;
   // Modified by sanjana SOP-17 to change comment line
   // setEmailErrorMessage("Invalid Email");
   setEmailErrorMessage("User dose not  exist");
   // End of SOP=17
}

// const secretPass = "XkhZG4fW2t2W";   
let decryptedData; 
if (CompanyDetails.password && typeof CompanyDetails.password === 'string' && CompanyDetails.password.trim() !== '') {
   const bytes = CryptoJS.AES.decrypt(CompanyDetails.password, secretPass);
   // console.log("bytes",bytes)
   decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}         
// console.log("decryptedData",decryptedData)

if(decryptedData !== PasswordValue){
 //console.log(" invalid password")
 errors.password = true;
//  setPasswordErrorMessage("Invalid password");
 setPasswordErrorMessage("Incorrect Password");
}
else{
   //console.log("Correct")
}
 

const trimmedEmailValue = EmailValue.trim();
if (trimmedEmailValue === '') {
   errors.email = true;
   setEmailErrorMessage("Email is required");
}
else if( !(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(trimmedEmailValue) ){
   errors.email = true;
   setEmailErrorMessage("Invalid Email");
}
else if( trimmedEmailValue.length >255 ){
   errors.email = true;
   setEmailErrorMessage("Max 255 characters");
}

const trimmedPasswordValue = PasswordValue.trim();
if (trimmedPasswordValue === '') {
   errors.password = true;
   setPasswordErrorMessage("Password is required");
} 
else if( trimmedPasswordValue.length >12 ){
   errors.password = true;
   setPasswordErrorMessage("Max 12 characters");
} 

if (Object.keys(errors).length > 0) {
   setEmailError(errors.email || false);
   setPasswordError(errors.password || false);
   return;
}

//SOP-50 Included By PCS [otp for login]
       handleSignUpOTPOpen();

       // Declare a digits variable  which stores all digits  
       
       let digits = '0123456789'; 
       let FormatedOTP = ''; 
       let len = digits.length 
           for (let i = 0; i < 4; i++) { 
               FormatedOTP += digits[Math.floor(Math.random() * len)]; 
                                       } 
    //    console.log(FormatedOTP)
       let  OTP=FormatedOTP


       // SOP-77 Included by PCS [to encrypt OTP before storing in session]
           // sessionStorage.setItem("GlobalOTP",FormatedOTP) Commented By PCS
               const dataOTP = CryptoJS.AES.encrypt(JSON.stringify(FormatedOTP),secretPass).toString();
               // console.log("Encrypted OTP Data:", dataOTP);
               sessionStorage.setItem("GlobalOTP",dataOTP) 
       //EOF  SOP-77 Included by PCS  [to encrypt OTP before storing in session]


       const OTPPayload ={
           RegisterdEmail:EmailValue,
           OTP:OTP
                       }

       //  console.log(OTPPayload.OTP)
       
       commonService
       .postService(`${process.env.REACT_APP_BACKEND_URL}/company/OTPMailLogin`, OTPPayload)
       .then((res) => {
           if (res.status === 200) {
           }
                   }).catch((error) => {
           console.error("Error:", error);
                                       });
   }
//EOF SOP-50 Included By PCS

// SOP-77 Included by PCS [to decrypt OTP before validating]
const secretPass = "XkhZG4fW2t2W"; 
   let decryptedDataOTP :any;
   if (GlobalOTPFromSession && typeof GlobalOTPFromSession === 'string' && GlobalOTPFromSession.trim() !== '')
   {
       const bytes = CryptoJS.AES.decrypt(GlobalOTPFromSession, secretPass);
       decryptedDataOTP = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
   }  
//EOF SOP-77 Included by PCS [to decrypt OTP before validating]



//SOP-50 Included By PCS [otp for login]
//SOP-79 Modified By PCS [to validate OTP on enter button]
// const LoginToSmartopz = async() =>{
const LoginToSmartopz = async(event:any) =>
{
   event.preventDefault();
  //EOF SOP-79 Modified By PCS [to validate OTP on enter button]
   let APIValidation = true
// SOP-77 Modified by PCS [validating decrypted value with OTP]
  
   //if(GlobalOTPFromSession !== OTPValue){
       if(decryptedDataOTP !== OTPValue){
       Failed("Invalid OTP")
       APIValidation= false  }
//EOF SOP-77 Modified by PCS [validating decrypted value with OTP]  

//SOP-85 Commented By PCS [to generate JWT Token]
// if (APIValidation)
// {
//         const CompanyDetails = await ToGetCompanyUserDetails();
//     //EOF SOP-50 Included By PCS [otp for login]
//         // SOP-11 - TS (Testing Points -05)
//         // Instead of display confirmation messsage "Registered successful" to be display as "Registered Successfully"
//         // Success("Logged In Successfuly!");
//     //SOP-50 Commented By PCS
//         Success("Logged In Successfully!");
//          navigate('/home')
//         // Included by sanjana SOP-56 to set windows reload
//         window.location.reload();
//         // End of SOP-56     
//         sessionStorage.removeItem("GlobalOTP")//SOP-78 Included By PCS [to remove otp from session after login]
//     //EOF SOP-50 Commented By PCS
//         const ToSetGlobalSessionStorage = {
//         GlobalCompanyId:CompanyDetails.companyid,
//         GlobalCompanyNAme:CompanyDetails.companyname,
//         // Modified by sanjana SOP-17 to rename user_name as username
//         // GlobalUserName:CompanyDetails.user_name,
//         GlobalUserName:CompanyDetails.username,
//         // End of SOP-17
//         // Modifeid by sanjana to rename global variable as GlobalRegistredEmail
//         // GlobalUserEamil:CompanyDetails.registeredemail,
//         GlobalRegistredEmail:CompanyDetails.registeredemail,
//         // End of SOP-17
//         // Included by sanjana SOP-17 to add user email
//         GlobalUserEmail:CompanyDetails.usernameemail,
//         // End of SOP-17
//         GlobalRegistredMobileNo:CompanyDetails.registeredmobile,
//         GlobalPlanID:CompanyDetails.planid,
//         GlobalPlanName:CompanyDetails.planname,
//         GlobalNoOfAssets:CompanyDetails.numberofassets,
//         GlobalRegistrationDate:CompanyDetails.registrationdate,
//         GlobalValidTillDate:CompanyDetails.validtilldate,
//         GlobalActive:CompanyDetails.active,
//         GlobalCurrency:CompanyDetails.currency,
//         GlobalPaymentschedule:CompanyDetails.paymentschedule,
//         // included by sanjana SOP-15 to add userid in session storage
//         GlobalUserId:CompanyDetails.userid,
//         // End of SOP15
//         GlobalUserRole:CompanyDetails.user_role_name,
//         GlobalUserMobile:CompanyDetails.employee_mobile,
//         GlobalPhotoUrl:CompanyDetails.photo_url,
//         // Inluded by sanjana SOP-40 to set employee id in session storage
//         GlobalEmployeeId:CompanyDetails.employee_id,
//         // End of SOP-40
//                 }
//         ////console.log("ToSetGlobalSessionStorage",ToSetGlobalSessionStorage)
//         sessionStorage.setItem("GlobalVariable",JSON.stringify(ToSetGlobalSessionStorage))
//         //SOP-50 Included By PCS
//                 Reset() //Resets the timer
//                 setOTPValue('') 
//                 handleSignUpOTPClose();
//         //EOF SOP-50
// }
// else{
//     console.log("APIValidation is false");
//     }
//EOF SOP-85 Commented By PCS [to generate JWT Token]
//SOP-85 Included By PCS [to generate JWT Token]
if (APIValidation) {
   const CompanyDetails = await ToGetCompanyUserDetails();
     // SOP-85 Included By PCS [Including JWT Token]
   const JWtpayload = {
       Email: CompanyDetails.usernameemail,
       CompanyName: CompanyDetails.companyname
       };

   try {
      const res: any = await commonService.postService(
           `${process.env.REACT_APP_BACKEND_URL}/company/JWTValidate`, 
           { JWtpayload }
       );
       
       //  console.log("API Response:", res); // Log the full response
       if (res?.data?.data) {
           // Store JWT in localStorage
           localStorage.setItem("avt-auth-token", res.data.data);
           // console.log("Stored JWT:", res.data.data);
           // Set session storage values
           const ToSetGlobalSessionStorage = {
               GlobalCompanyId: CompanyDetails.companyid,
               GlobalCompanyNAme: CompanyDetails.companyname,
               GlobalUserName: CompanyDetails.username,
               GlobalRegistredEmail: CompanyDetails.registeredemail,
               GlobalUserEmail: CompanyDetails.usernameemail,
               GlobalRegistredMobileNo: CompanyDetails.registeredmobile,
               GlobalPlanID: CompanyDetails.planid,
               GlobalPlanName: CompanyDetails.planname,
               GlobalNoOfAssets: CompanyDetails.numberofassets,
               GlobalRegistrationDate: CompanyDetails.registrationdate,
               GlobalValidTillDate: CompanyDetails.validtilldate,
               GlobalActive: CompanyDetails.active,
               GlobalCurrency: CompanyDetails.currency,
               GlobalPaymentschedule: CompanyDetails.paymentschedule,
               GlobalUserId: CompanyDetails.userid,
               GlobalUserRole: CompanyDetails.user_role_name,
               GlobalUserMobile: CompanyDetails.employee_mobile,
               GlobalPhotoUrl: CompanyDetails.photo_url,
               GlobalEmployeeId: CompanyDetails.employee_id
           };

           // Save session storage
           sessionStorage.setItem("GlobalVariable", JSON.stringify(ToSetGlobalSessionStorage));

           // Reset OTP value and close the OTP dialog
           Reset();
           setOTPValue('');
           handleSignUpOTPClose();

           // Show success message and navigate
           Success("Logged In Successfully!");
           navigate('/home');  // Only navigate after successful token storage
           window.location.reload();  // Reload the page after navigating to the home page
           sessionStorage.removeItem("GlobalOTP");  // Remove OTP from session after login

       } else {
           console.error("Token not found in response");
       }

   } catch (error: any) {
       console.error("Error has come:", error.response ? error.response.data : error);
   }
} else {
   console.log("APIValidation is false");
}
//EOF SOP-85 Included  By PCS [to generate JWT Token]   

}

//SOP-50 Included By PCS
   useEffect(() => {
   FormatedTimer(getDeadTime());
   }, []);
//EOF SOP-50

//SOP-93 Included By PCS [Login Page design is modifed as per new FIGMA screen ]
return(<>
    <Box display="flex" justifyContent="center" sx={{ 
           overflow: 'hidden', // Hide overflowing content if any
        //    height: '720px',
           height: '100vh',
        //    width: '100%' // Set width to 100% of its parent
           
          }}>
    <Grid
        container
        sx={{
         display:'flex',
         overflowX: 'hidden',  // Prevent overflow
        //  width:{
        //     lg:1440,
        //     md:900,
        //     xs:470
        // }
        }}
        display={'flex'} 
        boxShadow="rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px" // Fixed boxShadow syntax
    >
    {/* Left Section  starts*/}
        <Grid
            item lg={7}  md={7} xs={12}
            sx={{
            position: 'relative',
            backgroundImage: `url(${LoginscreenImage})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center', 
            backgroundRepeat: 'no-repeat', 
            width: '100%', display: 'flex' ,
            //  flex: '1 1 auto', 
            height: '100%', 
                }}
      >
        {/* for text  */}
        <Box
            fontFamily="Rubik"
            sx={{ }}
        >
            <Typography
                    sx={{
                    fontFamily:'Rubik',
                    position: 'relative',
                    height: '60px',
                    left: '4% !important',
                    marginTop: '5%',
                    fontWeight: 600,
                    fontSize: '44px',
                    lineHeight: '60px',
                    color: '#FFFFFF',
                // Make the font size responsive with viewport width
                '@media (max-width: 1024px)': {
                    fontSize: '36px',  
                    left: '3%',        
                    marginTop: '7%',   
                  },
                  '@media (max-width: 600px)': {
                    fontSize: '28px',  
                    left: '2%',        
                    marginTop: '10%',  
                  },
                    }}
            >
                    Welcome to Smartopz!
            </Typography>
            <Typography
                    sx={{
                    position: 'relative',
                    left: '4% !important',
                    fontWeight: 400,
                    width:'65%',
                    marginTop: '1%',
                    fontSize: '21px',
                      lineHeight: '44px',
                      color: '#DEDEDE',
                      '@media (max-width: 1024px)': {
                        fontSize: '18px',  
                      },
                      '@media (max-width: 600px)': {
                        fontSize: '16px',  
                      },
                    }}
            >
                Track your assets, manage IT issues, streamline HR processes, and handle all your documents in one place. Let’s get you set up quickly to improve your workflow and productivity.
            </Typography>

        </Box>
     </Grid>
    {/* Left Section ends*/}
        {/* Right Section starts*/}
            <Grid  item lg={5} md={5} xs={12}
                sx={{
                    border: '1px solid gray', // Add border to the Grid item itself
                    width: '100%' ,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%', // Ensure it takes full height
                   
                }}
            >
                <Box>
                <img
                    src={SmartOpzLogo}
                    style={{
                     position: 'relative',
                      width: '218px',
                      height: '54px',
                      left: '8% ',
                      marginTop: '5%'
                      }}
                  />
                <Typography
                  sx={{
                    fontFamily: 'Rubik',
                    position: 'relative',
                    left: '8% ',
                    marginTop: '5%',
                    fontWeight: 500,
                    fontSize: '34px',
                    lineHeight: '1.5',
                    color: '#3C3C3C',
                    '@media (max-width: 1024px)': {
                      fontSize: '30px',  // Fixed size for medium screens
                    },
                    '@media (max-width: 600px)': {
                      fontSize: '24px',  // Fixed size for small screens
                    },
   
                  }}
                >
                  Welcome Back!
                </Typography>

                <Typography
                  sx={{
                    fontFamily: 'Rubik',
                    position: 'relative',
                    left: '8% ',
                    marginTop: '3%',
                    fontSize: '24px',
                    lineHeight: '1.5',
                    color: '#3C3C3C',
                    '@media (max-width: 1024px)': {
                      fontSize: '20px',  
                    },
                    '@media (max-width: 600px)': {
                      fontSize: '18px',  
                    },
   
                  }}
                >
                  Log in to get started
                </Typography> 
{/* //Fields starts */}
                <form noValidate 
                onSubmit={handleSubmit}
                >
                <Typography
                  sx={{
                    fontFamily: 'Rubik',
                    position: 'relative',
                    marginTop: '3%',
                    marginLeft: '8% ',
                    fontWeight: 500,
                    fontSize: '1.25rem',
                    lineHeight: '1.5',
                    color: '#3F424D',
                     '@media (max-width: 1024px)': {
                        fontSize: '1rem',
                       },
                       '@media (max-width: 600px)': {
                        fontSize: '0.875rem', 
                       },
                  }}
                >
                  Email
                </Typography>
                <TextField
                    type="text"
                    variant="outlined"
                    //SOP-93_A Modified By PCS [as suggested by Kathiravan]
                    // label="Enter your Email ID"
                    placeholder="Enter your Email ID"
                    //EOF SOP-93_A Modified By PCS [as suggested by Kathiravan]
                    name='Enter your Email ID'
                    sx={{
                    width: '85%',  
                    fontFamily: 'Rubik',
                    position: 'relative',
                    marginTop: '3%',
                    marginLeft: '8% ',
                    fontSize: '1.25rem',
                    lineHeight: '1.5',
                    color: '#3F424D',
                    }}
                    required
                    value={EmailValue}
                    error={EmailError}
                    helperText={EmailErrorMessage && (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={ErrorInformation} style={{width:'15px',height:'15px',marginRight:'4px'}}></img>
                            {EmailErrorMessage}
                        </span>
                    )}
                    onChange={handleEmailChange}
                    autoFocus 
                    
                  />
                  <Typography
                  sx={{
                    fontFamily: 'Rubik',
                    position: 'relative',
                    marginTop: '2.5%',
                    marginLeft: '8% ',
                    fontWeight: 500,
                    fontSize: '1.25rem',
                    lineHeight: '1.5',
                    color: '#3F424D',
                   '@media (max-width: 1024px)': {
                        fontSize: '1rem',
                       },
                       '@media (max-width: 600px)': {
                        fontSize: '0.875rem', 
                       },
                  }}
                >
                  Password
                </Typography>
                <TextField
                    //SOP-11 - TS (Testing Point -03)                
                     // type='password'
                     type={showPassword ? 'text' : 'password'}
                     variant="outlined"
                    //SOP-93_A Modified By PCS [As suggested by Kathiravan]
                    //  label="Enter your Password"
                    placeholder="Enter your Password"
                    //EOF SOP-93_A Modified By PCS [As suggested by Kathiravan]
                    sx={{
                    width: '85%',  
                    fontFamily: 'Rubik',
                    position: 'relative',
                    marginTop: '2.5%',
                    marginLeft: '8% ',
                    fontSize: '1.25rem',
                    lineHeight: '1.5',
                    color: '#3F424D',
                  }}
                    required
                    
                    value={PasswordValue}
                    error={PasswordError}
                    helperText={PasswordErrorMessage && (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={ErrorInformation} style={{width:'15px',height:'15px',marginRight:'4px'}}></img>
                            {PasswordErrorMessage}
                        </span>
                    )}
                    onChange={handlePasswordChange}
                                 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleTogglePasswordVisibility}
                                    edge="end"
                                >
                                {/* //SOP-93_A Modified By PCS Testing feedback corrections */}
                                    {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                {/* //SOP-93_A  Modified By PCS Testing feedback corrections */}
                                </IconButton>
                            </InputAdornment>
                        ),
                        }}
                   />
                  {/* Forgot password */}
                    <Box>
                    <Button
                        sx={{
                        fontFamily: 'Rubik',
                        textAlign:'end',
                        position: 'relative',
                        marginTop: '2.5%',
                        marginLeft: '68% ',
                        fontSize: '1.08rem',
                        lineHeight: '1.5',
                        cursor:'pointer',
                            '&:hover':{
                            // color:'#3F424D'
                            },
                        
                        color: '#195AFE',
                    // Responsive design with media queries
                        '@media (max-width: 1024px)': {
                            fontSize: '1.0rem',  // Smaller font size for smaller screens
                            marginLeft: '60% ',
                        },
                        '@media (max-width: 600px)': {
                            fontSize: '0.8rem',  // Further reduce font size for very small screens
                            marginLeft: '60% ',
                            
                        },
                        }}
                         onClick={handleForgotPasswordOpen}
                     >
                    Forgot Password
                    </Button>
                    </Box>

                    <Box display={'flex'} justifyContent={'center'}>
                        <Button
                        style={{
                        position: 'relative',
                        fontWeight: 600,
                        fontSize: '1.08rem',
                        lineHeight: '1.5',
                        fontFamily: 'Rubik',
                        width: '85%',           
                        marginTop:'2%',
                        background: '#195AFE',
                        border: 'none',
                        borderRadius: '8px',
                        color: 'white',
                        height:'3.75rem',
                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                            type='submit'
                            //SOP-50 Included By PCS
                            onClick={()=>{
                                FormatedTimer(getDeadTime());
                                         }}
                            //EOF SOP-50
                        >
                            Login
                        </Button> 
                    </Box>
                </form>

                <Box >
                <Grid display={'flex'}  alignItems="center">
                    <Grid item lg={7} md={7} xs={7} sx={{ textAlign: 'right' }}justifyContent={'flex-end' }>
                    <Typography
                    sx={{
                    fontFamily: 'Rubik',
                    fontWeight:400,
                    marginTop: '4.5%',
                    fontSize: '1.0rem',
                    lineHeight: '1.5',
                    color: '#3F424D',
                    // Media query for screens smaller than 1024px
                        '@media (max-width: 1024px)': {
                            fontSize: '1.0rem', 
                        },
                    // Media query for screens smaller than 600px
                        '@media (max-width: 600px)': {
                            fontSize: '0.8rem',  
                      },

                        }}
                    >
                    Don’t have an account ?
                    </Typography>
                    </Grid>
                    <Grid item lg={5} md={5} xs={5} sx={{ textAlign: 'left' }} justifyContent={'flex-start' }>
                    <Typography
                     sx={{
                        cursor:'pointer',
                        fontWeight:700,
                        fontFamily: 'Rubik',
                        marginLeft:'1.0%',
                        marginTop: '7.1%',
                        fontSize: '1.0rem',
                        lineHeight: '1.5',
                        color: '#195AFE',
                    // Media query for screens smaller than 1024px
                        '@media (max-width: 1024px)': {
                            fontSize: '1.0rem',  
                            marginLeft: '1%',   
                        },
                        // Media query for screens smaller than 600px
                        '@media (max-width: 600px)': {
                            fontSize: '0.8rem',  
                            marginLeft: '1%',   
                        },
                    }}
                    onClick={handleSignupClick}
                    >
                    Signup
                    </Typography>
                    </Grid>
                </Grid>
                <Grid display={'flex'} mt={1} alignItems="center">
                    <Grid item lg={7.8} md={7.8} xs={7.8} sx={{ textAlign: 'right' }}justifyContent={'flex-end' }>
                    <Typography
                     sx={{
                     fontFamily: 'sans-serif',
                     fontWeight:400,
                     fontStyle: 'normal',
                     marginTop: '4.5%',
                     fontSize: '1.0rem',
                     lineHeight: '1.5',
                     color: '#666666',
                    // Media query for screens smaller than 1024px
                        '@media (max-width: 1024px)': {
                            fontSize: '1.0rem',  
                        },
                    // Media query for screens smaller than 600px
                        '@media (max-width: 600px)': {
                            fontSize: '0.8rem',  
                      },

                        }}
                    >
                    Terms of Service | Privacy Policy
                    </Typography>
                    </Grid>
                    <Grid item lg={4.2} md={4.2} xs={4.2} sx={{ textAlign: 'left' }} justifyContent={'flex-start' }>
                    <Typography
                     sx={{
                        cursor:'pointer',
                        fontWeight:700,
                        fontFamily: 'sans-serif',
                        marginLeft:'2%',
                        marginTop: '8%',
                        fontSize: '1.0rem',
                        lineHeight: '1.5',
                        color: '#195AFE',
                    // Media query for screens smaller than 1024px
                        '@media (max-width: 1024px)': {
                            fontSize: '1.0rem',  
                            marginLeft: '2%',   
                        },
                        // Media query for screens smaller than 600px
                        '@media (max-width: 600px)': {
                            fontSize: '0.8rem',  
                            marginLeft: '2%',   
                        },
                    }}
                     onClick={handleContactSupportClick}
                    >
                    Contact Support
                    </Typography>
                    </Grid>
                </Grid>
                </Box>
{/* //Fields ends */}
                </Box>
                
        </Grid>
    </Grid>
{/* //SOP-32 - TS - start */}
            {/* User Management - Forget Password */}
            <Box>
                <Dialog  open={ForgotPasswordOpen} fullWidth>
                    <ForgotPassword handleForgotPasswordClose={handleForgotPasswordClose}/>
                </Dialog>
            </Box>
            {/* //SOP-32 - TS - end */}

{/* //SOP-50 Commented By PCS */}
      {/* </Box> */}  
{/* //SOP-50 Included by PCS */}

            <Box>
            <Dialog  open={signUpOTPOpen} fullWidth>
               {/* //SOP-79 Modified By PCS [to validate OTP on enter button- enclosed OTP in form tag] */}
                <form>
             
                    <Box bgcolor={'white'} 
                            sx={{
                                width:{
                                    lg:600,
                                    md:600,
                                    xs:388
                                }
                            }}
                            display={ 'flex'} justifyContent={'center'}>
                            <Box mb={5}>
                             
                                <Box sx={{
                                    width:{
                                        lg:600,
                                        md:600,
                                        xs:388
                                    }
                                    }} bgcolor={'#4169e1'} display={ 'flex'} justifyContent={'center'}>
                                    <Typography  variant='h4' color={'white'} mt={2} mb={1} >
                                        OTP Verification
                                    </Typography>
                                </Box>
                                <Box mt={5}>
                                    <Typography fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                        OTP has been sent to 
                                    </Typography>
                                    <Typography fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                        {EmailValue} ( Valid for 5 minutes )
                                    </Typography>
                                </Box>
                                <Box mt={5} display={'flex'} justifyContent={'center'}>
                                    
                                    <OtpInput
                                    value={OTPValue}
                                    onChange={setOTPValue}
                                    numInputs={4}
                                    renderSeparator={<span style={{marginLeft:25}}>   </span>}
                                    inputStyle={{ 
                                        width: '40px', 
                                        height: '60px', 
                                        fontSize: '20px',
                                        borderRadius:10,
                                        border:'2px solid #4169e1'
                                    }}
                                    renderInput={(props:any) => <input {...props} />}
                                    />
                                </Box>

                                <Box mt={5} display={ 'flex'} justifyContent={'center'}>
                                        <Typography mt={1} fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                            {/* Didn't receive the OTP?  */}
                                            Don't receive the OTP? 
                                        </Typography>
                                        <Button 
                                        onClick={()=>{
                                            setResendDisable(true)
                                            ResendOTP();
                                            FormatedTimer(getDeadTime());
                                        }} 
                                        disabled={ResendDisable}
                                        sx={{
                                                ml:1,
                                                cursor:'pointer',
                                                color:'#4169e1',
                                                fontSize:'1.3rem',
                                                ":hover":{
                                                    bgcolor:'white'
                                                },
                                                display:'flex',
                                                justifyContent:'center',
                                            }}
                                            >
                                                RESEND
                                        </Button>
                                </Box>

                                <Box mt={5} display={ 'flex'} justifyContent={'center'}>
                                        <Typography fontSize={'1.3rem'} display={ 'flex'} justifyContent={'center'}>
                                            {timer}
                                        </Typography>
                                </Box>    
                        
                                <Box mt={5} display={ 'flex'} justifyContent={'center'} >
                                   <Button
                                        style={{
                                           
                                            background: '#4169e1',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding: "0 70px",
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                              }}
                                        type='submit'
                                        onClick={(event)=>{
                                        //SOP-79 Modified by PCS
                                            // LoginToSmartopz();
                                            LoginToSmartopz(event);
                                        //EOF SOP-79 Modified by PCS
                                        }}
                                    >
                                    Submit
                                    </Button>
                                {/* //SOP-81 Included By PCS [included  close button in OTP] */}
                                <Button
                                        style={{
                                            marginLeft:'4px', //SOP-81 Included By PCS[close button in OTP]
                                            background: '#ba000d',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding: "0 70px",
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                              }}
                                        type='submit'
                                        onClick={(event)=>{
                                    //   navigate('/')
                                      window.history.back();
                                        }}
                                    >
                                    Close
                                 </Button>
                                {/* //EOF SOP-81 Included By PCS*/}

                                </Box>
                            </Box>
                      </Box>
                    </form>
                    {/* //EOF SOP-79 Modified By PCS [to validate OTP on enter button- enclosed OTP in form tag] */}
            </Dialog>
              </Box>

        {/* //SOP-78 Included By PCS [Including Pricing Sign up page] */}
          <Dialog  open={signUpOpen} fullWidth >
                    <Box  height={60} bgcolor={'#4169e1'}>
                        <Box  display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Typography fontFamily={'Segoe UI'} fontSize={'1.5rem'} color={'white'}>
                               Plan Upgradation
                            </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        </Box>
                    </Box>

                    <PricingSignUpPage  handleSignUpClose={handleSignUpClose} /> 

            </Dialog>
        {/* //EOF SOP-78 Included By PCS [Including Pricing Sign up page] */}

    </Box>
{/* //EOF SOP-50 Included By PCS */}
{/* </Box> */}
        </> )
//EOF SOP-93 Included By PCS [Login Page design is modifed as per new FIGMA screen ]    
};

export default LoginPage;
