// SOP-97 Included By PCS [Ticketing Masters - Sub Categories]


import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
    Paper,
    Divider,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import './EditTicketingSubCategories.scss'
import Swal from "sweetalert2";

// Page start
const EditTicketingSubCategories = 
// To get handleEditCategoriesClose & getSubcategorieslistsearch from catgories page
({
    handleEditCategoriesClose,
    getSubcategorieslistsearch,
    SubCategoryId
}: {
    handleEditCategoriesClose: (event: React.MouseEvent) => void,
    getSubcategorieslistsearch: (event: React.MouseEvent) => void,
    SubCategoryId: any;
}) =>{

    const [SubCategoriesError, setSubCategoriesError]=useState(false)
    const [SubCategoriesValue, setSubCategoriesValue]=useState('')
    const [SubCategoriesErrorMessage, setSubCategoriesErrorMessage]=useState('')
    const [CategoryOptions, setCategoryOptions] = useState<OptionType[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>('');
    const [CategoryError, setCategoryError]=useState(false)
    const [CategoryErrorMessage, setCategoryErrorMessage]=useState('')
    const [SubCategoriesValueEndTrim, setSubCategoriesValueEndTrim]=useState('')
    const [isActive, setIsActive] = useState(true); //SOP-97 Included By PCS

    //SOP-97 Inlcuded By PCS
     const handleToggleActive = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };
    //EOF SOP-97 Inlcuded By PCS
   
    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    //  Handle Category while typing category and validations
    const handleSubCategoriesChange = (event:any) => {
        const trimmedSubCategoriesValue = event.target.value.trim();
        setSubCategoriesValue(event.target.value);
        setSubCategoriesValueEndTrim(trimmedSubCategoriesValue);

        if (trimmedSubCategoriesValue === '') {
            setSubCategoriesError(true);
            setSubCategoriesErrorMessage("Sub-Category is required");
        } 
        else if( trimmedSubCategoriesValue.length >255 ){
            setSubCategoriesError(true);
            setSubCategoriesErrorMessage("Max 255 characters");
        }
        else {
            setSubCategoriesError(false);
            setSubCategoriesErrorMessage("");
             }
    };


    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditCategoriesClose && typeof handleEditCategoriesClose === 'function') {
            handleEditCategoriesClose(event);
        }
    };


    // to render getSubcategorieslistsearch function after closing dialouge box
    const handlegetSubcategorieslistsearch= (event: React.MouseEvent) => {
        if (getSubcategorieslistsearch && typeof getSubcategorieslistsearch === 'function') {
            getSubcategorieslistsearch(event);
        }
    };

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId; //SOP-97 Included by PCS
    const UserId = GlobalData.GlobalUserId; //SOP-97 Included by PCS
  
    const GetCategoryList = async () => {

        //SOP-97 Included By PCS
        const CategoryPayload ={
        CompanyId:CompanyId
        }
        //EOF SOP-97 Included By PCS
 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketingCategoryList`,CategoryPayload);
            let formattedCategory = res.data.data.map((item:any) => { 
        
                const { sys_issue_type_id, sys_issue_type_name } = item;
            return {
                name: sys_issue_type_name,
                value: sys_issue_type_id,
            };
            });
            setCategoryOptions(formattedCategory)
        } 
        catch (err)
        {
            console.log( "co",err);
        }
    };


    // To get categories details as per the id
    const GetSubCategoryListById =()=>{
       
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_subcategories_list_by_id`, {
             IssueSubTypeId:SubCategoryId,
             CompanyId:CompanyId//SOP-97 Inlcuded By PCS
           
          })
         .then((res: any) => 
         { 
            const CategoryData = res.data.data[0]
            // console.log('Sub Category Data',CategoryData)
            setSubCategoriesValue(CategoryData.sys_issue_subtype_name)
          
            let formattedSubCategory = res.data.data.map((item:any) => {
                const { sys_issue_type_id, sys_issue_type_name } = item;
                return {
                    name: sys_issue_type_name,
                    value: sys_issue_type_id,
                };
                });
                setSelectedCategoryId(formattedSubCategory[0].value)


        //SOP-97 Inlcuded By PCS
            if(CategoryData.active === 'Y'){
                setIsActive(true)
            }
            else{
                setIsActive(false)
            }
        //EOF SOP-97 Inlcuded By PCS

         }).catch((error) =>  
         { 
          Failed(error.response.message);
         });
    }

        // Exist SubCategory name API
        const GetSubCategoryNameExist = async () =>
            {   
                const  ExistSubCategoryNamePayload = 
                    {
                        ExistIssueSubTypeName:SubCategoriesValueEndTrim,
                        IssueSubTypeId:SubCategoryId,
                        IssueTypeId:selectedCategoryId,
                        CompanyId:CompanyId //SOP-97 Included By PCS
                       
                    }
                    try 
                    {
                        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_subcategory_name_exists`,ExistSubCategoryNamePayload);
                        //  console.log( "ress",res.data.data); 
                        return res.data.data || [];
                    } 
                    catch (err)
                    {
                    console.log( "co",err); 
                    }
            };
    

    // To Update categories

     // Define a type for errors
     type Errors = {
        categories?:boolean;
    };

    const HandleUpdateCategories =async (event:any)=>
    {
        event.preventDefault();
    
        let errors: Errors = {};

        const CategoryNameList = await GetSubCategoryNameExist();
        if (CategoryNameList.length > 0) {
            setSubCategoriesError(true);
            setSubCategoriesErrorMessage("Sub-Category already exists");
            errors.categories = true;
            return;
        }
    
        const trimmedCategoriesValue = SubCategoriesValue.trim();
        if (trimmedCategoriesValue === '') {
            errors.categories = true;
            setSubCategoriesErrorMessage("Sub-Category is required");
        } else if (trimmedCategoriesValue.length > 255) {
            errors.categories = true;
            setSubCategoriesErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setSubCategoriesError(errors.categories || false);
            return;
        }

        //SOP-97 Inlcuded By PCS
        let EditActive ;
    
        if( isActive === true){
           EditActive = "Y"
        }
        else{
            EditActive='N'
        }
        //SOP-97 Inlcuded By PCS
     
        const UpdateSubCategoriesPayload =
         {
            SaveUpdateKey: "update",
            IssueSubTypeName: SubCategoriesValue,
            IssueSubTypeId: SubCategoryId,
            IssueTypeId:selectedCategoryId,
            //SOP-97 Included by PCS
             CompanyId:CompanyId,
             Active: EditActive,
             LastModifiedBy: UserId,
             IsRemove: null
            //EOF SOP-97 Included by PCS
        
         }
    
        // console.log("AddPL", UpdateSubCategoriesPayload);

         Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor:"#25425f",
                allowOutsideClick: false,
                })
            .then((result) =>
            {
            if (result.isConfirmed)
            {

                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_subcategories_create_update`, UpdateSubCategoriesPayload)
                .then((res) => {
                    if (res.status === 200) {
                        Success("Sub-Category Updated successfully");
                        handleClose(event);
                        handlegetSubcategorieslistsearch(event);
                    }
                    }).catch((error) => {
                        console.error("Error:", error);
                    });
           } 
           else  
            {
                  return
            }
        });

    }
    
    // UseEffect declaration
    useEffect(() => {
        GetSubCategoryListById();
        GetCategoryList()
      }, []); 
 
// Design start
    return(
    <Box>
    <Box>
    <Box display={'flex'} justifyContent={'center'}>
    <Box bgcolor={'white'} borderRadius={3}
    sx={{
        width:{
            lg:900,
            md:600,
            xs:700

        }}}
    >
    <Box p={3}>
        <Typography variant='h1'>
        Edit Ticketing Sub-Category
        </Typography>
    </Box>
    <Box p={3}>
        <form noValidate>
        <Box>
        <Grid container spacing={2} display={'flex'}  justifyContent={'space-evenly'}>
        <Grid item lg={6} md={6} xs={12} >
        <Autocomplete
        id="combo-box-demo"
        disabled
        options={CategoryOptions as OptionType[]}
        filterOptions={filterOptions}
        getOptionLabel={(option: unknown) => (option as OptionType).name}
        
        value={CategoryOptions.find((option: OptionType) => option.value === selectedCategoryId) || null}
        onChange={(event, newValue) => {
            if (newValue) {
                setSelectedCategoryId(newValue.value);
                setCategoryError(false);
                setCategoryErrorMessage("");
            } else {
                setSelectedCategoryId('');
                setCategoryError(true);
                setCategoryErrorMessage("Category is required");
            }
            }}
            sx={{fontSize:'30px'}}
            renderInput={(params) => 
            <TextField {...params}
            
            error={CategoryError}
            helperText={CategoryErrorMessage}
            required label="Category" 
            variant="standard" />}
        />
        </Grid>
        <Grid item lg={6} md={6} xs={12} >
            <TextField
                autoFocus
                type='text'
                variant="standard"
                label='Sub-Category'
                name='SubCategories'
                style={{ margin: '0 10px' }}
                fullWidth
                required
                error={SubCategoriesError}
                onChange={handleSubCategoriesChange}
                value={SubCategoriesValue}
                helperText={SubCategoriesErrorMessage}
            /> 
        </Grid>
        </Grid>
        </Box>
        {/*//SOP-97 Included By PCS  */}
        <Box mt={2} ml={1}>
        <Typography className="label_design" sx={{mt:1.5}}>
        <label style={{fontSize:'0.7rem'}}>Active State</label>
        </Typography>
        <label className="switchToggle">
        <input
            type="checkbox"
            checked={isActive}
            onChange={handleToggleActive} 
        />
        <span className="sliderToggle round"></span>
        </label>
        </Box> 
        {/*EOF  SOP-97 Included By PCS  */}
        <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Button
            sx={{
                background: 'white',
                border: 'none',
                borderRadius: '5px',
                padding:{
                    lg: "0 70px",
                    md: "0 70",
                    xs:'0 50px'
                },
                height: "3rem",
                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
               onClick={handleClose} 
            >
            Back
            </Button>
            <Button
                sx={{
                    background: '#25425F',
                    border: 'none',
                    borderRadius: '5px',
                    padding:{
                        lg: "0 70px",
                        md: "0 70",
                        xs:'0 50px'
                    },
                    "&:hover":{
                        backgroundColor:'#25425F',
                        color:'white',
                    },
                    color: 'white',
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                    type='button'
                    onClick={(event)=>{
                    HandleUpdateCategories(event);
                }}
                 >
                Update
                </Button>
                </Box>
                </form>
                </Box>
                </Box>
                </Box>
            </Box>
        </Box>
    )
}
// Page end

export default EditTicketingSubCategories;
//EOF  SOP-97 Included By PCS [Ticketing Masters -Sub Categories]
