//SOP-97 Included by PCS [Ticketing Masters -Category]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
   
} from '@mui/material';
import Typography from '@mui/material/Typography';

import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import './EditTicketingCategories.scss'
import Swal from "sweetalert2";


// Page start
const EditTicketingCategoriesPage = 
// To get handleEditCategoriesClose & getcategorieslistsearch from catgories page
({
    handleEditCategoriesClose,
    getcategorieslistsearch,
    CategoryId
}: {
    handleEditCategoriesClose: (event: React.MouseEvent) => void,
    getcategorieslistsearch: (event: React.MouseEvent) => void,
    CategoryId: any;
}) =>{

    //Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId;
    const UserId = GlobalData.GlobalUserId;
   
    //  use state declaration
    const [CategoriesError, setCategoriesError]=useState(false)
    const [CategoriesValue, setCategoriesValue]=useState('')
    const [CategoriesErrorMessage, setCategoriesErrorMessage]=useState('')
    const [isActive, setIsActive] = useState(true); //SOP-97 Included By PCS
    //[to avoid duplicate category]
    const [CategoriesValueEndTrim, setCategoriesValueEndTrim]=useState('')
    
    //SOP-97 Inlcuded By PCS
    const handleToggleActive = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };
    //EOF  SOP-97 Inlcuded By PCS

    
    //  Handle Category while typing category and validations
    const handleCategoriesChange = (event:any) =>
    {
        const trimmedCategoriesValue = event.target.value.trim();
        setCategoriesValue(event.target.value);
        //to avoid duplicate category
         setCategoriesValueEndTrim(trimmedCategoriesValue);
           if (trimmedCategoriesValue === '') {
                setCategoriesError(true);
                setCategoriesErrorMessage("Category is required");
            } 
            else if( trimmedCategoriesValue.length >255 ){
                setCategoriesError(true);
                setCategoriesErrorMessage("Max 255 characters");
            }
            else {
                setCategoriesError(false);
                setCategoriesErrorMessage("");
            }
    };

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditCategoriesClose && typeof handleEditCategoriesClose === 'function') {
            handleEditCategoriesClose(event);
        }
     };

    // to render getcategorieslistsearch function after closing dialouge box
    const handlegetcategorieslistsearch= (event: React.MouseEvent) => {
        if (getcategorieslistsearch && typeof getcategorieslistsearch === 'function') {
            getcategorieslistsearch(event);
        }
     };
  
    // To get categories details as per the id
    const GetCategoryListById =()=>
    {
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_categories_list_by_id`, {
            IssueTypeId:CategoryId,
            CompanyId:CompanyId//SOP-97 Inlcuded By PCS
        })
        .then((res: any) => 
        { 
            const CategoryData = res.data.data[0]
            // console.log('CategoryData',CategoryData)
             setCategoriesValue(CategoryData.sys_issue_type_name)
            //SOP-97 Inlcuded By PCS
            if(CategoryData.active === 'Y'){
                setIsActive(true)
            }
            else{
                setIsActive(false)
            }
            //EOF SOP-97 Inlcuded By PCS
        
        }).catch((error) =>  
        { 
        Failed(error.response.message);
            });
    }

    // To check Exist Category name 
    const GetCategoryNameExist = async () => {   
        const  ExistCategoryNamePayload = 
        {
        ExistIssueTypeName:CategoriesValueEndTrim,
        IssueTypeId:CategoryId,
        CompanyId:CompanyId //SOP-97 Included By PCS
    }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_category_name_exists`,ExistCategoryNamePayload);
        // console.log(res.data.data);
        return res.data.data || [];
           } 
        catch (err) {
        console.log( "co",err); 
        }
    };

    //To Update categories
     type Errors = {
        categories?:boolean;
                  };
    const HandleUpdateCategories = async (event:any)=>
    {
        event.preventDefault();
        let errors: Errors = {};
       const CategoryNameList = await GetCategoryNameExist();
        if (CategoryNameList.length > 0) {
            setCategoriesError(true);
            setCategoriesErrorMessage("Category already exists");
            errors.categories = true;
            return;
        }
    
        const trimmedCategoriesValue = CategoriesValue.trim();
        if (trimmedCategoriesValue === '') {
            errors.categories = true;
            setCategoriesErrorMessage("Category is required");
        } 
        else if (trimmedCategoriesValue.length > 255) {
            errors.categories = true;
            setCategoriesErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length >0) {
            // If there are errors, update the state with all the errors
            setCategoriesError(errors.categories || false);
            return;
        }
        //SOP-97 Inlcuded By PCS
        let EditActive ;
    
        if( isActive === true){
           EditActive = "Y"
        }
        else{
            EditActive='N'
        }
        //EOF SOP-97 Inlcuded By PCS
        const UpdateCategoriesPayload =
        {
             SaveUpdateKey: "update",
             IssueTypeName: CategoriesValue,
             IssueTypeId: CategoryId,
             //SOP-97 Included by PCS
             CompanyId:CompanyId,
             Active: EditActive,
             LastModifiedBy: UserId,
             IsRemove: null
            //EOF SOP-97 Included by PCS
        }
        //console.log("Update Payload", UpdateCategoriesPayload);
        Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
            if (result.isConfirmed)
            {
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_categories_create_update`, UpdateCategoriesPayload)
                .then((res) => {
                if (res.status === 200) {
                Success("Category Updated successfully");
                handleClose(event);
                handlegetcategorieslistsearch(event);
                }
                }).catch((error) => {
                    console.error("Error:", error);
                });
            } 
            else  
            {
            return
            }
      });

    }
    //EOF To Update categories

    // UseEffect declaration
    useEffect(() => {
        GetCategoryListById();
      }, []); 
 
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
     };

// Design start
    return(
    <Box>
    <Box>
    <Box display={'flex'} justifyContent={'center'}>
        <Box bgcolor={'white'} borderRadius={3}
        sx={{
        width:{
            lg:900,
            md:600,
            xs:700

        }}}
        >
    <Box p={3}>
    <Typography variant='h1'> Edit Ticketing Category</Typography>
    </Box>
    <Box p={3}>
        <form  noValidate>
        <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
            <Box>
                <TextField
                    onKeyDown={handleKeyDown}
                    autoFocus
                    type='text'
                    variant="standard"
                    label='Category'
                    name='Categories'
                    style={{ margin: '0 10px' }}
                    fullWidth
                    required
                    error={CategoriesError}
                    onChange={handleCategoriesChange}
                    value={CategoriesValue}
                    helperText={CategoriesErrorMessage}
                /> 
            </Box>
            </Grid>
        </Grid>
        {/*//SOP-97 Included By PCS  */}
        <Box mt={2} ml={1}>
        <Typography className="label_design" sx={{mt:1.5}}>
        <label style={{fontSize:'0.7rem'}}>Active State</label>
        </Typography>
        <label className="switchToggle">
        <input
            type="checkbox"
            checked={isActive}
            onChange={handleToggleActive} 
        />
        <span className="sliderToggle round"></span>
        </label>
        </Box> 
        {/* SOP-97 Included By PCS  */}
        <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Button
            sx={{
                background: 'white',
                border: 'none',
                borderRadius: '5px',
                padding:{
                lg: "0 70px",
                md: "0 70",
                xs:'0 50px' },
                            
                height: "3rem",
                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
            onClick={handleClose}>Back
        </Button>
        <Button
            sx={{
                background: '#25425F',
                border: 'none',
                borderRadius: '5px',
                padding:{
                    lg: "0 70px",
                    md: "0 70",
                    xs:'0 50px'
                },
                "&:hover":{
                    backgroundColor:'#25425F',
                    color:'white',
                },
                color: 'white',
                height: "3rem",
                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
            }}
            type='submit'
            onClick={(event)=>{
                HandleUpdateCategories(event);
                }}
            >
            Update
        </Button>
        </Box>
        </form>
    </Box>
    </Box>
    </Box>
    </Box>
    </Box>
    )}

export default EditTicketingCategoriesPage;
//EOF SOP-97 Included by PCS [Ticketing Masters - Category]