//SOP-55 - TS commented - start
// Asset Management - Ticketing - View and Edit Page

// // Included by sanjana SOP-26 to create raise a ticket page
// // Imports
// import { useState,useRef,useEffect } from 'react'; // Import useState hook
// import React from 'react';
// import {
//     Box,
//     Button,
//     TextField,
//     InputAdornment,
//     Avatar,
//     useMediaQuery,
//     Menu,
//     MenuItem,
//     Grid,
//     Paper,
//     Divider,
//     Tooltip,
//     Dialog,
//     FormControl,
//     FormLabel,
//     RadioGroup,
//     FormControlLabel,
//     Radio,
//     FormHelperText,
// } from '@mui/material';
// import Typography from '@mui/material/Typography';
// import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
// import { commonService } from '../../atic-common-helpers/helpers/common.service';
// import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
// import deleteAlert from '../../component/HOC/deleteAlert';
// import Swal from 'sweetalert2';




// const ViewRaiseATicketPage  = () => {
// // ({
// //     handleViewTicketClose,
// //     handleEditTicketOpen,
// //     TicketId,
// // }: {
// //     handleViewTicketClose: (event: React.MouseEvent) => void,
// //     handleEditTicketOpen: (event: React.MouseEvent) => void,
// //     TicketId:any
// // })=>{




//     const [IssuediscriptionValue, setIssuediscriptionValue]=useState('')
//     const [IssueTypeOptions, setIssueTypeOptions] = useState<OptionType[]>([]);
//     const [selectedIssueTypeId, setSelectedIssueTypeId] = useState<string | null>('');
//     // Included by sanjana SOP-28 usestate
//     const [TicketTypeValue, setTicketTypeValue]=useState('1')
//     const [TicketAllocatedSts, setTicketAllocatedSts]=useState('')
//     // Endof SOP-28


//     type OptionType = {
//         name: string;
//         value: string;
//     };
//     const filterOptions = createFilterOptions<OptionType>({
//         matchFrom: 'start',
//         stringify: (option) => option.name,
//     });


//     // To render handle close function to close dialouge box
//     // const handleClose = (event: React.MouseEvent) => {
//     //     if (handleViewTicketClose && typeof handleViewTicketClose === 'function') {
//     //         handleViewTicketClose(event);
//     //     }
//     // };

//     // to render handleEditTicketOpen function after closing dialouge box
//     // const EditTicketOpen= (event: React.MouseEvent) => {
//     //     // if (handleEditTicketOpen && typeof handleEditTicketOpen === 'function') {
//     //     //     handleEditTicketOpen(TicketId);
//     //     // }
//     //     // for - user should edit the ticket if the ticket is already allocated
//     //     if(handleEditTicketOpen && typeof handleEditTicketOpen === 'function' && TicketAllocatedSts === "Y"){
//     //         //console.log("actionType E",actionType)
//     //         Swal.fire({
//     //             html: '<span style="font-size: 1.3rem;">Ticket is already allocated, You cannot edit</span>',
//     //             confirmButtonText: 'OK',
//     //             });
//     //     }
//     //     else if(handleEditTicketOpen && typeof handleEditTicketOpen === 'function' && TicketAllocatedSts === "N"){
//     //         handleEditTicketOpen(TicketId)
//     //     }
//     // };

//     // Included by sanjana SOP-28 Handle IssueType while typing IssueType and validations
//          const handleTicketTypeChange = (event:any) => {
//             setTicketTypeValue(event.target.value);
//         };
//     // End of SOP-28

//     //  Global data from session storage
//     const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
//     const GlobalData = JSON.parse(GlobalDataFromSession);
//     //console.log("GlobalData",GlobalData)
//     let CompanyId = GlobalData.GlobalCompanyId
//     const UserId = GlobalData.GlobalUserId;


//     const GetIssueTypeList = async () => {
//         try {
//             const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueTypeList`);
//             //console.log("IssueType" ,res.data.data);
//             let formattedIssueType = res.data.data.map((item:any) => {
//             const { sys_issue_type_id, sys_issue_type_name } = item;
//             return {
//                 name: sys_issue_type_name,
//                 value: sys_issue_type_id,
//             };
//             });
//             setIssueTypeOptions(formattedIssueType)
//         } catch (err) {
//             //console.log( "co",err);
//         }
//         };

//     // To get categories details as per the id
//     const GetTickteListById =()=>{
//         commonService
//          .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getTicketListById`, {
//             // TicketId:TicketId,
//             CreatedBy: UserId,
//             CompanyId:CompanyId
//          })
//          .then((res: any) =>
//          {
//             //console.log("res",res)
//             const TicketData = res.data.data[0]
//             console.log("TicketData",TicketData)
//             setIssuediscriptionValue(TicketData.ticket_issuedescription)
//             // Included by sanjana SOP-28 to pass ticket_type value for TicketTypeValue
//             setTicketTypeValue(TicketData.ticket_type)
//             setTicketAllocatedSts(TicketData.ticket_allocatestatus)
//             // End of SOP-28
//             let formattedIssueType = res.data.data.map((item:any) => {
//                 const { ticket_issuetype, sys_issue_type_name } = item;
//                 return {
//                     name: sys_issue_type_name,
//                     value: ticket_issuetype,
//                 };
//                 });
//                 //console.log("formattedIssueType",formattedIssueType[0].value)
//                 setSelectedIssueTypeId(formattedIssueType[0].value)
//          }).catch((error) =>
//          {
//           Failed(error.response.message);
//          });
//     }


//         useEffect(() => {
//             GetTickteListById();
//             GetIssueTypeList();
//         }, []);
        
    

//     return(
//         <Box>
//             <Box>
               
//             {/* SOP-45 - TS -Alignment Issue- start */}
//                 {/* Home Page - Raise a Ticket - Popup */}
//                 {/* <Box display={'flex'} justifyContent={'center'}> */}
//                 <Box display={'flex'} >
//             {/* SOP-45 - TS - end */}
                    
//                     <Box bgcolor={'white'} borderRadius={3}
//                     sx={{
//                         width:{
//                             lg:900,
//                             md:600,
//                             xs:700
        
//                         }}}
//                     >
//                     <Box p={3}>
//                         <Typography variant='h1'
//                         sx={{
//                             fontSize:{
//                                 lg:'2rem',
//                                 md:'2rem',
//                                 xs:'1.5rem'
//                             }
//                         }}
//                         >
//                         Raise A Ticket
//                         </Typography>
//                     </Box>
//                         <Box p={3}>
//                                 <Box>
//                                 <Grid container spacing={2} display={'flex'} justifyContent={'space-evenly'}>
                                    
//                                     {/* Included by sanjana SOP-28 to add radio for view */}
//                                     <Grid item lg={12} md={12} xs={12}>
//                                         <FormControl>
//                                         <FormLabel id="demo-controlled-radio-buttons-group">Ticket Type</FormLabel>
//                                         <RadioGroup
//                                             aria-labelledby="demo-controlled-radio-buttons-group"
//                                             name="controlled-radio-buttons-group"
//                                             value={TicketTypeValue}
//                                             onChange={handleTicketTypeChange}
//                                         >
//                                             <Box display={'flex'}>
//                                             <FormControlLabel value="1" control={<Radio />} disabled label="Incident" />
//                                             <FormControlLabel value="2" control={<Radio />} disabled label="Request" />
//                                             </Box>

//                                         </RadioGroup>
//                                         </FormControl>
//                                         </Grid>
//                                     {/* End of SOP-28 */}
//                                         <Grid item lg={12} md={12} xs={12} >
//                                         <Autocomplete
//                                                 id="combo-box-demo"
//                                                 disabled
//                                                 options={IssueTypeOptions as OptionType[]}
//                                                 filterOptions={filterOptions}
//                                                 getOptionLabel={(option: unknown) => (option as OptionType).name}
//                                                 value={IssueTypeOptions.find((option: OptionType) => option.value === selectedIssueTypeId) || null}
//                                                 onChange={(event, newValue) => {
//                                                     if (newValue) {
//                                                         setSelectedIssueTypeId(newValue.value);
//                                                     } else {
//                                                         setSelectedIssueTypeId('');
//                                                     }
//                                                 }}
//                                                 // sx={{ width: 235, ml: 1 }}
//                                                 renderInput={(params) =>
//                                                 <TextField {...params}
//                                                     required label="Issue Type"
//                                                     variant="standard" />}
//                                             />
//                                         </Grid>
//                                         <Grid item lg={12} md={12} xs={12} >
//                                             {/* Modified by sanjana SOP-28 to add new description field */}
//                                             {/* <TextField
//                                                 type='text'
//                                                 variant="standard"
//                                                 label='Issue Description'
//                                                 name='Issuediscription'
//                                                 style={{ margin: '0 10px' }}
//                                                 fullWidth
//                                                 multiline
//                                                 disabled
//                                                 value={IssuediscriptionValue}
//                                             />  */}
//                                             <TextField
//                                             id="outlined-basic"
//                                             label="Issue Description"
//                                             variant="outlined"
//                                             name='Issuediscription'
//                                             fullWidth
//                                             multiline
//                                             disabled
//                                             rows={4}
//                                             value={IssuediscriptionValue}
//                                             />
//                                             {/* End of SOP-28 */}
//                                         </Grid>
//                                     </Grid>
                                    

//                                 </Box>
//                             <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
//                                 <Button
//                                     sx={{
//                                         background: 'white',
//                                         border: 'none',
//                                         borderRadius: '5px',
//                                         padding:{
//                                             lg: "0 70px",
//                                             md: "0 70",
//                                             xs:'0 50px'
                
//                                         },
//                                         height: "3rem",
//                                         boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                                     }}

//                                     // onClick={handleClose}
//                                 >
//                                     Back
//                                 </Button>

//                                 <Button
//                                     sx={{
//                                         background: '#25425F',
//                                         border: 'none',
//                                         borderRadius: '5px',
//                                         "&:hover":{
//                                             backgroundColor:'#25425F',
//                                             color:'white',
//                                         },
//                                         padding:{
//                                             lg: "0 70px",
//                                             md: "0 70",
//                                             xs:'0 50px'
                
//                                         },
//                                         color: 'white',
//                                         height: "3rem",
//                                         boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                                     }}
//                                     type='submit'
//                                     onClick={(event)=>{
//                                         // EditTicketOpen(TicketId);
//                                         // handleClose(event);
//                                     }}
//                                 >
//                                     Edit
//                                 </Button>
//                                 </Box>
//                         </Box>

//                     </Box>
//                 </Box>
//             </Box>
//         </Box>
//     )

// }

// export default ViewRaiseATicketPage;

// // End of SOP-26


//SOP-55 - TS commented - end







//SOP-55 - TS commented - start
// Asset Management-Ticketing-View and Edit Page
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate , useParams} from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import Swal from 'sweetalert2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Dayjs } from 'dayjs';



const ViewRaiseATicketPage  = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    // IssueType usestate 
    const [IssueTypeOptions, setIssueTypeOptions] = useState<OptionType[]>([]);
    const [selectedIssueTypeId, setSelectedIssueTypeId] = useState<string | null>('');
   
    // IssueSubType usestate 
    const [IssueSubTypeOptions, setIssueSubTypeOptions] = useState<OptionType[]>([]);
    const [selectedIssueSubTypeId, setSelectedIssueSubTypeId] = useState<string | null>('');
    // console.log("selectedIssueSubTypeIds",IssueSubTypeOptions)
   
    // ContactType usestate 
    const [ContactTypeOptions, setContactTypeOptions] = useState<OptionType[]>([]);
    const [selectedContactTypeId, setSelectedContactTypeId] = useState<string | null>('');
    // console.log("selectedContactTypeId",selectedContactTypeId)
  
    // PriorityType usestate
    const [PriorityTypeOptions, setPriorityTypeOptions] = useState<OptionType[]>([]);
    const [selectedPriorityTypeId, setSelectedPriorityTypeId] = useState<string | null>('');
      
    // Employee usestate
    const [EmployeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>('');
    // console.log("selectedEmployeeId",selectedEmployeeId)
  
    // Impact usestate
    const [ImpactOptions, setImpactOptions] = useState<OptionType[]>([]);
    const [selectedImpactId, setSelectedImpactId] = useState<string | null>('');
    // console.log("selectedImpactId", selectedImpactId)
    
    // ShortDiscriptio useState
    const [ShortDiscriptionValue, setShortDiscriptionValue] = useState(''); // ShortDiscription usestate
    const [DiscriptionValue, setDiscriptionValue] = useState(''); // Discription usestate
    const [LocationValue, setLocationValue] = useState('')  // Location usestate
    const [TicketIdValue, setTicketIdValue] = useState('')   // TicketId usestate

    // TicketDueDate usestate
    const [TicketDueDateValue, setTicketDueDateValue] = useState<dayjs.Dayjs | null>(null);
    const [TicketDueDateError, setTicketDueDateError]=useState(false)
    const [TicketDueDateErrorMessage, setTicketDueDateErrorMessage] = useState('')

    // TicketstartDueDate usestate
    const [TicketstartDateValue, setTicketstartDateValue] = useState<dayjs.Dayjs | null>(null);


    //SOP-72 Included By PCS
       const [TicketClosedDate, setTicketClosedDate] = useState<dayjs.Dayjs | null>(null);
       const [TicketStatusValue, setTicketStatusValue] = useState('')
    //EOF SOP-72 Included By PCS

    //    console.log("TicketstartDateValue",TicketstartDateValue)
    const [attachment, setAttachment] = useState<File | null>(null);
    const [attachmentBase64, setAttachmentBase64] = useState<string | null>(null);

    //SOP-72 Included By PCS [closure information]
       const [ClosureInformationOptions, setClosureInformationOptions] =useState<OptionType[]>([]);
       const [selectedClosureInformationId, setselectedClosureInformationId] = useState<string | null>('');


       console.log('16',selectedClosureInformationId)
  //EOF SOP-72 Included By PCS

    // SOP-57 - TS - start
    // Asset Management    - Ticketing - Activity screen
  //SOP-72 Commented By PCS
    // // ClosureInformationOptions
    // const [ClosureInformationOptions, setClosureInformationOptions] = useState<OptionType[]>([]);
    // const [selectedClosureInformation, setSelectedClosureInformation] = useState<string | null>('');
    // // SOP-57- TS - end
  //EOF SOP-72 Commented By PCS
    // console.log("selectedIssueTypeId", selectedIssueTypeId)
    // console.log("selectedIssueSubTypeId", selectedIssueSubTypeId)
    // console.log("IssueSubTypeOptions",IssueSubTypeOptions)

    
    //SOP-71 Included By PCS  [to initialize vendor Ticket ID]  
    const [VendorTicketIDValue, setVendorTicketIDValue] = useState('');
    //EOF SOP-71 Included By PCS

    //SOP-71 Included By PCS  [to initialize variables for activity details]
        const [CreatedDetails, setCreatedDetails] = useState('')
        const [AllocatedDetails, setAllocatedDetails] = useState('')
        const [AllocateRemarks, setAllocateRemarks] = useState('')
        const [OpenStatus, setOpenStatus] = useState('')
        const [CurrentStatus, setCurrentStatus] = useState('')
        const [ServiceRemarks, setServiceRemarks] = useState('')
        const [ClosedDetails, setClosedDetails] = useState('')
    //EOF SOP-71 Included By PCS

    //SOP-71 Included By PCS [to display avatar ]
    const shouldDisplayAvatar = (variable:any) => !!variable; // Returns true if variable has a value


    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    // GlobalData
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId;
    const UserId = GlobalData.GlobalUserId;

    //SOP-98 Included By PCS [to get User Role Name]
    const UserRole = GlobalData.GlobalUserRole;
    //EOF SOP-98 Included By PCS


    // GetIssueTypeList API
    const GetIssueTypeList = async () => { 

            //SOP-97 Included By PCS [filter by company id]
            const payloadValues = {
                CompanyId: CompanyId,
                active: "Y"
            }
            //EOF SOP-97 Included By PCS [filter by company id]

        try {
            
        //SOP-97 Modified By PCS [filter by company id]
        // const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueTypeList`);
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueTypeList`, payloadValues);
        //EOF SOP-97 Modified By PCS [filter by company id]
        //console.log("IssueType" ,res.data.data);
            let formattedIssueType = res.data.data.map((item:any) => {
            const { sys_issue_type_id, sys_issue_type_name } = item;
            return {
                name: sys_issue_type_name,
                value: sys_issue_type_id,
            };
            });
            setIssueTypeOptions(formattedIssueType)
        } catch (err) {
            //console.log( "co",err);
        }
    };

    
        // IssueSubType List API
    const GetIssueSubTypeList = async (issueTypeId: string) => { 
         //SOP-97 Included By PCS [filter by company id]
         const payloadValues = {
            issuetypeid:issueTypeId,
            CompanyId: CompanyId,
            active: "Y",
        }
        //EOF SOP-97 Included By PCS [filter by company id]


        try {

        //SOP-97 Modified By PCS [filter by company id]
        // const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueSubTypeList`, { issuetypeid: issueTypeId });
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getIssueSubTypeList`,payloadValues );
        //EOF SOP-97 Modified By PCS [filter by company id]



            //console.log("IssueSubType" ,res.data.data);
            let formattedIssueSubType = res.data.data.map((item:any) => {
                const { sys_issue_subtype_id, sys_issue_subtype_name } = item;
                console.log("item",item)
            return {
                name: sys_issue_subtype_name,
                value: sys_issue_subtype_id,
            };
            });
            // console.log("IssueSubType",formattedIssueSubType)
            setIssueSubTypeOptions(formattedIssueSubType)
        } catch (err) {
            //console.log( "co",err);
        }
    };
    
    // ContactType List API
    const GetContactTypeList = async () => { 
        
        //SOP-97 Included By PCS [filter by company id]
        const payloadValues = {
            CompanyId: CompanyId,
            active: "Y",
            }
        //EOF SOP-97 Included By PCS [filter by company id]
        try {


            //SOP-97 Modified By PCS [filter by company id]
            // const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getContactTypeList`);
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getContactTypeList`,payloadValues);
            //EOF SOP-97 Modified By PCS [filter by company id]


            //console.log("IssueType" ,res.data.data);
            let formattedContactType = res.data.data.map((item:any) => {
            const { sys_contact_type_id, sys_contact_type_name } = item;
            return {
                name: sys_contact_type_name,
                value: sys_contact_type_id,
            };
            });
            setContactTypeOptions(formattedContactType)
        } catch (err) {
            //console.log( "co",err);
        }
    };
        
    // PriorityType List API
    const GetPriorityTypeList = async () => { 

          //SOP-97 Included By PCS [filter by company id]
          const payloadValues = {
            CompanyId: CompanyId,
            active: "Y",
            }
        //EOF SOP-97 Included By PCS [filter by company id]


        try {
            
            //SOP-97 Modified By PCS [filter by company id]
            //  const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getPriorityList`);
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getPriorityList`,payloadValues);
            //EOF SOP-97 Modified By PCS [filter by company id]
            
            
            //console.log("IssueType" ,res.data.data);
            let formattedPriorityType = res.data.data.map((item:any) => {
            const { sys_priority_id, sys_priority_name } = item;
            return {
                name: sys_priority_name,
                value: sys_priority_id,
            };
            });
            setPriorityTypeOptions(formattedPriorityType)
        } catch (err) {
            //console.log( "co",err);
        }
    };

    // Employee List API
    const GetEmployeeList = async () => { 
        const EmployeeLisPayload ={
            CompanyId:CompanyId,
            active:"Y"
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getEmployeeList`,EmployeeLisPayload);
            //console.log("IssueType" ,res.data.data);
            let formattedEmployeeType = res.data.data.map((item:any) => {
                const { employee_id, employee_name } = item;
                console.log("item",item)
            return {
                name: employee_name,
                value: employee_id,
            };
            });
            setEmployeeOptions(formattedEmployeeType)
        } catch (err) {
            //console.log( "co",err);
        }
    };

    // Impact List API
    const GetImpactList = async () => { 

         //SOP-97 Included By PCS [filter by company id]
         const payloadValues = {
            CompanyId: CompanyId,
            active: "Y",
            }
        //EOF SOP-97 Included By PCS [filter by company id]

        try {

            //SOP-97 Modified By PCS [filter by company id]
            //  const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getImpactList`);
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getImpactList`,payloadValues);
            //EOF SOP-97 Modified By PCS [filter by company id]



            console.log("IssueType" ,res.data.data);
            let formattedImpactType = res.data.data.map((item:any) => {
            const { sys_impact_id, sys_impact_name } = item;
            return {
                name: sys_impact_name,
                value: sys_impact_id,
            };
            });
            setImpactOptions(formattedImpactType)
        } catch (err) {
            //console.log( "co",err);
        }
    };

     // SOP-57 - TS - start
    // Asset Management    - Ticketing - Activity screen
        // ClosureInformation API
        const GetClosureInformationList = async () => { 
            const payload ={
                //SOP-97 Included By PCS
                CompanyId: CompanyId,
                active: "Y",
                //EOF SOP-97 Included By PCS
           }

            try {
                
                //SOP-72_A Modified By PCS[16/08/2024]
                // const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getClosureInformationList`);
                //SOP-97 Modified By PCS
                // const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_closure_information_list`);
                 const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_closure_information_list`,payload);
                //EOF SOP-97 Modified By PCS
               //EOF SOP-72_A Modified By PCS[16/08/2024] 

                let formattedClosureInformationType = res.data.data.map((item: any) => {
                    // console.log("formattedClosureInformationType" ,formattedClosureInformationType);
                    const { sys_ticket_closure_information_id, sys_ticket_closure_information_name } = item;
                    // console.log("item1",item)
                return {
                    name: sys_ticket_closure_information_name,
                    value: sys_ticket_closure_information_id,
                };
                });
                setClosureInformationOptions(formattedClosureInformationType)
            } catch (err) {
                //console.log( "co",err);
            }
        };
    // SOP-57 - TS - end
    
    // // To get categories details as per the id 
    const GetTickteListById =()=>{
       
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getTicketListById`, {
            TicketId:id,
            CreatedBy: UserId,
            CompanyId:CompanyId
         })
         .then((res: any) => 
         { 
            
            const TicketData = res.data.data[0]
             console.log(" record",TicketData)

            if (TicketData) {
                setSelectedIssueTypeId(TicketData.ticket_issuetype);
                setSelectedIssueSubTypeId(TicketData.sys_issue_subtype_id);
            }

             setShortDiscriptionValue(TicketData.ticket_issueshortdescription)
             setDiscriptionValue(TicketData.ticket_issuedescription)
             setLocationValue(TicketData.location)
             setTicketDueDateValue(TicketData.ticket_due_date)
             setTicketIdValue(TicketData.ticket_number)
             setTicketstartDateValue(TicketData.ticket_date)

             setVendorTicketIDValue(TicketData.vendor_ticket_id) // SOP-71 Inlcuded By PCS
             setTicketClosedDate(TicketData.service_completedate) //SOP-72 Included By PCS
             setTicketStatusValue(TicketData.sys_completion_status_name) // SOP-72 Inlcuded By PCS
            
            // formatted IssueType
            let formattedIssueType = res.data.data.map((item:any) => {
                const { ticket_issuetype, sys_issue_type_name } = item;
                return {
                    name: sys_issue_type_name,
                    value: ticket_issuetype,
                };
                });
             setSelectedIssueTypeId(formattedIssueType[0].value)   
             
            //  formatted IssueSubType
             let formattedIssueSubType = res.data.data.map((item: any) => {
                 
                 const { ticket_issuesubtype, sys_issue_subtype_name } = item;
                 console.log("item new",item)
                return {
                    name: sys_issue_subtype_name,
                    value: ticket_issuesubtype,
                };
             });
            //  console.log("formattedIssueSubType......",formattedIssueSubType)
                setSelectedIssueSubTypeId(formattedIssueSubType[0].value) 
             
            // formatted ContactType
             let formattedContactType = res.data.data.map((item:any) => {
                const { sys_contact_type_id, sys_contact_type_name } = item;
            return {
                name: sys_contact_type_name,
                value: sys_contact_type_id,
            };
                });
                setSelectedContactTypeId(formattedContactType[0].value) 
             
            // formatted PriorityType 
             let formattedPriorityType = res.data.data.map((item:any) => {
                 const { sys_priority_id, sys_priority_name } = item;
                 return {
                    name: sys_priority_name,
                    value: sys_priority_id,
                };
                });
             setSelectedPriorityTypeId(formattedPriorityType[0].value) 
             
            //  formatted Employee
             let formattedEmployee = res.data.data.map((item: any) => {
                const { employee_id, employee_name } = item;
                return {
                    name: employee_name,
                    value: employee_id,
                };
                });
                setSelectedEmployeeId(formattedEmployee[0].value) 

            //  formatted ImpactType
             let formattedImpactType = res.data.data.map((item:any) => {
               const { sys_impact_id, sys_impact_name } = item;
            return {
                name: sys_impact_name,
                value: sys_impact_id,
            };
                });
             setSelectedImpactId(formattedImpactType[0].value) 


            //SOP-72 Included by PCS [Closure Information from DB]
            let formattedClosureInformation = res.data.data.map((item:any) => {
                
                const { closure_information, closure_information_name } = item;
                return {
                    name: closure_information_name,
                    value: closure_information,
                };
                });
                setselectedClosureInformationId(formattedClosureInformation[0].value)

            //EOF SOP-72 Included by PCS


//SOP-72 Commented below closure information
            // SOP-57 - TS - start
            //  Asset Management    - Ticketing - Activity screen
            //  formatted ClosureInformationType
            //   let formattedClosureInformationType = res.data.data.map((item:any) => {
            //     const { sys_ticket_closure_information_id, sys_ticket_closure_information_name } = item;
            //     return {
            //      name: sys_ticket_closure_information_name,
            //      value: sys_ticket_closure_information_id,
            //     };
            //     });
            //     setSelectedClosureInformation(formattedClosureInformationType[0].value) 
            // SOP-57 - TS - end
//SOP-72 Commented  closure information     


         }).catch((error) =>  
         { 
            Failed(error.response.message);
            console.log()
         });
       
    }



//SOP-71 To get Ticket Activity details by TicketId..
const GetTicketActivityDetails =()=>
      {
         const payload={
            CompanyId:CompanyId,
            TicketId:id,
                    }
        // console.log('ActivityDetails Payload',payload)

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/getTicketActivityDetails`, payload)
        .then((res: any) => 
        { 
            // console.log("res activity details",res)
            const TicketData = res.data.data[0]
            // console.log("Activity Details ",TicketData)

            setCreatedDetails(TicketData.CreatedDetails)
            setAllocatedDetails(TicketData.AllocatedDetails)
            setAllocateRemarks(TicketData.AllocateRemarks)
            setOpenStatus(TicketData.OpenStatus)
            setCurrentStatus(TicketData.CurrentStatus)
            setServiceRemarks(TicketData.ServiceRemarks)
            setClosedDetails(TicketData.ClosingDetails)
           
        }).catch((error) =>  
        { 
        Failed(error.response.message);
        });
    }
//EOF SOP-71 To get Ticket Activity details by TicketId..



   // To handle TicketDueDateChange validation
   const handleTicketDueDateChange =(date:any)=>{
    setTicketDueDateValue(date);
        if (!date) {
            setTicketDueDateError(true)
            setTicketDueDateErrorMessage("Due Date is required");
        }
        else {
            setTicketDueDateError(false);
            setTicketDueDateErrorMessage("")
        }
    }
    
    const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setAttachment(file);
    };

    // To handle ShortDiscription validation
    const handleShortDiscriptionChange =( event:any)=>{
        const trimmedShortDiscriptionValue = event.target.value.trim();
            setShortDiscriptionValue(event.target.value);
        
        }

    // To handle DiscriptionChange validation
    const handleDiscriptionChange =( event:any)=>{
        const trimmedDiscriptionValue = event.target.value.trim();
        setDiscriptionValue(event.target.value);
    
    }

    // To handle LocationChange validation
    const handleLocationChange =( event:any)=>{
        const trimmedDiscriptionValue = event.target.value.trim();
        setLocationValue(event.target.value);
    
    }
    
type Errors = {
    issueType?:boolean;
    issueDiscription?: boolean;
    issueSubType?: boolean;
    constactType?: boolean;
    priorityType?: boolean;
    employee?: boolean;
    impact?: boolean;
    shortDiscription?: boolean;
    discription?: boolean;
    location?: boolean;
    ticketDueDate?: boolean;
};

    // useEffect
        useEffect(() => {
            GetContactTypeList();
            GetPriorityTypeList();
            GetEmployeeList();
            GetImpactList();
            GetIssueTypeList();
            GetTickteListById();

            // SOP-57 - TS - start
            //  Asset Management    - Ticketing - Activity screen
            GetClosureInformationList();   //Get ClosureInformationList

            GetTicketActivityDetails(); //SOP-71 Included by PCS
        }, []); 
        
    
        useEffect(() => {
            if (selectedIssueTypeId) {
                GetIssueSubTypeList(selectedIssueTypeId);
            }
        }, [selectedIssueTypeId]);

//SOP-71 Included By PCS [to randomly generate color for the first letter of the text]
        function stringToColor(string: string) {
                let hash = 0;
                let i;
            
            
                for (i = 0; i < string.length; i += 1) {
                hash = string.charCodeAt(i) + ((hash << 5) - hash);
                }
            
                let color = '#';
            
                for (i = 0; i < 3; i += 1) {
                const value = (hash >> (i * 8)) & 0xff;
                color += `00${value.toString(16)}`.slice(-2);
                }
                return color;
            }
          
       
            function stringAvatar(name: string) {
                return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: `${name[0]}`, // Use only the first letter of the name
                };
            }
    //EOF SOP-71 Included By PCS      

    return(
        <Box> 
        <Box>          
            <Box display={'flex'} justifyContent={'space-evenly'}>
                <Box bgcolor={'white'} borderRadius={3}
                    sx={{
                        width:{
                            lg:1150,
                            md:650,
                            xs:500
                        }}}
                >
                <Box p={3}>
                    <Typography variant='h1'
                        sx={{
                            fontSize:{
                                lg:'2rem',
                                md:'2rem',
                                xs:'1.5rem'
                            }
                        }}
                    >
                        View Ticket
                    </Typography>
                </Box>
                    <Box p={3}>
                        <form  noValidate>
                            <Box>
                                <Grid container spacing={3.5} mb={4} display={'flex'} 
                                //SOP-98 Modified by PCS[ alignment]
                                // justifyContent={'space-evenly'}
                                justifyContent={'left'}
                                
                                >
                                        <Grid item lg={4} md={12} xs={12}>
                                            <TextField
                                                type='text'
                                                variant="standard"
                                                label='Number'
                                                name='Number'
                                                fullWidth
                                                disabled
                                                value={TicketIdValue}
                                            /> 
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >
                                        <FormControl fullWidth>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Ticket Date"
                                                        value={TicketstartDateValue ? dayjs(TicketstartDateValue) : null}
                                                        format="DD-MM-YYYY"
                                                        disabled
                                                        slotProps={{
                                                            textField: {
                                                            }
                                                        }}
                                                    />                                                        
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-dob-login"
                                                    >
                                                    </FormHelperText>
                                            </LocalizationProvider>
                                        </FormControl> 
                                        </Grid>
                                    {/* //SOP-98 Included By PCS [If user role is 'End User'- Employee dropdrown alignment modified] */}
                                    {UserRole === 'End User'&& (
                                       <Grid item lg={4} md={12} xs={12}>
                                        <Autocomplete
                                                id="combo-box-demo"
                                                
                                                options={EmployeeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={EmployeeOptions.find((option: OptionType) => option.value === selectedEmployeeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedEmployeeId(newValue.value);
                                                       
                                                    }
                                                    else {
                                                        setSelectedEmployeeId('');
                                                     
                                                    }
                                                }}
                                               
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        // error={EmployeeError}
                                                        // helperText={EmployeeErrorMessage}
                                                        // required 
                                                        label="Employee"
                                                        variant="standard"
                                                        
                                                    />}
                                            />
                                        </Grid>
                                    )}
                                    {/*//EOF SOP-98 Included By PCS [If user role is 'End User'- Employee dropdrown alignment modified] */}
                                       
                                        {/* <Box> */}
                                        {/* //SOP-78 Commented By PCS [to remove Ticket Due date field] */}
                                             {/* <FormControl
                                                fullWidth
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Ticket Due Date"
                                                        value={TicketDueDateValue ? dayjs(TicketDueDateValue) : null}
                                                        onChange={handleTicketDueDateChange}
                                                        format="DD-MM-YYYY"
                                                        minDate={dayjs()} // Disable past dates
                                                        disabled
                                                        slotProps={{
                                                            textField: {
                                                                error: Boolean(TicketDueDateError),
                                                                required: true
                                                            }
                                                        }}
                                                    />                                                        
                                                    {TicketDueDateError && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-dob-login"
                                                        >
                                                            {TicketDueDateErrorMessage}
                                                        </FormHelperText>
                                                    )}     
                                                </LocalizationProvider>
                                            </FormControl>  */}
                                        {/* //EOF SOP-78 Commented By PCS [to remove Ticket Due date field] */}                                                   
                                        {/* </Box>  */}
                                    </Grid>
                                    
                                {/* //SOP-98 Included By PCS ['Contact Type not to display for 'End User'] */}
                                <Grid container spacing={3.5} mb={4}display={'flex'} justifyContent={'space-evenly'}>
                                {UserRole !== 'End User'&& (
                                    <Grid item lg={4} md={12} xs={12} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={ContactTypeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                value={ContactTypeOptions.find((option: OptionType) => option.value === selectedContactTypeId) || null}
                                                disabled
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedContactTypeId(newValue.value);
                                                   
                                                    } else {
                                                        setSelectedContactTypeId('');
                                                    }
                                                }}
                                                renderInput={(params) => 
                                                <TextField {...params}
                                                    disabled
                                                    // required SOP-98 Commenetd By PCS
                                                     label="Contact Type" 
                                                    variant="standard"
                                                />}
                                            />
                                    </Grid>
                                    )} 
                            {/* //EOF SOP-98 Included By PCS ['Contact Type not to display for 'End User'] */} 
                            {/* //SOP-98 Included By PCS ['If 'End User'- Employee dropdrown alignment modified] */}
                                {UserRole !== 'End User'&& (
                                        <Grid item lg={4} md={12} xs={12} >                             
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={EmployeeOptions as OptionType[]}
                                            filterOptions={filterOptions}
                                            disabled
                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                            value={EmployeeOptions.find((option: OptionType) => option.value === selectedEmployeeId) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedEmployeeId(newValue.value); 
                                                }
                                                else {
                                                    setSelectedEmployeeId('');
                                                }
                                            }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                disabled
                                                required label="Employee"
                                                variant="standard"
                                                />}
                                        />
                                    </Grid>
                                )}
                            {/* //EOF SOP-98 Included By PCS ['If 'End User'- Employee dropdrown alignment modified] */} 
                            {/* //SOP-98 Included By PCS ['Contact Type not to display for 'End User'] */}
                            {UserRole !== 'End User'&& (
                                  <Grid item lg={4} md={12} xs={12} >
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={ImpactOptions as OptionType[]}
                                            filterOptions={filterOptions}
                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                            disabled
                                            value={ImpactOptions.find((option: OptionType) => option.value === selectedImpactId) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedImpactId(newValue.value);
                                                }
                                                else {
                                                    setSelectedImpactId('');
                                                }
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                    disabled
                                                   // required SOP-98 Commenetd By PCS
                                                    label="Impact"
                                                    variant="standard"
                                                />}
                                        />
                                    </Grid>
                            )}
                        {/* //EOF SOP-98 Included By PCS ['Contact Type not to display for 'End User'] */} 

                                </Grid>

                                <Grid container spacing={3.5} mb={4}display={'flex'} justifyContent={'space-evenly'}>
                                    <Grid item lg={4} md={12} xs={12} >
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={PriorityTypeOptions as OptionType[]}
                                            filterOptions={filterOptions}
                                            getOptionLabel={(option: unknown) => (option as OptionType).name}
                                            value={PriorityTypeOptions.find((option: OptionType) => option.value === selectedPriorityTypeId) || null}
                                            disabled
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedPriorityTypeId(newValue.value);
                                                } else {
                                                    setSelectedPriorityTypeId('');
                                                }
                                            }}
                                            renderInput={(params) => 
                                            <TextField {...params}
                                                disabled
                                               // required SOP-98 Commenetd By PCS
                                                 label="Priority" 
                                                variant="standard"
                                            />}
                                        />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={IssueTypeOptions as OptionType[]}
                                            filterOptions={filterOptions}
                                            getOptionLabel={(option) => option.name}
                                            disabled
                                            value={IssueTypeOptions.find((option: OptionType) => option.value === selectedIssueTypeId) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedIssueTypeId(newValue.value);
                                                } else {
                                                    setSelectedIssueTypeId('');
                                                }
                                            }}
                                            renderInput={(params) => 
                                            <TextField {...params}
                                                disabled
                                               // required SOP-98 Commenetd By PCS
                                                label="Category" 
                                                variant="standard"
                                            />}
                                        />
                                        </Grid>
                                        <Grid item lg={4} md={12} xs={12} >                                           
                                            <Autocomplete
                                                id='Sub Category'
                                                options={IssueSubTypeOptions as OptionType[]}
                                                filterOptions={filterOptions}
                                                getOptionLabel={(option: unknown) => (option as OptionType).name}
                                                disabled
                                                value={IssueSubTypeOptions.find(option => option.value === selectedIssueSubTypeId) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setSelectedIssueSubTypeId(newValue.value);
                                                    } else {
                                                        setSelectedIssueSubTypeId('');
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        disabled
                                                        // required SOP-98 Commenetd By PCS
                                                        label="Sub Category"
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                    </Grid>
                                </Grid>

                                <Grid item mt={2} lg={12} md={12} xs={12} >
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Short Description" 
                                        variant="outlined" 
                                        name='ShortDescription'
                                        required
                                        fullWidth
                                        multiline
                                        rows={3}
                                        onChange={handleShortDiscriptionChange}
                                        value={ShortDiscriptionValue}
                                        disabled
                                    />
                                </Grid>
                                <Grid item mt={2} lg={12} md={12} xs={12} >
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Description" 
                                        variant="outlined" 
                                        name='Description'
                                        fullWidth
                                        multiline
                                        rows={10}
                                        onChange={handleDiscriptionChange}
                                        value={DiscriptionValue}
                                        disabled
                                    />
                                </Grid>
                                    
                                <Grid container spacing={3.5} mb={4}display={'flex'} justifyContent={'space-evenly'}>
                                    <Grid item mt={2} lg={12} md={12} xs={12} >
                                        <TextField 
                                            label="Location" 
                                            variant="standard" 
                                            name='Issuediscription'
                                            fullWidth
                                            onChange={handleLocationChange}
                                            value={LocationValue}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item mt={2} lg={4} md={12} xs={12}>   </Grid>
                                    <Grid item mt={2} lg={4} md={12} xs={12}>   </Grid>
                                </Grid>
                            </Box>
                            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Button
                                    sx={{
                                        background: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        padding:{
                                            lg: "0 70px",
                                            md: "0 70",
                                            xs:'0 50px'
                                        },
                                        // color: {accent},
                                        height: "3rem",
                                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                    }}
                                    onClick={()=>{
                                        navigate('/home')
                                    }} 
                                >
                                    Back
                                </Button>
                                <Button
                                sx={{
                                background: '#25425F',
                                border: 'none',
                                borderRadius: '5px',
                                "&:hover":{
                                    backgroundColor:'#25425F',
                                    color:'white',
                                },
                                padding:{
                                    lg: "0 70px",
                                    md: "0 70",
                                    xs:'0 50px'
        
                                },
                                color: 'white',
                                height: "3rem",
                                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                            }}
                                onClick={()=>{
                                    navigate(`/edit_raise_a_ticket_page/${id}`)
                                }} 
                            >
                                Edit
                            </Button>
                            </Box>
                        </form>
                    </Box>
                    </Box>

                {/* // SOP-57 - TS - start
                //  Asset Management    - Ticketing - Activity screen */}
                    {/* Activity screen - start */}
                    
                    <Box bgcolor={'white'} borderRadius={3}
                        sx={{
                            width:{
                                lg:600,
                                md:650,
                                xs:500
                            }}}
                        >
                        <Box p={3}>
                            <Typography variant='h1'
                                sx={{
                                    fontSize:{
                                        lg:'2rem',
                                        md:'2rem',
                                        xs:'1.5rem'
                                    }
                                }}
                            >
                                Activity
                            </Typography>
                        </Box>

                        <Box p={3}>
                            <form  noValidate>
                                <Box>
                                    <Grid item lg={5} md={12} xs={12} mb={2.5}>
                                        <TextField
                                            type='text'
                                            variant="standard"
                                            label='Status'
                                            name='Status'
                                            fullWidth
                                            disabled
                                            //SOP-72 Included By PCS
                                            value={TicketStatusValue || ''}
                                            // defaultValue={"NEW"}
                                            //EOF SOP-72 Included By PCS
                                        /> 
                                    </Grid>
                                    <Grid item lg={5} md={12} xs={12} mb={2.2} >
                                        <Box>
                                            <FormControl
                                                fullWidth
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Opened"
                                                        value={TicketstartDateValue ? dayjs(TicketstartDateValue) : null}
                                                        // onChange={handleTicketDueDateChange}
                                                        format="DD-MM-YYYY"
                                                        minDate={dayjs()} // Disable past dates
                                                        disabled
                                                        slotProps={{
                                                            textField: {
                                                                error: Boolean(TicketDueDateError),
                                                                required: true
                                                            }
                                                        }}
                                                    />                                                        
                                                    {/* {TicketDueDateError && ( */}
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-dob-login"
                                                        >
                                                            {/* {TicketDueDateErrorMessage} */}
                                                        </FormHelperText>
                                                    {/* )}      */}
                                                </LocalizationProvider>
                                            </FormControl>                                                    
                                        </Box> 
                                    </Grid>

                                    <Grid item lg={5} md={12} xs={12} mb={2.5}>
                                        <Box>
                                            <TextField
                                               
                                                type='text'
                                                variant="standard"
                                                label='Vendor Ticket ID'
                                                name='Vendor Ticket ID'
                                                fullWidth
                                                disabled
                                                //SOP-71 Included By PCS[Included vendor ticket id]
                                                value={VendorTicketIDValue || ''}
                                                //EOF SOP-71 Included By PCS[Included vendor ticket id]
                                            /> 
                                        </Box>
                                    </Grid>
                                    <Grid item lg={5} md={12} xs={12} mb={2.5} >
                                        <Box>
                                            <FormControl
                                                fullWidth
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Closed On"
                                                        // onChange={handleTicketDueDateChange}
                                                        //SOP-72 Included By PCS
                                                        value={TicketClosedDate ? dayjs(TicketClosedDate) : null}
                                                        //EOF SOP-72 Included By PCS
                                                        format="DD-MM-YYYY"
                                                        disabled
                                                        // minDate={dayjs()} // Disable past dates
                                                        slotProps={{
                                                            textField: {
                                                                required: true
                                                            }
                                                        }}
                                                    />                                                        
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-dob-login"
                                                        >
                                                        </FormHelperText>
                                                </LocalizationProvider>
                                            </FormControl>                                                    
                                        </Box> 
                                    </Grid>
                                    <Grid item lg={4} md={12} xs={12} mb={2.5}>
                                    {/* //SOP-72 Included By PCS */}
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={ClosureInformationOptions as OptionType[]}
                                            filterOptions={filterOptions}
                                            disabled
                                            getOptionLabel={(option: unknown) => (option as OptionType).name}

                                            value={ClosureInformationOptions.find((option: OptionType) => option.value === selectedClosureInformationId) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setselectedClosureInformationId(newValue.value);
                                                } else {
                                                    setselectedClosureInformationId('');
                                                }
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params}
                                                // required SOP-98 Commented by PCS
                                                label="Closure Information" 
                                                variant="standard"
                                            />}
                                        />
                                    {/* //EOF SOP-72 Included By PCS */}
                                    </Grid>

        {/* //SOP-71 Inlcuded By PCS */}
            <Grid item lg={12} md={12} xs={12} >
                        {/* <Typography sx={{color:'#3498db'}} variant='h3'>Activity </Typography> */}
                        
                    
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>

                        {shouldDisplayAvatar(CreatedDetails) && (
                            <div style={{ display: 'flex', marginTop: '17px', alignItems: 'center' }}>
                            <Avatar style={{ width: 41, height: 41}} {...stringAvatar(CreatedDetails)} />
                            <Typography sx={{ fontSize: '16px', marginLeft: '8px' }}>
                                {CreatedDetails}
                            </Typography>
                            </div>
                        )}

                        {shouldDisplayAvatar(AllocatedDetails) && (
                        <div style={{display:'flex',marginTop:'12px'}}>
                            <Avatar  style={{ width: 41, height: 41 }}   {...stringAvatar(AllocatedDetails)} />
                            <Typography sx={{fontSize:'16px',marginLeft:'8px'}}  >{AllocatedDetails}</Typography>
                            </div>
                        )}

                        <Typography  sx={{color:'gray',marginLeft:'48px',fontSize:'15px'}} mt={0.75} >{AllocateRemarks}</Typography>

                        {shouldDisplayAvatar(OpenStatus) && (
                            <div style={{display:'flex',marginTop:'12px'}}>
                            <Avatar  style={{ width: 41, height: 41 }}  {...stringAvatar(OpenStatus)} />
                            <Typography sx={{fontSize:'16px',marginLeft:'8px',marginTop:'6px'}}  >{OpenStatus}</Typography>
                            </div>
                        )}

                        <Typography sx={{fontSize:'16px',marginLeft:'52px'}} mt={1} >{CurrentStatus}</Typography> 
                        <Typography sx={{color:'gray',marginLeft:'58px',fontSize:'15px'}} mt={0.75} >{ServiceRemarks}</Typography> 
                            
                        {shouldDisplayAvatar(ClosedDetails) && (  
                            <div style={{display:'flex',marginTop:'12px'}}>
                            <Avatar  style={{ width: 41, height: 41}}  {...stringAvatar(ClosedDetails)} />
                            <Typography sx={{fontSize:'16px',marginLeft:'8px',marginTop:'6px'}}>{ClosedDetails}</Typography> 
                            </div>
                        )}
                        </div>
            </Grid>
        {/* //EOF SOP-71 Inlcuded By PCS */}


                                </Box>
                            </form>
                        </Box>
                    </Box>
                    {/* // SOP-57 - TS - end  Asset Management    - Ticketing - Activity screen */}
                    {/* Activity screen - end */}
            </Box>
        </Box>        
    </Box>
    )
}

export default ViewRaiseATicketPage;
// SOP-55  by TS - Asset Management-Ticketing-View and Edit Page - end