// SOP-97 Included by PCS [Ticketing Masters - Contact type]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
   
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import Swal from "sweetalert2";


// Page start
const AddTicketingContactType = 
// To get handleAddContactTypeClose & getcontacttypelistsearch from contact type page
    ({
        handleAddContactTypeClose,
        getcontacttypelistsearch
    }: {
        handleAddContactTypeClose: (event: React.MouseEvent) => void,
        getcontacttypelistsearch: (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

      // SOP-97 Inlcuded By PCS
      const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
      const GlobalData = JSON.parse(GlobalDataFromSession);
      //console.log("GlobalData",GlobalData)
      let CompanyId = GlobalData.GlobalCompanyId;
      const UserId = GlobalData.GlobalUserId;


    const [ContactTypeError, setContactTypeError]=useState(false)
    const [ContactTypeValue, setContactTypeValue]=useState('')
    const [ContactTypeErrorMessage, setContactTypeErrorMessage]=useState('')

    //[to avoid duplicate contact type name]
     const [ContactTypeValueEndTrim, setContactTypeValueEndTrim]=useState('')
    

    //  Handle Contact type while typing contact type and validations
     const handleContactTypeChange = (event:any) => {
         const trimmedContactTypeValue = event.target.value.trim();
         setContactTypeValue(event.target.value);

         //[to avoid duplicate contact type name]
         setContactTypeValueEndTrim(trimmedContactTypeValue);
        
        if (trimmedContactTypeValue === '') {
            setContactTypeError(true);
            setContactTypeErrorMessage("Contact type is required");
                                           } 
        else if( trimmedContactTypeValue.length >255 ){
            setContactTypeError(true);
            setContactTypeErrorMessage("Max 255 characters");
                                                      }
        else {
            setContactTypeError(false);
            setContactTypeErrorMessage("");
             }
                                                  };

    // Exist Contact Type name API
    const GetContactTypeNameExist = async () =>
   {   
        
        const  ExistContactTypeNamePayload = {
            ExistContactTypeName:ContactTypeValueEndTrim,
            ContactTypeId:null,
            CompanyId:CompanyId //SOP-97 Included By PCS
                                         }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_contactType_name_exists`,ExistContactTypeNamePayload);
       // console.log(res.data.data);
        return res.data.data || [];
           } 
        catch (err) {
        console.log( "co",err); 
                   }
    };


    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddContactTypeClose && typeof handleAddContactTypeClose === 'function') {
            handleAddContactTypeClose(event);
        }
    };

    // to render getcontacttypelistsearch function after closing dialouge box
    const handlegetcontacttypelistsearch= (event: React.MouseEvent) => {
        if (getcontacttypelistsearch && typeof getcontacttypelistsearch === 'function') {
            getcontacttypelistsearch(event);
        }
    };

   
    // Define a type for errors
        type Errors = {
            contacttype?:boolean;
        };

     // To add contact type
        const HandleAddContactType = async (event:any)=>
        {
            event.preventDefault();
            let errors: Errors = {};

                const ContactTypeNameList = await GetContactTypeNameExist();
                if (ContactTypeNameList.length > 0) {
                    setContactTypeError(true);
                    setContactTypeErrorMessage("Contact Type  already exists");
                    errors.contacttype = true;
                    return;
                                                    }
            
                const trimmedContactTypeValue = ContactTypeValue.trim();
                if (trimmedContactTypeValue === '') {
                    errors.contacttype = true;
                    setContactTypeErrorMessage("Contact Type is required");
                } 
                else if (trimmedContactTypeValue.length > 255) {
                    errors.contacttype = true;
                    setContactTypeErrorMessage("Max 255 characters");
                }
        
                if (Object.keys(errors).length > 0) {
                    // If there are errors, update the state with all the errors
                    setContactTypeError(errors.contacttype || false);
                    return;
                                                }
        
            const todayDate = new Date();
            const Year = todayDate.getFullYear();
            const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
            const Day = todayDate.getDate().toString().padStart(2, '0');
            const FormattedDate = `${Year}-${Month}-${Day}`;
        // console.log("FormattedDate",FormattedDate)
        
            const AddContactTypePayload = {
                SaveUpdateKey: "create",
                ContactTypeName: ContactTypeValue,
                ContactTypeId: null,
                //SOP-97 Included By PCS
                CompanyId:CompanyId,
                Active: "Y",
                CreatedDateTime: null,
                CreatedBy: UserId,
                IsRemove: null
                //EOF SOP-97 Included By PCS
                                        }
        
            //console.log("Add payload", AddContactTypePayload);

            Swal.fire({
                    html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    cancelButtonColor: '#d33',
                    confirmButtonColor:"#25425f",
                    allowOutsideClick: false,
                    })
                    .then((result) =>
                    {
                        if (result.isConfirmed)
                        {
                        commonService
                                .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_contactType_create_update`, AddContactTypePayload)
                                .then((res) => {
                                if (res.status === 200) {
                                    Success("Contact Type created successfully");
                                    handleClose(event);
                                    handlegetcontacttypelistsearch(event);
                                }
                                }).catch((error) => {
                                    console.error("Error:", error);
                                });
                        } 
                        else  
                        {
                        return
                        }
                    });

        
        }
   
    // UseEffect Declaration
    useEffect(() => {
         }, []);
     
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => 
        {
            if (event.key === 'Enter') {
                event.preventDefault();
                                        }
        };

    return(
        <>
         <Box>
            <Box display={'flex'} justifyContent={'center'}>
                <Box bgcolor={'white'} borderRadius={3}
                sx={{
                    width:{
                        lg:900,
                        md:600,
                        xs:700
    
                    }}}
                >
                <Box p={3}>
                    <Typography variant='h1'>
                    Add Ticketing Contact Type
                    </Typography>
                </Box>
                <Box p={3}>
                <form  noValidate>
                <Box>
                <TextField
                    onKeyDown={handleKeyDown} 
                    autoFocus
                    type='text'
                    variant="standard"
                    label='Contact Type'
                    name='ContactType'
                    style={{ margin: '0 10px' }}
                    fullWidth
                    required
                    error={ContactTypeError}
                    onChange={handleContactTypeChange}
                    value={ContactTypeValue}
                    helperText={ContactTypeErrorMessage}
                /> 
                </Box>
                <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Button
                    sx={{
                        background: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding:{
                            lg: "0 70px",
                            md: "0 70",
                            xs:'0 50px'

                        },
                        
                        height: "3rem",
                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                    }}
                    onClick={handleClose} 
                    >
                    Back
                </Button>
                <Button
                sx={{
                    background: '#25425F',
                    border: 'none',
                    borderRadius: '5px',
                    padding:{
                        lg: "0 70px",
                        md: "0 70",
                        xs:'0 50px'

                    },
                    "&:hover":{
                        backgroundColor:'#25425F',
                        color:'white',
                    },
                    color: 'white',
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                type='submit'
                onClick={(event)=>{
                    HandleAddContactType(event);
                }}
            >
             Save
            </Button>
            </Box>
            </form>
            </Box>
            </Box>
            </Box>
            </Box>
           
        </>
    )

}

export default AddTicketingContactType;

// EOF SOP-97 Included by PCS [Ticketing Masters -Contact Type]