//  SOP-76 feedback points L2 (7) Included BY PCS [View Hire request View Page]
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

// SOP-96 Commented By PCS [Existing View Page is modified ]
// const ViewHireRequestApprovalsPage=
// // To get handleApproveApprovalsPageClose & getMyPendingApprovalsList from catgories page
// ({
//     handleViewClose,
//     handleEditOpen,
//     RequestId
// }: {
//     handleViewClose: (event: React.MouseEvent) => void,
//     handleEditOpen: (event: React.MouseEvent) => void,
//     RequestId:any
// })=>{
    
//     // console.log('HireRequestId',RequestId)

//     const [HireRequestApproveRemarksError, setHireRequestApproveRemarksError]=useState(false)
//     const [HireRequestApproveRemarksValue, setHireRequestApproveRemarksValue]=useState('')
//     const [HireRequestApproveRemarksErrorMessage, setHireRequestApproveRemarksErrorMessage]=useState('')
//     const [ApproveOptions, setApproveOptions] = useState<OptionType[]>([]);
//     const [selectedApproveId, setSelectedApproveId] = useState<any | null>('');
//     const [HireRequestApproveError, setHireRequestApproveError]=useState(false)
//     const [HireRequestApproveErrorMessage, setHireRequestApproveErrorMessage]=useState('')
//     const [HireRequestFilledPositionCount, setHireRequestFilledPositionCount]= useState(0)

//     // console.log('ApproveOptions',ApproveOptions)
//     // console.log('HireRequestFilledPositionCount',HireRequestFilledPositionCount)


//     type OptionType = {
//         name: string;
//         value: string;
//     };
//     const filterOptions = createFilterOptions<OptionType>({
//         matchFrom: 'start',
//         stringify: (option) => option.name,
//     });

//         //  Global data from session storage
//         const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
//         const GlobalData = JSON.parse(GlobalDataFromSession);
//         //console.log("GlobalData",GlobalData)
//         let CompanyId = GlobalData.GlobalCompanyId
//         const UserId = GlobalData.GlobalUserId;

//     //  Handle Approve while typing Approve and validations
//         const handleApproveRemarksChange = (event:any) => {
//             setHireRequestApproveRemarksValue(event.target.value);
//             const trimmedHireRequestApproveRemarksValue =  event.target.value
//             if( trimmedHireRequestApproveRemarksValue.length > 255 ){
//                 setHireRequestApproveRemarksError(false);
//                 setHireRequestApproveRemarksErrorMessage("");
//             }
//         };

//     // To render handle close function to close dialouge box
//         const handleClose = (event: React.MouseEvent) => {
//             if (handleViewClose && typeof handleViewClose === 'function') {
//                 handleViewClose(event);
//             }
//         };

//     // to render handleEditOpen function after closing dialouge box
//     const EditApproveApprovalOpen= (event: React.MouseEvent) => {
//         //console.log("ApprovalAllocatationSts",ApprovalAllocatationSts)
//         // To stop editing the request if Hire Request has already been started.
//         if(handleEditOpen && typeof handleEditOpen === 'function' && HireRequestFilledPositionCount > 0){
//             Swal.fire({
//                 html: '<span style="font-size: 1.3rem;">Hire Request  already started, You cannot edit</span>',
//                 confirmButtonText: 'OK',
//                 });
//         }
//         else if(handleEditOpen && typeof handleEditOpen === 'function' && HireRequestFilledPositionCount === 0){
//             handleEditOpen(RequestId)
//         }
//     };

// //Drop down to fill Approve Status..
//     const GetHireRequestApproveList = async () => { 
//         try {
//             const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_approve_status_list`);
//             //console.log("Approve" ,res.data.data);
//             let formattedApprove = res.data.data.map((item:any) => {
//             const { sys_approve_status_id, sys_approve_status_name } = item;
//             return {
//                 name: sys_approve_status_name,
//                 value: sys_approve_status_id,
//             };
//             });
//             setApproveOptions(formattedApprove)
//         } catch (err) {
//             console.log( "co",err);
//         }
//         };

//     // To get Approved Hire request details as per the id .
//       const GetHireRequestApprovedApprovalListById =()=>
//         {
//           commonService
//             .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_hire_request_approved_list_by_id`, {
//             CompanyId: CompanyId,
//             RequestId: RequestId,
//             })
//             .then((res: any) => 
//             { 
               
//             const ApprovedAssetData = res.data.data[0]
//             setHireRequestApproveRemarksValue(ApprovedAssetData.request_approve_remarks)
//             setHireRequestFilledPositionCount(ApprovedAssetData.hirerequest_total_filled_position)
//             let formattedApproveStatus = res.data.data.map((item:any) => {
//                 const { sys_approve_status_id, sys_approve_status_name } = item;
//                 return {
//                     name: sys_approve_status_name,
//                     value: sys_approve_status_id,
//                 };
//                 });
//                 setSelectedApproveId(formattedApproveStatus[0].value)            
//             }).catch((error) =>  
//             { 
//             Failed(error.response.message);
//             });
//         }
    
//     useEffect(() => {
//         GetHireRequestApproveList();
//         GetHireRequestApprovedApprovalListById();
//         }, []); 


//     return(
//         <Box>
//             <Box bgcolor={'white'} borderRadius={3}
//             sx={{
//                 width:{
//                     lg:600,
//                     md:600,
//                     xs:350
//             }}} >
//                 <Box>
//                 <Box p={3}>
//                         <Typography variant='h1'
//                         sx={{
//                             fontSize:{
//                                 lg:'2rem',
//                                 md:'2rem',
//                                 xs:'1.5rem'
//                             }
//                         }}
//                         >
//                         View Hire Request 
//                         </Typography>
//                     </Box>
//                         <Box p={3}>
//                             <Grid container >
//                                 <Grid item lg={12} md={12} xs={12} >
//                                     <Autocomplete
//                                         id="combo-box-demo"
//                                         disabled
//                                         options={ApproveOptions as OptionType[]}
//                                         filterOptions={filterOptions}
//                                         getOptionLabel={(option: unknown) => (option as OptionType).name}
//                                         value={ApproveOptions.find((option: OptionType) => option.value === selectedApproveId) || null}
//                                         onChange={(event, newValue) => {
//                                             if (newValue) {
//                                                 setSelectedApproveId(newValue.value);
//                                                 setHireRequestApproveError(false);
//                                                 setHireRequestApproveErrorMessage("");
//                                             } else {
//                                                 setSelectedApproveId('');
//                                                 setHireRequestApproveError(true);
//                                                 setHireRequestApproveErrorMessage("Approve status is required");
//                                             }
//                                         }}
                                        
//                                         renderInput={(params) => 
//                                         <TextField {...params}
//                                         error={HireRequestApproveError}
//                                         autoFocus
//                                         disabled
//                                         helperText={HireRequestApproveErrorMessage}
//                                         required label="Approve Status" 
//                                         variant="standard" />}
//                                     />
//                                 </Grid>

//                                 <Grid item mt={3} lg={12} md={12} xs={12} >
//                                             <TextField 
//                                             id="outlined-basic" 
//                                             label="Approve Remarks" 
//                                             variant="outlined" 
//                                             name='ApproveRemarks'
//                                             fullWidth
//                                             disabled
//                                             multiline
//                                             rows={4}
//                                             error={HireRequestApproveRemarksError}
//                                             onChange={handleApproveRemarksChange}
//                                             value={HireRequestApproveRemarksValue}
//                                             helperText={HireRequestApproveRemarksErrorMessage}                                           
//                                             />
//                                         </Grid>
//                             </Grid>
//                         </Box>

//                     <Box mt={5} ml={2} mr={2} mb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
//                                 <Button
//                                     sx={{
//                                         background: 'white',
//                                         border: 'none',
//                                         borderRadius: '5px',
//                                         padding:{
//                                             lg: "0 70px",
//                                             md: "0 70",
//                                             xs:'0 50px'
                
//                                         },
//                                         // color: {accent},
//                                         height: "3rem",
//                                         boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                                     }}

//                                     onClick={handleClose} 
//                                 >
//                                     Back
//                                 </Button>

//                                 <Button
//                                     sx={{
//                                         background: '#25425F',
//                                         border: 'none',
//                                         borderRadius: '5px',
//                                         padding:{
//                                             lg: "0 70px",
//                                             md: "0 70",
//                                             xs:'0 50px'
                
//                                         },
//                                         "&:hover":{
//                                             backgroundColor:'#25425F',
//                                             color:'white',
//                                         },
//                                         color: 'white',
//                                         height: "3rem",
//                                         boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
//                                     }}
//                                     onClick={(event)=>{
//                                         EditApproveApprovalOpen(event);
//                                         handleClose(event);
//                                     }}
//                                 >
//                                     Edit
//                                 </Button>
//                                 </Box>
//                 </Box>
//             </Box>
        
//         </Box>
//     )
// }
// export default ViewHireRequestApprovalsPage;

//EOF SOP-76 feedback points L2 (7) Included BY PCS [View Hire request View Page]
//EOF  SOP-96 Commented By PCS [Existing View Page is modified ]


// SOP-96 Modified By PCS [Existing View Page is modified ]

const ViewHireRequestApprovalsPage=
// To get handleApproveApprovalsPageClose & getMyPendingApprovalsList from catgories page
({
    handleViewClose,
     HireRequestId
}: {
    handleViewClose: (event: React.MouseEvent) => void,
  
    HireRequestId:any
})=>{

    //Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    let EmployeeId = GlobalData.GlobalEmployeeId
    let GlobalUserName = GlobalData.GlobalUserName   //SOP-96 Included By PCS

  
    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleViewClose && typeof handleViewClose === 'function') {
            handleViewClose(event);
            }
    };
 

//SOP-96 Included By PCS [Multi Level Approval]

   const navigate = useNavigate();

   // Head cells of HireRequestApprovals
   const HireRequestApprovalsHeadCell = [
    {
        id: "level_id",
        numeric: false,
        disablePadding: true,
        label: "Level",
        isSort: true,
        isBold: true,
    },        
    {
        id: "employee_name",
        numeric: false,
        disablePadding: false,
        label: "Approving Authority",
        isSort: true,
        isBold: true,
   },
   {
        id: "departmentname",
        numeric: false,
        disablePadding: true,
        label: "Department",
        isSort: true,
        isBold: true,
    },
    {
        id: "approval_status",
        numeric: false,
        disablePadding: false,
        label: "Approval status",
        isSort: true,
        isBold: true,
    },
   {
        id: "approval_remarks",
        numeric: false,
        disablePadding: false,
        label: "Remarks",
        isSort: true,
        isBold: true,
        
    },
    {
        id: "approveddate",
        numeric: false,
        disablePadding: false,
        label: "Approve/ Reject Date",
        isSort: true,
        isBold: true,
        
    },];

    // const [cells, setCells] = useState<any>(HireRequestApprovalsHeadCell);
    let  [HireRequestsApprovalsorder, setHireRequestsApprovalsOrder] = React.useState<"desc" | "asc">("desc");
    let  [HireRequestsApprovalsorderBy, setHireRequestsApprovalsOrderBy] = React.useState("");
    let  [HireRequestsApprovalstableData, setHireRequestsApprovalstableData] = React.useState([]);
    const [HireRequestsApprovalspage, setHireRequestsApprovalspage] = React.useState<number>(0);
    const [HireRequestsApprovalLevelCount, setHireRequestsApprovalLevelCount] = useState<number>(0);
    const [HireRequestsApprovalsrowsPerPage, setHireRequestsApprovalsrowsPerPage] = React.useState<number>(10);
    // const [SaveButtondisable, setSaveButtondisable] = useState(true);

//To display in header
    const [JobProfile, setJobProfile] = useState('');
    const [Department, setDepartment] = useState('');
    const [RequestedBy, setRequestedBy] = useState('');
    const [HiringManager, setHiringManager] = useState('');
    const [TypeOfEmployment, setTypeOfEmployment] = useState('');

//To get details on page load  
    // const [EmployeeLevelId, setEmployeeLevelId] = useState<number>(0);
    // const [MaxLevel, setMaxLevel] = useState<number>(0);
    // const [EmployeeStatus, setEmployeeStatus] = useState(''); 
   
 
    type OptionType = {
        name: string;
        value: string;
    };

    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

//To get Request details in header
    const getHireRequestDetailsByIdforHeader =() => 
    {       
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_hire_request_approved_list_by_id`, {
            CompanyId:CompanyId,
            RequestId:HireRequestId
                            })
        .then((res: any) => 
        {
        const HireRequestDetails =res.data.data[0]
        // console.log("Hire Request Heading details ..",HireRequestDetails )
            setJobProfile(HireRequestDetails.hirerequest_hiring_jobcode_title)
            setDepartment(HireRequestDetails.departmentname)
            setRequestedBy(HireRequestDetails.fullname)
            setHiringManager(HireRequestDetails.hiringmanager)
            setTypeOfEmployment(HireRequestDetails.sys_hr_employment_type_name)
        })
    };
  
//To get Request details  in Grid with all levels..
    const GetHireRequestApprovalHierarchy = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_hirerequest_id_all_approval_levels`, {
                HireRequestId:HireRequestId,
                CompanyId:CompanyId,
                SortColumnName: HireRequestsApprovalsorderBy,
                SortColumnDirection: HireRequestsApprovalsorder,
                StartIndex: HireRequestsApprovalspage + 1,
                PageSize: HireRequestsApprovalsrowsPerPage,
            })
            .then((res: any) => 
            {
             const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
            id:item.hirerequest_id,
            approveddate: item.approveddate? dayjs(item.approveddate).format("DD-MM-YYYY HH:mm:ss") : "",
                
            }
            }) 
            console.log("HireRequests Multi Level Approval Grid ..",res.data.data)

    //         //SOP-96 [to get  details of the request]
    //         const hireRequests = res.data.data; 
    //         const maxLevel = Math.max(...hireRequests.map((item: any) => item.level_id));
    //         // setMaxLevel(maxLevel)
    //         if (hireRequests) 
    //         {
    //             const employeeRequest = hireRequests.find((request: any) => request.employee_id === EmployeeId);
    //             if (employeeRequest) 
    //             {
    //                 // To get current level ID and status
    //                 const EmployeeLevel = employeeRequest.level_id;
    //                 const EmployeeStatus = employeeRequest.approval_status;
    //                 // setEmployeeLevelId(EmployeeLevel);
    //                 // setEmployeeStatus(EmployeeStatus)

    //                 // If EmployeeLevel is 1, check EmployeeStatus,
    //                 if (EmployeeLevel === 1) 
    //                 {
    //                         // if Approved or Rejected disable the button
    //                         if (EmployeeStatus === 'Approved' || EmployeeStatus === 'Rejected') {
    //                         setSaveButtondisable(true); 
    //                         }
    //                         // Pending enable  the button 
    //                         else if (EmployeeStatus === 'Pending') 
    //                         {
    //                         setSaveButtondisable(false);  // Enable the button if status is Pending
    //                         }
    //                 } 
    //                 //Employee level other than 1
    //                 else{
    //                         // check EmployeeStatus and PrevLevelStatus
    //                         if (EmployeeStatus === 'Pending')
    //                         {
    //                             // Find the previous level request
    //                             const previousLevelRequest = hireRequests.find((request: any) => request.level_id === EmployeeLevel - 1);
    //                             if (previousLevelRequest)
    //                             {
    //                                 const PrevLevelStatus = previousLevelRequest.approval_status;
    //                         // If PrevLevelStatus is 'Pending, rejected or Not Requested' disable the button
    //                                 if (PrevLevelStatus === 'Pending' || PrevLevelStatus === 'Rejected' || PrevLevelStatus === 'Not Requested') {
    //                                     setSaveButtondisable(true); 
    //                                 }
    //                             // If PrevLevelStatus is 'Approved' enable the button
    //                                 else if (PrevLevelStatus === 'Approved') {
    //                                     setSaveButtondisable(false);  // Enable button if previous level status is Approved
    //                                 }
    //                             }
    //                             else 
    //                             {
    //                                 console.log(`No request found for previous level (level ${EmployeeLevel - 1})`);
    //                             }
    //                         }
    //                     }
    //             }            
                
    //         }
    // //EOF SOP-96 [New]
            setHireRequestsApprovalstableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
            setHireRequestsApprovalLevelCount(0);
            }
            else
            {
            setHireRequestsApprovalLevelCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setHireRequestsApprovalstableData([]);
            }
            Failed(error.response.message);
            });
        };
   
// Pagination Handler of table
    const HireRequestsApprovalshandleChangePage = (event: unknown, newPage: number) => {
            setHireRequestsApprovalspage(newPage);
        };
        
// Rows Per page Handler
    const HireRequestsApprovalshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setHireRequestsApprovalsrowsPerPage(+event.target.value);
                setHireRequestsApprovalspage(0);
        };

// Sort Handler
    const handleHireRequestsApprovalsSort = (event: any, property: any) =>
            {
            const isAsc = HireRequestsApprovalsorderBy === property && HireRequestsApprovalsorder === "asc";
            HireRequestsApprovalsorder = isAsc ? "desc" : "asc";
            setHireRequestsApprovalsOrder(HireRequestsApprovalsorder);
            HireRequestsApprovalsorderBy = property;
            setHireRequestsApprovalsOrderBy(HireRequestsApprovalsorderBy);
            };
              
// Action function of table icons
    const HireRequestsApprovalsactionFunction = (id: string, actionText: string) => 
        {
           console.log('id is..',id)
        };


// Define a type for errors
        type Errors = {
        approveSts?:boolean;
        apprveRemarks?:boolean;
        };



//Use Effect
    useEffect(() => {
      
        GetHireRequestApprovalHierarchy();//SOP-96 Includedd By PCS
        getHireRequestDetailsByIdforHeader();//SOP-96 Included By PCS
    }, [HireRequestsApprovalsrowsPerPage,HireRequestsApprovalspage,HireRequestsApprovalsorderBy,HireRequestsApprovalsorder]); 


//SOP-96 Modified By PCS [Existing design changed- for multi level approval]
return(<>
<Box>
{/* //Header Section starts*/}
<Box  bgcolor={'white'}sx={{width:'95%',marginTop:'4%',marginLeft:'4%'}}>

{/* //Header Section */}
<div style={{display: 'flex', flexDirection: 'row'}}>
<Grid mb={2} 
    container
    spacing={{ xs: 2, md: 2 }}
    columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
    rowSpacing={{ xs: 2, md: 1 }}
    columnSpacing={{ xs: 2, md: 3 }}
>

<Grid item xs={8} sm={8} md={8} lg={1.5}>
<div style={{display: 'flex', flexDirection: 'column'}}>

  <Typography sx={{marginLeft:'2%',fontWeight: 'bold',fontSize: {lg: '1.1rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'#002984'}}>
    Request Id
  </Typography>
  <Typography sx={{ marginLeft:'2%',fontSize: {lg: '1.0rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'black'}}>
    {HireRequestId}
  </Typography>
</div>
  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={2}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word',  fontWeight: 'bold',fontSize: {lg: '1.1rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'#002984'}}>
      Job Profile
    </Typography>
    <Typography sx={{fontSize: {lg: '1.0rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'black',
        wordWrap: 'break-word',
        overflowWrap: 'break-word'}}>
      {JobProfile}
    </Typography>
  </div>

  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={1.5}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word',fontWeight: 'bold',fontSize: {lg: '1.1rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'#002984'}}>
      Department
    </Typography>
    <Typography sx={{  wordWrap: 'break-word',
        overflowWrap: 'break-word', fontSize: {lg: '1.0rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'black'}}>
      {Department}
    </Typography>
  </div>
  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={2}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{  wordWrap: 'break-word',
        overflowWrap: 'break-word',fontWeight: 'bold', fontSize: {lg: '1.1rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'#002984'}}>
      Requested By
    </Typography>
    <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word', fontSize: {lg: '1.0rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'black'}}>
      {RequestedBy}
    </Typography>
  </div>
  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={2}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
  <Typography sx={{  wordWrap: 'break-word',
        overflowWrap: 'break-word',fontWeight: 'bold',fontSize: {lg: '1.1rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'#002984'}}>
    Hiring Manager
  </Typography>
  <Typography sx={{  wordWrap: 'break-word',
        overflowWrap: 'break-word',fontSize: {lg: '1.0rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'black'}}>
    {HiringManager}
  </Typography>
  </div>
  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={2.5}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
  <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word',fontWeight: 'bold', fontSize: {lg: '1.1rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'#002984'}}>
    Type Of Employment
  </Typography>
  <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word',fontSize: {lg: '1.0rem', md: '0.75rem', xs: '0.5rem'}, textAlign: 'left', color:'black'}}>
    {TypeOfEmployment}
  </Typography>
</div>
  </Grid>
</Grid>
</div>
</Box>
{/* //Header Section Ends*/}

{/* //Grid Section starts*/}
<Box sx={{marginTop:'4%',marginLeft:'3%'}}>
        <EnhancedTable
            headCells={HireRequestApprovalsHeadCell}
            rows={HireRequestsApprovalstableData}
            rowsPerPage={HireRequestsApprovalsrowsPerPage}
            page={HireRequestsApprovalspage}
            handleChangePage={HireRequestsApprovalshandleChangePage}
            handleChangeRowsPerPage={HireRequestsApprovalshandleChangeRowsPerPage}
            handleRequestSort={handleHireRequestsApprovalsSort}
            order={HireRequestsApprovalsorder}
            orderBy={HireRequestsApprovalsorderBy}
            actionFunction={HireRequestsApprovalsactionFunction}
            isView={false}
            isDelete={false}
            isEdit={false}
            total={HireRequestsApprovalLevelCount}
            isSearch={false}
            HoverColor={'#E7F1FB'}
            />
</Box>
{/* //Grid Section ends*/}


{/* Back button Section */}    
<Box>
    <Button
    sx={{
    marginBottom:'20px',
    width:'15%',
    marginLeft:'4%',
        background: 'white',
        border: 'none',
        borderRadius: '5px',
        padding:{
            lg: "0 70px",
            md: "0 70",
            xs:'0 50px'},
        height: "3rem",
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    }}
    onClick={handleClose} 
>
    Back
    </Button>
</Box>
{/* Back button Section ends*/}  
</Box>
</> )

   
}
    

export default ViewHireRequestApprovalsPage;
// EOF SOP-96 Modified By PCS [Existing View Page is modified ]