// SOP-97 Included by PCS [Ticketing Masters -Priority]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
   
} from '@mui/material';
import Typography from '@mui/material/Typography';

import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import './EditTicketingPriority.scss'
import Swal from "sweetalert2";

// Page start
const EditTicketingPriority = 
// To get handleEditPriorityClose & getprioritylistsearch from priority page
({
    handleEditPriorityClose,
    getprioritylistsearch,
    PriorityId
}: {
    handleEditPriorityClose: (event: React.MouseEvent) => void,
    getprioritylistsearch: (event: React.MouseEvent) => void,
    PriorityId: any;
}) =>{

    //  use state declaration
    const [PriorityError, setPriorityError]=useState(false)
    const [PriorityValue, setPriorityValue]=useState('')
    const [PriorityErrorMessage, setPriorityErrorMessage]=useState('')

    const [isActive, setIsActive] = useState(true); //SOP-97 Included By PCS
    

    //[ to avoid duplicate Priority type]
    const [PriorityValueEndTrim, setPriorityValueEndTrim]=useState('')
   
    //SOP-97 Inlcuded By PCS
      const handleToggleActive = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };
    //EOF SOP-97 Included By PCS
 
    //  Handle Priority Type while typing Priority and validations
    const handlePriorityChange = (event:any) => {
        const trimmedPriorityValue = event.target.value.trim();
        setPriorityValue(event.target.value);

         //[ to avoid duplicate Priority ]
         setPriorityValueEndTrim(trimmedPriorityValue);
        

        if (trimmedPriorityValue === '') {
            setPriorityError(true);
            setPriorityErrorMessage("Priority is required");
        } 
        else if( trimmedPriorityValue.length >255 ){
            setPriorityError(true);
            setPriorityErrorMessage("Max 255 characters");
        }
        else {
            setPriorityError(false);
            setPriorityErrorMessage("");
        }
    };


    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditPriorityClose && typeof handleEditPriorityClose === 'function') {
            handleEditPriorityClose(event);
        }
    };


    // to render handlegetprioritylistsearch function after closing dialouge box
    const handlegetprioritylistsearch= (event: React.MouseEvent) => {
        if (getprioritylistsearch && typeof getprioritylistsearch === 'function') {
            getprioritylistsearch(event);
        }
    };

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId; //SOP-97 Included by PCS
    const UserId = GlobalData.GlobalUserId;//SOP-97 Included by PCS

    // To get Priority  details as per the id
    const GetPriorityListById =()=>{
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_priority_list_by_id`, {
            PriorityId:PriorityId,
            CompanyId:CompanyId//SOP-97 Inlcuded By PCS
           
         })
         .then((res: any) => 
         { 
            //console.log("res",res)
            const PriorityData = res.data.data[0]
            setPriorityValue(PriorityData.sys_priority_name)
            //SOP-97 Inlcuded By PCS
              if(PriorityData.active === 'Y'){
                setIsActive(true)
            }
            else{
                setIsActive(false)
            }
             //EOF SOP-97 Inlcuded By PCS
           
         }).catch((error) =>  
         { 
          Failed(error.response.message);
         });
    }

    // Exist Priority name API
    const GetPriorityNameExist = async () => {   
        const  ExistPriorityNamePayload = 
            {
               ExistPriorityName:PriorityValueEndTrim,
               PriorityId:PriorityId,
               CompanyId:CompanyId //SOP-97 Included By PCS
            }
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_priority_name_exists`,ExistPriorityNamePayload);
            // console.log(res.data.data);
            return res.data.data || [];
            } 
            catch (err) {
            console.log( "co",err); 
            }
        };


    // To Update Priority 

     // Define a type for errors
     type Errors = {
        priority?:boolean;
                  };
    const HandleUpdatePriority = async (event:any)=>
    {
        event.preventDefault();
    
        let errors: Errors = {};

        const PriorityNameList = await GetPriorityNameExist();
        if (PriorityNameList.length > 0) {
            setPriorityError(true);
            setPriorityErrorMessage("Priority already exists");
            errors.priority = true;
            return;
        }
    
        const trimmedPriorityValue = PriorityValue.trim();
        if (trimmedPriorityValue === '') {
            errors.priority = true;
            setPriorityErrorMessage("Priority is required");
        } else if (trimmedPriorityValue.length > 255) {
            errors.priority = true;
            setPriorityErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setPriorityError(errors.priority || false);
            return;
        }

        //SOP-97 Inlcuded By PCS
        let EditActive ;
    
        if( isActive === true){
           EditActive = "Y"
        }
        else{
            EditActive='N'
        }
        //EOF SOP-97 Inlcuded By PCS

    
        const UpdatePriorityPayload =
        {
        SaveUpdateKey: "update",
        PriorityName: PriorityValue,
        PriorityId: PriorityId,
        //SOP-97 Included by PCS
        CompanyId:CompanyId,
        Active: EditActive,
        LastModifiedBy: UserId,
        IsRemove: null
        //EOF SOP-97 Included by PCS
        }
    
        // console.log("Update Payload", UpdatePriorityPayload);
        Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
            if (result.isConfirmed)
            {
                commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_priority_create_update`, UpdatePriorityPayload)
                .then((res) => {
                if (res.status === 200) {
                    Success("Priority Updated successfully");
                    handleClose(event);
                    handlegetprioritylistsearch(event);
                }
                }).catch((error) => {
                    console.error("Error:", error);
                });
            } 
            else  
            {
                return
            }
      });

    }
    

    // UseEffect declaration
    useEffect(() => {
        GetPriorityListById();
      }, []); 
 
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        };

// Design start
    return(
        <Box>
            <Box>
            <Box display={'flex'} justifyContent={'center'}>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:900,
                    md:600,
                    xs:700

                }}}
            >
            <Box p={3}>
                <Typography variant='h1'>
                Edit Ticketing Priority
                </Typography>
            </Box>
            <Box p={3}>
            <form  noValidate>
            <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
            <Box>
            <TextField
                onKeyDown={handleKeyDown}
                autoFocus
                type='text'
                variant="standard"
                label='Priority'
                name='Priority '
                style={{ margin: '0 10px' }}
                fullWidth
                required
                error={PriorityError}
                onChange={handlePriorityChange}
                value={PriorityValue}
                helperText={PriorityErrorMessage}
                /> 
            </Box>
            </Grid>
            </Grid>
            {/*//SOP-97 Included By PCS  */}
                <Box mt={2} ml={1}>
                <Typography className="label_design" sx={{mt:1.5}}>
                <label style={{fontSize:'0.7rem'}}>Active State</label>
                </Typography>
                <label className="switchToggle">
                <input
                    type="checkbox"
                    checked={isActive}
                    onChange={handleToggleActive} 
                />
                <span className="sliderToggle round"></span>
                </label>
                </Box> 
            {/* EOF SOP-97 Included By PCS  */}   
            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Button
                sx={{
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding:{
                        lg: "0 70px",
                        md: "0 70",
                        xs:'0 50px'

                    },
                    
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                  onClick={handleClose} 
                >
                Back
                </Button>
                <Button
                sx={{
                    background: '#25425F',
                    border: 'none',
                    borderRadius: '5px',
                    padding:{
                        lg: "0 70px",
                        md: "0 70",
                        xs:'0 50px'
                    },
                    "&:hover":{
                        backgroundColor:'#25425F',
                        color:'white',
                    },
                    color: 'white',
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                type='submit'
                onClick={(event)=>{
                    HandleUpdatePriority(event);
                }}
            >
            Update
            </Button>
            </Box>
            </form>
            </Box>

            </Box>
            </Box>
            </Box>
        </Box>
    )

}


export default EditTicketingPriority;
// EOF SOP-97 Included by PCS [Ticketing Masters -Priority]