// SOP-97 Included By PCS [Ticketing Masters-Sub categories]

// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import AddCategoriesPage from './AddTicketingSubCategories';
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import { parse } from 'path';
import EditTicketingSubCategories from './EditTicketingSubCategories';
import AddTicketingSubCategories from './AddTicketingSubCategories';
import { Category } from '@mui/icons-material';
import deleteAlert from '../../component/HOC/deleteAlert';//SOP-97 Inlcuded by PCS


const TicketingSubCategories =()=>{

      // Use navigate
      const navigate = useNavigate();

        
    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId;
    const UserId = GlobalData.GlobalUserId;

       // useState
       let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
       let  [orderBy, setOrderBy] = React.useState("");
       let  [tableData, setTableData] = React.useState([]);
       const [page, setPage] = React.useState<number>(0);
       const [count, setCount] = useState<number>(0);
       const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
       const [AddSubCategoriesOpen, setAddSubCategoriesOpen] = React.useState(false);
       const [EditSubCategoriesOpen, setEditSubCategoriesOpen] = React.useState(false);
       const [EditSubCategoryId, setEditSubCategoryId]= useState('')


      //  To open add dialouge box
       const handleAddSubCategoriesOpen = () => {
        setAddSubCategoriesOpen(true);
        };
    
        // To close add dialouge box
        const handleAddSubCategoriesClose = () => {
        setAddSubCategoriesOpen(false);
        };

        // To open edit dialouge box
        const handleEditSubCategoriesOpen = (id:any) => {
            setEditSubCategoriesOpen(true);
            setEditSubCategoryId(id)
         };
    
        // To close edit dialouge box
        const handleEditCategoriesClose = () => {
        setEditSubCategoriesOpen(false);
        };

        // Head cell of categories
     const CategoriesHeadCell = [

        {
          id: "sys_issue_type_name",
          numeric: false,
          disablePadding: false,
          label: "Categories",
          isSort: true,
          isBold: true,
        },        
        {
         id: "sys_issue_subtype_name",
         numeric: false,
         disablePadding: false,
         label: "Sub-Categories",
         isSort: true,
         isBold: true,
       },
        //SOP-97 Included By PCS
        {
          id: "active",
          numeric: false,
          disablePadding: false,
          label: "Active",
          isSort: true,
          isBold: true,
         
        },
        //EOF SOP-97 Included By PCS
    
      {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        isSort: false,
      },
       ];

      const [cells, setCells] = useState<any>(CategoriesHeadCell);
    
      //  To get categories list search API  
       const getSubcategorieslistsearch = () => 
       {
     
      // Initializing Variable 
         
         let CategoryName = null;
         let SubCategoryName = null;
         let bActiveState = ""; //SOP-97 Included by PCS

       for (const item of cells)
       {
          if (item.id === "sys_issue_type_name") 
          {
            CategoryName = item.value;
          }

         if (item.id === "sys_issue_subtype_name")
          {
             SubCategoryName = item.value;
         }

        //SOP-97 Included by PCS
        if (item.id === "active") 
            {
              bActiveState = item.value;
            }
        //EOF SOP-97 Included by PCS
         
       }
       
         commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_subcategories_list_search`, {
            SearchIssueType:CategoryName,
            SearchIssueSubType:SubCategoryName,
            SearchActive:  bActiveState,//SOP-97 Included by PCS
            SortColumnName: orderBy,
            SortColumnDirection: order,
            StartIndex: page + 1,
            PageSize: rowsPerPage,
            CompanyId:CompanyId//SOP-97 Inlcuded by PCS


         })
         .then((res: any) => 
         {
            // console.log("res",res)
         const formattedTableData= res.data.data.map((item:any)=>{
         return {...item,
             id:item.sys_issue_subtype_id,
            categoryid:item.sys_issue_type_id
        }
           }) 
           
         setTableData(formattedTableData);
         if(res.data.data[0]===undefined)
           {
             setCount(0);
           }
           else
           {
             setCount(res.data.data[0].total_count);
           }
         })
       .catch((error) => 
         {
           if (error.response.status === 404)
           {
             setTableData([]);
           }
          Failed(error.response.message);
         });
       };

       
    // Sort Handler
    const handleRequestSort = (event: any, property: any) =>
    {
    const isAsc = orderBy === property && order === "asc";
    order = isAsc ? "desc" : "asc";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
    };


      //SOP-97 Included by PCS Handle Delete
      const handleDeleteFleet = (id: string) => {
        deleteAlert({
          title: "Delete",
          message: "Are you sure?",
          confirmButtonText: "Yes",
          onConfirmed: DeleteCategory,
          data: id,
        });
    };

    const handleChangeList = () => {
      if (page === 0) getSubcategorieslistsearch();
      else setPage(0);
    };

     const DeleteCategory = (id: string) =>{
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_subcategories_create_update`, 
        {
          SaveUpdateKey: null,
          IssueSubTypeName: null,
          IssueSubTypeId: id,
          IssueTypeId:null,
          CompanyId:CompanyId,
          Active: null,
          CreatedDateTime: null,
          CreatedBy: UserId,
          IsRemove: 'Y'
        })
        .then((res: any) => {
          // console.log('res is',res)
          if (res.status === 200) {
           
              Success("Sub Category Deleted Successfully");
              handleChangeList();
              getSubcategorieslistsearch();
          }
        })
        .catch((err) => {
          Failed("Reference information is found in the transaction table");
         
        });
     }
   //EOF SOP-97 Included By PCS

    // Action function of table icons
    const actionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "edit"){
            handleEditSubCategoriesOpen(id);
        };

         if (actionText === "delete") handleDeleteFleet(id)//SOP-97 Included By PCS
       
    };
  

    // Search Handler of table
    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const search = [...cells];
        const index = cells.findIndex((c: any) => c.id === e.target.name);
        
        if (index !== -1) {
            search[index].value = e.target.value;
        }
       
        setCells(search);
        handleChangePage(setPage,0);
        getSubcategorieslistsearch();
    };


    // Pagination Handler of table
    const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    };

    // Rows Per page Handler
    const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    };


    useEffect(() => {
        getSubcategorieslistsearch();
      }, [rowsPerPage, page, orderBy, order]); 
     

// Design start
    return(
        <>
        <MainContainerWrapper 
        headingText="Ticketing Sub-Categories"
        handleClick={() =>{
            handleAddSubCategoriesOpen()
        }}
        
        buttonText="Add Ticketing Sub-Category"
        isSearch={false}
        isCreateButton={true}
        buttonIcon={<span>&#43;</span>}
        >
            <Box>
            <Box bgcolor={'white'}>
            <Box>
            <EnhancedTable
                headCells={CategoriesHeadCell}
                rows={tableData}
                rowsPerPage={rowsPerPage}
                page={page}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                actionFunction={actionFunction}
                isEdit={true}
                isDelete={true}//SOP-97 Included By PCS
                total={count}
                isSearch={true}
                />
            </Box>

        </Box>
          {/* Add Categories dialog box */}
            <Box>
                <Dialog  open={AddSubCategoriesOpen}  >
                    <AddTicketingSubCategories handleAddSubCategoriesClose={handleAddSubCategoriesClose}
                    getSubcategorieslistsearch={getSubcategorieslistsearch} />
                </Dialog>
            </Box>
            {/* End of Add categories */}

            {/* Edit Categories dialog box */}
            <Box>
                <Dialog  open={EditSubCategoriesOpen}  >
                    <EditTicketingSubCategories handleEditCategoriesClose={handleEditCategoriesClose}
                    getSubcategorieslistsearch={getSubcategorieslistsearch} SubCategoryId={EditSubCategoryId}  />
                </Dialog>
            </Box>
            {/* End of Edit categories */}
          </Box>
          <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
          <Button
              sx={{
                  background: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding:{
                      lg: "0 70px",
                      md: "0 70",
                      xs:'0 50px'

                  },
                  
                  height: "3rem",
                  boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
              }}

              onClick={()=>{
                navigate('/backoffice')
              }} 
          >
              Back
          </Button>

          </Box>
        </MainContainerWrapper>
        </>
    )
// Design end
}

export default TicketingSubCategories;

 
// EOF SOP-97 Included By PCS [Ticketing Masters-Sub categories]

