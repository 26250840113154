import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import requestAsset from '../../asset/images/requestAsset1.png';
import raiseTicket from '../../asset/images/raiseTicket.png'
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed } from '../../atic-common-helpers/helpers/toast.helper';

// (SOP-64) Asset Management - Home Page - New hire Request - TS 
import HR from '../../asset/images/HR employee.jpg'


// Included by sanjana SOP-26 imports

//SOP-49 - TS - commented - start
// Asset Management-Ticketing-Create Page
// import RaiseATicketPage from '../Ticketing/RaiseATicket';
//SOP-49 - TS - commented - end

import dayjs from 'dayjs';

  // SOP-55 - TS commented
// import EditRaiseATicketPage from '../Ticketing/EditRaiseATicket';
// import ViewRaiseATicketPage from '../Ticketing/ViewRaiseATicket';
// End of SOP-26


// (SOP-64) Asset Management - Home Page - New hire Request - TS - start
// Define a type for menu details
interface MenuDetails {
    menuname: string;
    menuid: number;
}
// (SOP-64) Asset Management - Home Page - New hire Request - TS - end


const HomePage =()=>{

    // useState
    // Modified by sanjana SOP-28 to rename usestate data as per the table
    // let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
    // let  [orderBy, setOrderBy] = React.useState("");
    // let  [tableData, setTableData] = React.useState([]);
    // const [page, setPage] = React.useState<number>(0);
    // const [count, setCount] = useState<number>(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    let  [AssetRequestorder, setAssetRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [AssetRequestorderBy, setAssetRequestOrderBy] = React.useState("");
    let  [AssetRequesttableData, setAssetRequestTableData] = React.useState([]);
    const [AssetRequestpage, setAssetRequestPage] = React.useState<number>(0);
    const [AssetRequestcount, setAssetRequestCount] = useState<number>(0);
    const [AssetRequestrowsPerPage, setAssetRequestsetRowsPerPage] = React.useState<number>(5);

    // Included by sanjana SOP-33 useState
    let  [UserAssetorder, setUserAssetOrder] = React.useState<"desc" | "asc">("desc");
    let  [UserAssetorderBy, setUserAssetOrderBy] = React.useState("");
    let  [UserAssettableData, setUserAssetTableData] = React.useState([]);
    const [UserAssetpage, setUserAssetPage] = React.useState<number>(0);
    const [UserAssetcount, setUserAssetCount] = useState<number>(0);
    const [UserAssetrowsPerPage, setUserAssetsetRowsPerPage] = React.useState<number>(5);
    // End of SOP-33
    
    let  [TicketTypeIncidentorder, setTicketTypeIncidentOrder] = React.useState<"desc" | "asc">("desc");
    let  [TicketTypeIncidentorderBy, setTicketTypeIncidentOrderBy] = React.useState("");
    let  [TicketTypeIncidenttableData, setTicketTypeIncidentTableData] = React.useState([]);
    const [TicketTypeIncidentpage, setTicketTypeIncidentPage] = React.useState<number>(0);
    const [TicketTypeIncidentcount, setTicketTypeIncidentCount] = useState<number>(0);
    const [TicketTypeIncidentrowsPerPage, setTicketTypeIncidentsetRowsPerPage] = React.useState<number>(5);
    
    let  [TicketTypeRequestorder, setTicketTypeRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [TicketTypeRequestorderBy, setTicketTypeRequestOrderBy] = React.useState("");
    let  [TicketTypeRequesttableData, setTicketTypeRequestTableData] = React.useState([]);
    const [TicketTypeRequestpage, setTicketTypeRequestPage] = React.useState<number>(0);
    const [TicketTypeRequestcount, setTicketTypeRequestCount] = useState<number>(0);
    const [TicketTypeRequestrowsPerPage, setTicketTypeRequestsetRowsPerPage] = React.useState<number>(5);
    // End of SOP-28

    //SOP-49 - TS - commented - start
    // Asset Management-Ticketing-Create Page
    // const [AddTicketOpen, setAddTicketOpen] = React.useState(false);
    //SOP-49 - TS - commented - end

    // SOP-55 - TS commented (screen pop dialogue is no need ) - start
    // Asset Management-Ticketing-View and Edit Page
        // const [EditTicketOpen, setEditTicketOpen] = React.useState(false);
        // const [EditTicketId, setEditTicketId]= useState('')
        // const [ViewTicketOpen, setViewTicketOpen] = React.useState(false);
        // const [ViewTicketId, setViewTicketId] = useState('')
    // SOP-55 - TS commented - end

    // (SOP-64) Asset Management - Home Page - New hire Request - TS
    const [isHRRequestVisible, setHRRequestVisible] = useState(false); // Visible state
    // console.log("isHRRequestVisible", isHRRequestVisible)
    // (SOP-64) Asset Management - Home Page - New hire Request - TS

    // {/* (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - HR Grid - start */}- TS - start 
    // (useState declaration for grid)
    let  [HRHireRequestorder, setHRHireRequestOrder] = React.useState<"desc" | "asc">("desc");
    let  [HRHireRequestorderBy, setHRHireRequestOrderBy] = React.useState("");
    let  [HRHireRequesttableData, setHRHireRequestTableData] = React.useState([]);
    const [HRHireRequestpage, setHRHireRequestPage] = React.useState<number>(0);
    const [HRHireRequestcount, setHRHireRequestCount] = useState<number>(0);
    const [HRHireRequestrowsPerPage, setHRHireRequestsetRowsPerPage] = React.useState<number>(5);
    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - TS - end

    const navigate = useNavigate();

        // To set action type for edit ad delete
        let actionType;
      
        //SOP-49 - TS - commented - start
        // Asset Management-Ticketing-Create Page
            //  To open add dialouge box
        //    const handleAddTicketOpen = () => {
        //     setAddTicketOpen(true);
        //     };
        
        //     // To close add dialouge box
        //     const handleAddTicketClose = () => {
        //     setAddTicketOpen(false);
        //     };
        //SOP-49 - TS - commented - end

    
    // SOP-55 - TS commented (screen pop dialogue is no need) - start
    // Asset Management-Ticketing-View and Edit Page
            // To open edit dialouge box
            // const handleEditTicketOpen = (id:any) => {
            //     setEditTicketOpen(true);
            //     //console.log("id",id)
            //      setEditTicketId(id)
            //  };
        
            // // To close edit dialouge box
            // const handleEditTicketClose = () => {
            // setEditTicketOpen(false);
            // };
    
            // // To open View dialouge box
            // const handleViewTicketOpen = (id:any) => {
            //     setViewTicketOpen(true);
            //     //console.log("id",id)
            //         setViewTicketId(id)
            //     };
        
            // // To close View dialouge box
            // const handleViewTicketClose = () => {
            // setViewTicketOpen(false);
            // };
      // SOP-55 - TS commented (screen pop dialogue is no need) - end

        // Head cell of My Asset Request
        const AssetRequestHeadCell = [
            {
                id: "request_number",
                numeric: false,
                disablePadding: true,
                label: "Request #",
                isSort: true,
                isBold: true,
            },
            {
                id: "assetname",
                numeric: false,
                disablePadding: true,
                label: "Asset Name",
                isSort: true,
                isBold: true,
            },
            {
                id: "asset_date",
                numeric: false,
                disablePadding: false,
                label: "Request Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
                id: "request_remarks",
                numeric: false,
                disablePadding: false,
                label: "Remarks",
                isSort: true,
                isBold: true,
            },
            //SOP-92 Included By PCS [to include view in asset request grid]
            {
                id: "action",
                numeric: false,
                disablePadding: false,
                label: "Actions",
                isSort: false,
              },
            //EOF SOP-92 Included By PCS [to include view in asset request grid]
            
            ];

        // Included by sanjana SOP-33 head cell of my property

        // Head cell of My Property
        const MyPropertyHeadCell = [
            {
            id: "assetname",
            numeric: false,
            disablePadding: true,
            label: "Asset Name",
            isSort: true,
            isBold: true,
            },
            {
            id: "modelnumber",
            numeric: false,
            disablePadding: false,
            label: "Model number",
            isSort: true,
            isBold: true,
            },

            {
            id: "serialnumber",
            numeric: false,
            disablePadding: false,
            label: "Serial number",
            isSort: true,
            isBold: true,
            },
            {
            id: "manufacturer",
            numeric: false,
            disablePadding: false,
            label: "Manufacturer",
            isSort: true,
            isBold: true,
            },
            //SOP-92 Inlcuded By PCS  [to include view in 'My asset' grid]
            {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
            },
            //EOF SOP-92 Inlcuded By PCS  [to include view in 'My asset' grid]
            ];

        // End of SOP-33
        
        // Head cell of My Asset Request
        const TicketTypeIncidentHeadCell = [
            {
                id: "ticket_number",
                numeric: false,
                disablePadding: true,
                label: "Ticket #",
                isSort: true,
                isBold: true,
            },
            {
                id: "ticket_date",
                numeric: false,
                disablePadding: true,
                label: "Ticket Date",
                isSort: true,
                isBold: true,
                dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
            },
            {
            id: "sys_issue_type_name",
            numeric: false,
            disablePadding: false,
            label: "Issue Type",
            isSort: true,
            isBold: true,
            },
            // {
            // id: "ticket_issuedescription",
            // numeric: false,
            // disablePadding: false,
            // label: "Issue Description",
            // isSort: true,
            // isBold: true,
            // },
            
            //     {
            // id: "ticket_issuetype",
            // numeric: false,
            // disablePadding: false,
            // label: "Issue Type",
            // isSort: true,
            // isBold: true,
            // },
            {
            id: "ticket_issueshortdescription",
            numeric: false,
            disablePadding: false,
            label: "Issue ShortDescription",
            isSort: true,
            isBold: true,
            },
            {
                id: "action",
                numeric: false,
                disablePadding: false,
                label: "Actions",
                isSort: false,
              },
            
            ];

        // Head cell of My Asset Request
        // const TicketTypeRequestHeadCell = [
        //     {
        //         id: "ticket_number",
        //         numeric: false,
        //         disablePadding: true,
        //         label: "Ticket #",
        //         isSort: true,
        //         isBold: true,
        //     },
        //     {
        //         id: "ticket_date",
        //         numeric: false,
        //         disablePadding: true,
        //         label: "Ticket Date",
        //         isSort: true,
        //         isBold: true,
        //         dateFormatter: (date:any) => dayjs(date).format("DD-MM-YYYY"),
        //     },
        //     {
        //     id: "sys_issue_type_name",
        //     numeric: false,
        //     disablePadding: false,
        //     label: "Issue Type",
        //     isSort: true,
        //     isBold: true,
        //     },
        //     {
        //     id: "ticket_issuedescription",
        //     numeric: false,
        //     disablePadding: false,
        //     label: "Issue Description",
        //     isSort: true,
        //     isBold: true,
        //     },
        //     {
        //     id: "action",
        //     numeric: false,
        //     disablePadding: false,
        //     label: "Actions",
        //     isSort: false,
        //       },
            
        //     ];  

     
    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - start
    // HRHireRequestHeadCell
    const HRHireRequestHeadCell = [
        // {
        //     id: "hirerequestid",
        //     numeric: false,
        //     disablePadding: true,
        //     label: "HireRequest Id #",
        //     isSort: true,
        //     isBold: true,
        // },
        {
            id: "hirerequest_hiring_jobcode_title",
            numeric: false,
            disablePadding: true,
            label: "Job Profile",
            isSort: true,
            isBold: true,
        },
        {
            id: "hirerequest_total_open_position",
            numeric: false,
            disablePadding: true,
            label: "Total Position",
            isSort: true,
            isBold: true,
        },
        {
            id: "departmentname",
            numeric: false,
            disablePadding: true,
            label: "Department",
            isSort: true,
            isBold: true,
        },
        {
            id: "employee_name",
            numeric: false,
            disablePadding: true,
            label: "Hiring Manager",
            isSort: true,
            isBold: true,
        },
        {
            id: "sys_hr_employment_type_name",
            numeric: false,
            disablePadding: true,
            label: "Type of Employment",
            isSort: true,
            isBold: true,
        },
        {
            id: "sys_hr_hirerequest_status_name",
            numeric: false,
            disablePadding: true,
            label: "Current Status",
            isSort: true,
            isBold: true,
        },
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
          },
        ];
    
        // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - end
         
    //SOP-92 Included By PCS [Including search functionality]
        const [cells, setCells] = useState<any>(AssetRequestHeadCell); //My Asset request grid
        const [cells_MyTicket, setcells_MyTicket] = useState<any>(TicketTypeIncidentHeadCell);//My Tickets (Incident) grid
        const [cells_MyAssets, setcells_MyAssets] = useState<any>(MyPropertyHeadCell);//My Assets grid
        const [cells_HireRequest, setcells_HireRequest] = useState<any>(HRHireRequestHeadCell);//Hire Requests grid
   //EOF SOP-92 Included By PCS [Including search functionality]
        
    //  Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    // Included by sanjana SOP-40 to get Employee id
    let EmployeeId = GlobalData.GlobalEmployeeId
    // End of SOP-40

    // Included by sanjana SOP-26 To get Ticket list search API 
    // Modified by sanjana SOP-28 to get request an asset API 
    const getAssetRequestList = () => 
    {     
        
        //SOP-92 Included by PCS [search functionality for 'My Asset Request' grid]
            // Initializing Variable 
                let AssetRequestNumber = null;
                let AssetName = null;
                let AssetRequestDate = null;
                let AssetRemarks = null;
               for (const item of cells)
               {
                if (item.id === "request_number") {
                    AssetRequestNumber = item.value;
                }

                if (item.id === "assetname") {
                    AssetName = item.value;
                }

                if (item.id === "asset_date") { 
                    AssetRequestDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
                    AssetRequestDate =AssetRequestDate? AssetRequestDate.add(5, 'hour').add(30, 'minute'):null;
                }

                if (item.id === "request_remarks") {
                    AssetRemarks = item.value;
                }
               }
        //EOF SOP-92 Included by PCS [search functionality for My Asset Request Grid]

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_request_and_asset_list_for_employee`, {
            
            //SOP-92 Inlcuded By PCS [Including search functionality]
            SearchAssetRequestNumber: AssetRequestNumber,
            SearchAssetName: AssetName,
            SearchAssetRequestDate: AssetRequestDate,
            SearchAssetRemarks: AssetRemarks,
            //EOF SOP-92 Included By PCS [Including search functionality]
            SortColumnName: AssetRequestorderBy,
            SortColumnDirection: AssetRequestorder,
            StartIndex: AssetRequestpage + 1,
            PageSize: AssetRequestrowsPerPage,
            CompanyId:CompanyId,
            // Modified by sanjana SOP-40 to set employee id insted of user id
            // CreatedBy:UserId,
            // Modified by sanjana SOP-58 to set userid id insted of employee id
             CreatedBy:UserId,
            //CreatedBy:EmployeeId,
            // End of SOP-58
            
            // End of SOP-40
        })
        .then((res: any) => 
        {
        const formattedTableData= res.data.data.map((item:any)=>{
        return {...item,
            //SOP-92 Modified By PCS [including view option in the grid]
            // id:item.ticketid,
            // request_date: item.request_date ? dayjs(item.request_date).format("DD-MM-YYYY") : "",
               asset_date: item.asset_date ? dayjs(item.asset_date).format("DD-MM-YYYY") : "",
               id:item.requestid,
           //EOF SOP-92 Modified By PCS [including view option in the grid]
        }

        }) 
        //   console.log("Asset Request",formattedTableData)
        
         setAssetRequestTableData(formattedTableData);
        if(res.data.data[0]===undefined)
        {
            setAssetRequestCount(0);
        }
        else
        {
            setAssetRequestCount(res.data.data[0].total_count);
        }
        })
    .catch((error) => 
        {
        if (error.response.status === 404)
        {
            setAssetRequestTableData([]);
        }
        Failed(error.response.message);
        });
    };
    // End of SOP-28
    // End of SOP-26

    // Included by sanjana SOP-33 to get user list API 
    const getUserAssetList = () => 
        {     
           
        //SOP-92 Included by PCS [search functionality for 'My Asset' grid]
        // Initializing Variable 
            let AssetName = null;
            let ModelNumber = null;
            let SerialNumber = null;
            let Manufacturer = null;
           for (const item of cells_MyAssets)
            {
              if (item.id === "assetname") {
                AssetName = item.value;
              }

             if (item.id === "modelnumber") {
                ModelNumber = item.value;
              }

              if (item.id === "serialnumber") {
                SerialNumber = item.value;
              }

             if (item.id === "manufacturer") {
                Manufacturer = item.value;
                  }
           }
    //EOF SOP-92 Included by PCS [search functionality for My Asset Request Grid]
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/requestanasset/get_user_asset_list`, {

               //SOP-92 Inlcuded By PCS [To include search functionality]
               SearchAssetName: AssetName,
               SearchModelNumber: ModelNumber,
               SearchSerialNumber: SerialNumber,
               SearchManufacturer: Manufacturer,
               //EOF SOP-92 Included By PCS [To include search functionality]
                SortColumnName: UserAssetorderBy,
                SortColumnDirection: UserAssetorder,
                StartIndex: UserAssetpage + 1,
                PageSize: UserAssetrowsPerPage,
                CompanyId:CompanyId,
                //SOP-89 Modified By PCS [passing employeeid instead of userid- to display assets used by employee]
                // Userid:UserId
                   Userid:EmployeeId,
               //EOF SOP-89 Modified By PCS
               
            })
            .then((res: any) => 
            {
           
            const formattedTableData= res.data.data.map((item:any)=>{
             return {...item,
            //SOP-92 Modified By PCS [including view option in the grid]
            // id:item.ticketid,
               assetid:item.request_forwhichassetid,
          //EOF SOP-92 Modified By PCS [including view option in the grid]
           
            }
            }) 
            // console.log("Asset ",formattedTableData)
             setUserAssetTableData(formattedTableData);
             if(res.data.data[0]===undefined)
            {
                setUserAssetCount(0);
            }
            else
            {
                setUserAssetCount(res.data.data[0].total_count);
            }
            })

            .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setUserAssetTableData([]);
            }
            Failed(error.response.message);
            console.log(' FAIled',error.response.message)
            });
        };
    // End of SOP-33

    // Modified by sanjana SOP-28 to get ticket list as pet the ticket type = Incident
    const getTicketTypeIncidentList = () => 
        {   
        //SOP-92 Included by PCS [search functionality for My Ticket(Incident) grid]
            // Initializing Variable 
            let TicketNumber = null;
            let TicketDate = null;
            let TicketIssueType = null;
            let TicketShortDescription = null;
           for (const item of cells_MyTicket)
           {
              if (item.id === "ticket_number") {
                TicketNumber = item.value;
               }

              if (item.id === "ticket_date") { 
                TicketDate = item.value ? dayjs(item.value, "DD-MM-YYYY") : null; // Format the date
                TicketDate =TicketDate? TicketDate.add(5, 'hour').add(30, 'minute'):null;
                }

              if (item.id === "sys_issue_type_name") {
                TicketIssueType = item.value;
                }

              if (item.id === "ticket_issueshortdescription") {
                TicketShortDescription = item.value;
                }
           }
    //EOF SOP-92 Included by PCS [search functionality for My Ticket(Incident) grid]

            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_ticket_list_for_employee`, {
                //SOP-92 Inlcuded By PCS [To include search functionality]
                SearchTicketNumber: TicketNumber,
                SearchTicketDate: TicketDate,
                SearchTicketIssueType: TicketIssueType,
                SearchTicketIssueShortDescription: TicketShortDescription,
                //EOF SOP-92 Included By PCS [To include search functionality]
                SortColumnName: TicketTypeIncidentorderBy,
                SortColumnDirection: TicketTypeIncidentorder,
                StartIndex: TicketTypeIncidentpage + 1,
                PageSize: TicketTypeIncidentrowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId,
                // // ticket type 1 for Incident
                // // TicketType:1
                // IssueType:1,
                // IssueShortDescription:'tested by sivasuri'
                // "SortColumnName":TicketTypeIncidentorderBy,
                // "SortColumnDirection":null,
                // "StartIndex":1,
                //   "PageSize":10,
                //   "CompanyId":65,
                //   "CreatedBy":109
            })
            .then((res: any) => 
            {
            
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                 id:item.ticketid,
                 ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
            }
            }) 
            //console.log("formattedTableData",formattedTableData)
            setTicketTypeIncidentTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setTicketTypeIncidentCount(0);
            }
            else
            {
                setTicketTypeIncidentCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setTicketTypeIncidentTableData([]);
            }
            Failed(error.response.message);
            });
        };
        // End of SOP-28


    // Modified by sanjana SOP-28 to get ticket list as pet the ticket type = Request
    //SOP-92 Commenetd By PCS [not in use- removed the grid from home page]
    // const getTicketTypeRequestList = () => 
    //     {  
    //         commonService
    //         .postService(`${process.env.REACT_APP_BACKEND_URL}/ticketing/get_ticket_list_for_employee`, {
    //             SortColumnName: TicketTypeRequestorderBy,
    //             SortColumnDirection: TicketTypeRequestorder,
    //             StartIndex: TicketTypeRequestpage + 1,
    //             PageSize: TicketTypeRequestrowsPerPage,
    //             CompanyId:CompanyId,
    //             CreatedBy:UserId,
    //             TicketType:2
    //         })
    //         .then((res: any) => 
    //         {
    //             //console.log("res",res)
    //         const formattedTableData= res.data.data.map((item:any)=>{
    //         return {...item,
    //             id:item.ticketid,
    //             ticket_date: item.ticket_date ? dayjs(item.ticket_date).format("DD-MM-YYYY") : "",
    //         }
    //         }) 
    //         //console.log("formattedTableData",formattedTableData)
            
    //         setTicketTypeRequestTableData(formattedTableData);
    //         if(res.data.data[0]===undefined)
    //         {
    //             setTicketTypeRequestCount(0);
    //         }
    //         else
    //         {
    //             setTicketTypeRequestCount(res.data.data[0].total_count);
    //         }
    //         })
    //     .catch((error) => 
    //         {
    //         if (error.response.status === 404)
    //         {
    //             setTicketTypeRequestTableData([]);
    //         }
    //         Failed(error.response.message);
    //         });
    //     };
        // End of SOP-28   
    
    
    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - start
    //  getHRHireRequestList API 
    const getHRHireRequestList = () => 
        {      

        //SOP-92 Included by PCS [search functionality in 'Hire Request' grid]
            // Initializing Variable 

            let JobProfile = null;
            let TotalPosition = null;
            let Department = null;
            let HiringManager = null;
            let TypeOfEmployment = null;
            let CurrentStatus = null;
     
            for (const item of cells_HireRequest)
             {
                if (item.id === "hirerequest_hiring_jobcode_title") {
                    JobProfile = item.value;
                }

               if (item.id === "hirerequest_total_open_position") {
                TotalPosition = item.value;
                }

                if (item.id === "departmentname") {
                Department = item.value;
                 }

               if (item.id === "employee_name") {
                HiringManager = item.value;
                }

               if (item.id === "sys_hr_employment_type_name") {
                TypeOfEmployment = item.value;
                }

               if (item.id === "sys_hr_hirerequest_status_name") {
                CurrentStatus = item.value;
                }
           }
        //EOF SOP-92 Included by PCS [search functionality 'Hire Request'  grid]
            

        commonService
        //SOP-86 Modified by PCS [to display only Hire request for that employee]
            // .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_hr_hire_requestlist`, {
            .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_hr_hire_requestlist_for_employee`, {
        //EOF SOP-86 Modified by PCS [to display only Hire request for that employee]
                 //SOP-92 Inlcuded By PCS [To include search functionality in 'Hire request' Grid]
                 SearchJobProfile: JobProfile,
                 SearchTotalPosition: TotalPosition,
                 SearchDepartment: Department,
                 SearchHiringManager: HiringManager,
                 SearchTypeofEmployment:TypeOfEmployment,
                 SearchCurrentStatus: CurrentStatus,
                 //EOF SOP-92 Included By PCS [To include search functionality in 'Hire request' Grid]
                 SortColumnName: HRHireRequestorderBy,
                SortColumnDirection: HRHireRequestorder,
                StartIndex: HRHireRequestpage + 1,
                PageSize: HRHireRequestrowsPerPage,
                CompanyId:CompanyId,
                CreatedBy:UserId,
            })
            .then((res: any) => 
            {
           
            const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                id:item.hirerequestid,//SOP-86 Included By PCS[to get hire request id from grid]
                hirerequest_start_date: item.hirerequest_start_date ? dayjs(item.hirerequest_start_date).format("DD-MM-YYYY") : "",
            }
    
            }) 
            // console.log("formattedTableData111",formattedTableData)
            setHRHireRequestTableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
                setHRHireRequestCount(0);
            }
            else
            {
                setHRHireRequestCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setHRHireRequestTableData([]);
            }
            Failed(error.response.message);
            });
    };
  
//Hire Request Grid's function
    // handle Sort
    const handleHRHireRequestIncidentRequestSort = (event: any, property: any) =>
        {
        const isAsc = HRHireRequestorderBy === property && HRHireRequestorder === "asc";
        HRHireRequestorder = isAsc ? "desc" : "asc";
        setHRHireRequestOrder(HRHireRequestorder);
        HRHireRequestorderBy = property;
        setHRHireRequestOrderBy(HRHireRequestorderBy);
    };
    
    // RequestactionFunction
    const HRHireRequestactionFunction = (id: string, actionText: string) => 
        {
            //SOP-86 Uncommented & modified  by PCS [to enable view ICON]
             if (actionText === "view") navigate(`/hr_view_hire_request/${id}`);
            //EOF SOP-86 Uncommented & modified  by PCS [to enable view ICON]
            // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
        };

    // Pagination Handler of table
    const HRHireRequesthandleChangePage = (event: unknown, newPage: number) => {
        setHRHireRequestPage(newPage);
        };

    //SOP-92 Included by PCS [search function in 'Hire Requests' Grid]
        // Search Handler of table
        const handleSearchHireRequests = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const search = [...cells_HireRequest];
            const index = cells_HireRequest.findIndex((c: any) => c.id === e.target.name);
            if (index !== -1) {
            search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
            }
            setcells_HireRequest(search);
            HRHireRequesthandleChangePage(setHRHireRequestPage,0);
            getHRHireRequestList();
        };
    //EOF SOP-92 Included by PCS [search function in 'Hire Requests' Grid]

    // Rows Per page Handler
    const HRHireRequesthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setHRHireRequestsetRowsPerPage(+event.target.value);
            setHRHireRequestPage(0);
    };
    
    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - end
    // Sort, Action function,Pagination, Per page Handler - end
//EOF Hire Request Grid's function

//Asset Request Grid's function
    
    // Sort Handler
    const handleAssetRequestIncidentRequestSort = (event: any, property: any) =>
    {
    const isAsc = AssetRequestorderBy === property && AssetRequestorder === "asc";
    AssetRequestorder = isAsc ? "desc" : "asc";
    setAssetRequestOrder(AssetRequestorder);
    AssetRequestorderBy = property;
    setAssetRequestOrderBy(AssetRequestorderBy);
    };


    // Action function of table icons
    const AssetRequestactionFunction = (id: string, actionText: string) => 
    {
        //SOP-92  modified  by PCS [to enable view ICON in asset request grid]
        // if (actionText === "view") navigate(`/view_nationality/${id}`);
           if (actionText === "view") navigate(`/view_asset_request/${id}`);
        //EOF SOP-92 modified  by PCS [to enable view ICON in asset request grid]
    
    };


    // Pagination Handler of table
    const AssetRequesthandleChangePage = (event: unknown, newPage: number) => {
        setAssetRequestPage(newPage);
    };

    //SOP-92 Included by PCS [search function in 'My Asset Request' Grid]
    // Search Handler of table
         const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const search = [...cells];
                const index = cells.findIndex((c: any) => c.id === e.target.name);
                if (index !== -1) {
                    search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
                }
                setCells(search);
                AssetRequesthandleChangePage(setAssetRequestPage,0);
                getAssetRequestList();
           };
    //EOF SOP-92 Included by PCS [search function in 'My Asset' Requests Grid]

    // Rows Per page Handler
    const AssetRequesthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setAssetRequestsetRowsPerPage(+event.target.value);
            setAssetRequestPage(0);
    };

//EOF Asset Request Grid's function

//My Assets Grid's function
    // Included by sanjana SOP-33 
        // Sort Handler
        const handleUserAssetSort = (event: any, property: any) =>
        {
        const isAsc = UserAssetorderBy === property && UserAssetorder === "asc";
        UserAssetorder = isAsc ? "desc" : "asc";
        setUserAssetOrder(UserAssetorder);
        UserAssetorderBy = property;
        setUserAssetOrderBy(UserAssetorderBy);
        };
           
        // Action function of table icons
        const UserAssetactionFunction = (id: string, actionText: string) => 
        {   

             console.log('My assetid',id)
            //SOP-92 modified by PCS [enable view icon in my asset grid]
            // if (actionText === "view") navigate(`/view_nationality/${id}`);
             if (actionText === "view") navigate(`/view_asset_details/${id}`);
            //EOF SOP-92 modified by PCS [enable view icon in my asset grid]
        };
   
        // Pagination Handler of table
        const UserAssethandleChangePage = (event: unknown, newPage: number) => {
            setUserAssetPage(newPage);
        };

        //Search
        const handleSearchMyAssets = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const search = [...cells_MyAssets];
            const index = cells_MyAssets.findIndex((c: any) => c.id === e.target.name);
            if (index !== -1) {
              search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
            }
           
            setcells_MyAssets(search);
            UserAssethandleChangePage(setUserAssetPage,0);
            getUserAssetList();
            

          };
       
       // Rows Per page Handler
        const UserAssethandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setUserAssetsetRowsPerPage(+event.target.value);
                setUserAssetPage(0);
        };
        // End of SOP-33
//EOF My Assets Grid's function

//My Tickets grid function
    // Sort Handler
    const handleTicketTypeIncidentRequestSort = (event: any, property: any) =>
        {
        const isAsc = TicketTypeIncidentorderBy === property && TicketTypeIncidentorder === "asc";
        TicketTypeIncidentorder = isAsc ? "desc" : "asc";
        setTicketTypeIncidentOrder(TicketTypeIncidentorder);
        TicketTypeIncidentorderBy = property;
        setTicketTypeIncidentOrderBy(TicketTypeIncidentorderBy);
        };
    
    // Action function of table icons
    const TicketTypeIncidentactionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "view"){
            // SOP-55 - TS commented and included (navigation) - start
            // Asset Management-Ticketing-View and Edit Page
                // handleViewTicketOpen(id);
                navigate(`/View_raise_a_ticket_page/${id}`)
            // SOP-55 - TS commented and included (navigation) - end
        }
    };
    

    // Pagination Handler of table
    const TicketTypeIncidenthandleChangePage = (event: unknown, newPage: number) => {
        setTicketTypeIncidentPage(newPage);
    };

    //SOP-92 Included by PCS [search function in'My Ticket' Incident Grid]
        // Search Handler of table
        const handleSearchMyTicket = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const search = [...cells_MyTicket];
            const index = cells_MyTicket.findIndex((c: any) => c.id === e.target.name);
            if (index !== -1) {
            search[index].value = e.target.value.trim() !== '' ? e.target.value : null; // Reset to null if empty
            }
            setcells_MyTicket(search);
            TicketTypeIncidenthandleChangePage(setTicketTypeIncidentPage,0);
            getTicketTypeIncidentList();
        };
    //EOF SOP-92 Included by PCS [search function in'My Ticket' Incident Grid]


    // Rows Per page Handler
    const TicketTypeIncidenthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setTicketTypeIncidentsetRowsPerPage(+event.target.value);
            setTicketTypeIncidentPage(0);
    };
//EOF My Tickets grid function

    // Sort Handler
    const handleTicketTypeRequestRequestSort = (event: any, property: any) =>
        {
        const isAsc = TicketTypeRequestorderBy === property && TicketTypeRequestorder === "asc";
        TicketTypeRequestorder = isAsc ? "desc" : "asc";
        setTicketTypeRequestOrder(TicketTypeRequestorder);
        TicketTypeRequestorderBy = property;
        setTicketTypeRequestOrderBy(TicketTypeRequestorderBy);
        };
      
    // Action function of table icons
    const TicketTypeRequestactionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "view") {

            // SOP-55 - TS commented and included (navigation) - start
            // Asset Management-Ticketing-View and Edit Page
                // handleViewTicketOpen(id);
                navigate(`/edit_raise_a_ticket_page/${id}`)
            // SOP-55 - TS commented and included (navigation) - end
        }
        // if (actionText === "edit") navigate(`/edit_nationality/${id}`);
    };
    

    // Pagination Handler of table
    const TicketTypeRequesthandleChangePage = (event: unknown, newPage: number) => {
        setTicketTypeRequestPage(newPage);
    };

    // Rows Per page Handler
    const TicketTypeRequesthandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setTicketTypeRequestsetRowsPerPage(+event.target.value);
            setTicketTypeRequestPage(0);
    };

// (SOP-64) Asset Management - Home Page - New hire Request - TS -  start

    //user rights from session storage 
    useEffect(() => {
        // Read from session storage
        const employeeMenuDetails = sessionStorage.getItem('EmployeeMenuDetails');
        if (employeeMenuDetails) {
          try {
            const menuDetailsArray: MenuDetails[] = JSON.parse(employeeMenuDetails);
                // console.log("menuDetailsArray", menuDetailsArray)
              
            // check if any item in the array matches the condition
            const isHRHierarchyMenuPresent = menuDetailsArray.some((menuDetails: MenuDetails) =>
              menuDetails.menuname === 'HR Work Space' && menuDetails.menuid === 11
              );
            //   console.log("isHRHierarchyMenuPresent",isHRHierarchyMenuPresent)
            setHRRequestVisible(isHRHierarchyMenuPresent);
          }
          catch (error) {
            // console.error('error from session storage', error);
            setHRRequestVisible(false);
          }
        } else {
          setHRRequestVisible(false);
        }
      }, []);

    // (SOP-64) Asset Management - Home Page - New hire Request- TS - end


    useEffect(() => {
        getAssetRequestList();
        getTicketTypeIncidentList();
        // getTicketTypeRequestList();//SOP-92 Commented By PCS [grid is not in  use in Home page]
        // Included by sanjana SOP-33
        getUserAssetList();
        // End of SOP-33
       getHRHireRequestList();  //  (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - API 

    }, [AssetRequestrowsPerPage,AssetRequestpage,AssetRequestorderBy,AssetRequestorder,
        TicketTypeIncidentrowsPerPage,TicketTypeIncidentpage, TicketTypeIncidentorderBy,TicketTypeIncidentorder,
        TicketTypeRequestrowsPerPage,TicketTypeRequestpage, TicketTypeRequestorderBy, TicketTypeRequestorder,
        // Included by sanjana SOP-33 to declare functions
        UserAssetrowsPerPage,UserAssetpage,UserAssetorderBy,UserAssetorder
        // End of SOP-33
        //  (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager
        ,HRHireRequestorder,HRHireRequestorderBy,HRHireRequestpage,HRHireRequestrowsPerPage
    ]); 
    

    return(
        <MainContainerWrapper headingText=''> 
            <Box >
                <Box display={'flex'} justifyContent={'center'}>
                    
                    {/* (SOP-64) Asset Management - Home Page - New hire Request- TS (alignment) */}
                    {/* <Box bgcolor={'white'} width={600} borderRadius={3} > */}
                    {/* //SOP-75 feedback points L1 modified by  PCS */}
                        {/* <Box bgcolor={'white'} width={800} borderRadius={3} > */}
                            <Box bgcolor={'white'} width={1000} borderRadius={3} >
                    {/* //EOF SOP-75  feedback points L1 modified by  PCS */}
                    {/* (SOP-64) Asset Management - Home Page - New hire Request - TS (alignment)*/}
                        
                    <Box sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        },
                    }}>
                    <Typography 
                    sx={{
                        fontSize:{
                            lg:'1.5rem',
                            xs:'1rem'
                    }, 
                    }}
                    color={'black'} mt={5}>
                        {/* Modified by sanjana SOP-28 */}
                        {/* How can we Help you today? */}
                        How can we help you today?
                        {/* End of SOP-28 */}
                    </Typography>
                </Box>  

                <Box mb={3}>
                    <Grid container
                        mt={3}  mb={3}  
                        sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        }}}>
                    <Grid item 
                        //  (SOP-64) Asset Management - Home Page - New hire Request - TS (alignment)
                        // lg={6} md={6} 
                          //SOP-75 feedback points L1 included By PCS [modifying grid space]
                           // lg={4} md={4} 
                              lg={3} md={3} 
                          //EOF SOP-75 feedback points L1 included By PCS [modifying grid space]
                        sx={{
                            display:{
                                lg:'flex',
                                xs:'flex',
                                md:'flex',
                            },
                            justifyContent:{
                                lg:'center',
                                xs:'center',
                                md:'center',
                            }}}>
                            <Box >
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    <img src={requestAsset} alt='requestAsset' height={65} width={65} />
                                </Box>
                                <Box mt={3} 
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}>
                                    <Typography fontSize={'1.5rem'} color={'#4CB18F'} sx={{cursor:'pointer'}}
                                    // Included by sanjana SOP-25 to add link of request an asset page
                                    onClick={()=>{
                                        navigate("/request_an_asset")
                                    }}
                                    // End of SOP-25
                                    >
                                       <u>  Request an asset</u> 
                                    </Typography>                                
                                </Box>

                                <Box mt={3}  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    <Typography fontSize={'1rem'} color={'#4CB18F'}>
                                    Request a hardware
                                    </Typography>
                                </Box>
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}
                                >
                                    <Typography fontSize={'1rem'} color={'#4CB18F'}>
                                        software or IT Services
                                    </Typography>
                                </Box>
                            </Box>
                    </Grid>


                    {/* (SOP-64) Asset Management - Home Page - New hire Request - TS (alignment)*/}
                    {/* <Grid item lg={6} md={6}   */}
                                
                    <Grid item 
                    //SOP-75 feedback points L1 included By PCS [modifying grid space]
                           // lg={4} md={4} 
                               lg={3} md={3} 
                    //EOF SOP-75 feedback points L1 included By PCS [modifying grid space] 

                        sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        },
                        }}
                    >
                        <Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <img src={raiseTicket} alt='raiseTicket' height={65} width={65} />
                            </Box>
                            <Box mt={3} 
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1.5rem'} color={'black'} sx={{cursor:'pointer'}}
                                // Included by sanjana to Open add ticket dialouge box
                                        onClick={() => {
                                    
                                //SOP-49 - TS - commented - start
                                // Asset Management-Ticketing-Create Page
                                    // handleAddTicketOpen();
                                
                                    navigate('/raise_a_ticket_page') // included navigation 
                                //SOP-49 - TS - commented - end
                                }}
                                >
                                    {/* Modified by sanjana SOP-26 to give underline for text */}
                                            {/* Raise a ticket */}
                                            <u> Raise a ticket </u>
                                        {/* End of SOP-26 */}
                                </Typography>                                
                            </Box>
                            <Box mt={3}  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'black'}>
                                    Report a problem
                                </Typography>
                            </Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'black'}>
                                    to get support
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>



            {/* //SOP-75 feedback points L1 included By PCS */}

                    <Grid item 
                       lg={3} md={3} 
                        sx={{
                            display:{
                                lg:'flex',
                                xs:'flex',
                                md:'flex',
                            },
                            justifyContent:{
                                lg:'center',
                                xs:'center',
                                md:'center',
                            }}}>
                            <Box >
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    <img src={HR} alt='hieracrchy' height={65} width={65} />
                                </Box>
                                <Box mt={3} 
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}>
                                    <Typography fontSize={'1.5rem'} color={'#4CB18F'} sx={{cursor:'pointer'}}
                                   
                                    onClick={()=>{
                                        navigate("/hr_hierarchy")
                                    }}
                                    
                                    >
                                      {/* //SOP-76 feedback points L2 modified by PCS [renaming View  Hierarchy to View ORG ] */}
                                             {/* <u> View  Hierarchy </u> */}
                                                 <u> View  ORG </u>
                                      {/* //EOF SOP-76 feedback points L2 modified by PCS [renaming View  Hierarchy to View ORG ] */}
                                    </Typography>                                
                                </Box>

                                <Box mt={3}  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                }}>
                                    
                                </Box>
                                <Box  
                                    sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    },
                                    }}
                                >
                                  
                                </Box>
                            </Box>
                    </Grid>

            {/* //EOF SOP-75 feedback points L1 included By PCS*/}
                                

                {/* (SOP-64) Asset Management - Home Page - New hire Request  -  HR Request block start - - TS*/}
            {/* //SOP-75 feedback points L1 commented by PCS [grid space is modified- alignment is changed] */}
                    {/* {isHRRequestVisible && (
                        <Box >
                            <Grid item 
                                //SOP-75 feedback points L1 included By PCS [modifying grid space]
                                // lg={4} md={4} 
                                    lg={2} md={2} 
                                //EOF SOP-75 feedback points L1 included By PCS [modifying grid space] 
                                sx={{
                                    display:{
                                        lg:'flex',
                                        xs:'flex',
                                        md:'flex',
                                    },
                                    justifyContent:{
                                        lg:'center',
                                        xs:'center',
                                        md:'center',
                                    }}}>
                                    <Box >
                                        <Box  
                                        sx={{
                                            display:{
                                                lg:'flex',
                                                xs:'flex',
                                                md:'flex',
                                            },
                                            justifyContent:{
                                                lg:'center',
                                                xs:'center',
                                                md:'center',
                                            },
                                        }}>
                                            <img src={HR} alt='requestAsset' height={67} width={67} />
                                        </Box>
                                        <Box mt={3} 
                                            sx={{
                                                display:{
                                                    lg:'flex',
                                                    xs:'flex',
                                                    md:'flex',
                                                },
                                                justifyContent:{
                                                    lg:'center',
                                                    xs:'center',
                                                    md:'center',
                                                },
                                                }}>
                                                <Typography fontSize={'1.5rem'} color={'#600918'} sx={{cursor:'pointer'}}
                                                    onClick={() => {
                                                    // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - TS (navigate path included) 
                                                        navigate("/hr_new_hire_request")
                                                    }}
                                                >
                                                    <u>  New Hire Request </u> 
                                                </Typography>                                
                                        </Box>
                                        <Box mt={3}  
                                            sx={{
                                                display:{
                                                    lg:'flex',
                                                    xs:'flex',
                                                    md:'flex',
                                                },
                                                justifyContent:{
                                                    lg:'center',
                                                    xs:'center',
                                                    md:'center',
                                                },
                                            }}>
                                                <Typography fontSize={'1rem'} color={'#600918'}>
                                                    Request for a
                                                </Typography>
                                        </Box>
                                        <Box  
                                            sx={{
                                                display:{
                                                    lg:'flex',
                                                    xs:'flex',
                                                    md:'flex',
                                                },
                                                justifyContent:{
                                                    lg:'center',
                                                    xs:'center',
                                                    md:'center',
                                                },
                                            }}>
                                                <Typography fontSize={'1rem'} color={'#600918'}>
                                                new Hire
                                                </Typography>
                                        </Box>
                                    </Box>
                            </Grid>
                        </Box>
                        )}             */}
       {/* //SOP-75 feedback points L1 commented by PCS [grid space is modified- alignment is changed] */}   

                     {isHRRequestVisible && (
                       <Grid item 
                    //SOP-75 feedback points L1 included By PCS [modifying grid space]
                           // lg={4} md={4} 
                              lg={3} md={3} 
                    //EOF SOP-75 feedback points L1 included By PCS [modifying grid space] 

                        sx={{
                        display:{
                            lg:'flex',
                            xs:'flex',
                            md:'flex',
                        },
                        justifyContent:{
                            lg:'center',
                            xs:'center',
                            md:'center',
                        },
                        }}
                    >
                        <Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                               <img src={HR} alt='requestAsset' height={67} width={67} />
                            </Box>
                            <Box mt={3} 
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1.5rem'} color={'black'} sx={{cursor:'pointer'}}
                               
                                        onClick={() => {
                                   // (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - TS (navigate path included) 
                                   navigate("/hr_new_hire_request")
                                }}
                                >
                                   <u>  New Hire Request </u> 
                                </Typography>                                
                            </Box>
                            <Box mt={3}  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'#600918'}>
                                            Request for a
                                </Typography>
                            </Box>
                            <Box  
                            sx={{
                                display:{
                                    lg:'flex',
                                    xs:'flex',
                                    md:'flex',
                                },
                                justifyContent:{
                                    lg:'center',
                                    xs:'center',
                                    md:'center',
                                },
                            }}>
                                <Typography fontSize={'1rem'} color={'#600918'}>
                                           new Hire
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                     )}
                {/* (SOP-64) Asset Management - Home Page - New hire Request -  HR Request  -  HR Request block end - - TS*/}
                                
                </Grid>
                </Box>                     
                </Box>
            </Box>  
            <Box  mt={5} 
            sx={{
                    display:{
                        lg:'flex',
                        xs:'flex',
                        md:'flex',
                    },
                    justifyContent:{
                        lg:'center',
                        xs:'center',
                        md:'center',
                    },
                }}>
                <Grid container spacing={3}>
                    <Grid item lg={6} md={12} xs={12}>
                        <Typography variant='h2'>
                            My Asset Request
                        </Typography>
                        <Grid container>
                            <Grid item lg={12} md={12} xs={12}>
                                <Box bgcolor={'white'} p={2} mt={3}>
                                    <Typography variant='h3'>
                                        {/* Modified by sanjana SOP-28 to change heading */}
                                        {/* My Approvals */}
                                            Request(s)
                                        {/* End of SOP-28 */}
                                    </Typography>
                                    <EnhancedTable
                                        headCells={AssetRequestHeadCell}
                                        rows={AssetRequesttableData}
                                        rowsPerPage={AssetRequestrowsPerPage}
                                        page={AssetRequestpage}
                                        handleChangePage={AssetRequesthandleChangePage}
                                        handleChangeRowsPerPage={AssetRequesthandleChangeRowsPerPage}
                                        handleRequestSort={handleAssetRequestIncidentRequestSort}
                                        handleSearch={handleSearch}//SOP-92 Included By PCS [Including search in My Asset request grid]
                                        order={AssetRequestorder}
                                        orderBy={AssetRequestorderBy}
                                        actionFunction={AssetRequestactionFunction}
                                        //SOP-92 Modified by PCS [enable view icon]
                                        // isView={false}
                                        isView={true}
                                        //SOP-92 Modified by PCS 
                                        isDelete={false}
                                        isEdit={false}
                                        total={AssetRequestcount}
                                        //SOP-92 Modified by PCS [enable search function]
                                        // isSearch={false}
                                        isSearch={true}
                                        //EOF SOP-92 Modified by PCS
                                        HoverColor={'#E7F1FB'}
                                        />
                                </Box>                                
                            </Grid>
                            {/* <Grid item lg={12} md={12} xs={12}>
                                <Box bgcolor={'white'} p={2} mt={3}>
                                    <Typography variant='h3'>
                                       My Properties 
                                     </Typography>
                                    <EnhancedTable
                                        headCells={MyPropertyHeadCell}
                                        rows={UserAssettableData}
                                        rowsPerPage={UserAssetrowsPerPage}
                                        page={UserAssetpage}
                                        handleChangePage={UserAssethandleChangePage}
                                        handleChangeRowsPerPage={UserAssethandleChangeRowsPerPage}
                                        handleRequestSort={handleUserAssetSort}
                                        order={UserAssetorder}
                                        orderBy={UserAssetorderBy}
                                        actionFunction={UserAssetactionFunction}
                                        isView={false}
                                        isDelete={false}
                                        isEdit={false}
                                        total={UserAssetcount}
                                        isSearch={false}
                                        HoverColor={'#E7F1FB'}
                                        /> 
                                {/* </Box>                                 
                            {/* </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid container>
                            <Grid item lg={12} md={12} xs={12}>
                                <Typography variant='h2'>
                                    My Tickets
                                </Typography>
                                <Box bgcolor={'white'} mt={3} p={2} >
                                    <Typography variant='h3'>
                                        Incident(s)
                                    </Typography>
                                        <EnhancedTable
                                            headCells={TicketTypeIncidentHeadCell}
                                            rows={TicketTypeIncidenttableData}
                                            rowsPerPage={TicketTypeIncidentrowsPerPage}
                                            page={TicketTypeIncidentpage}
                                            handleChangePage={TicketTypeIncidenthandleChangePage}
                                            handleChangeRowsPerPage={TicketTypeIncidenthandleChangeRowsPerPage}
                                            handleRequestSort={handleTicketTypeIncidentRequestSort}
                                            handleSearch={handleSearchMyTicket}//SOP-92 Included By PCS [search in 'My Ticket' grid]
                                            order={TicketTypeIncidentorder}
                                            orderBy={TicketTypeIncidentorderBy}
                                            actionFunction={TicketTypeIncidentactionFunction}
                                            isView={true}
                                            isDelete={false}
                                            isEdit={false}
                                            total={TicketTypeIncidentcount}
                                            //SOP-92 Modified by PCS [to enable search function]
                                            // isSearch={false}
                                            isSearch={true}
                                            //EOF SOP-92 Modified by PCS
                                            HoverColor={'#E7F1FB'}
                                            />
                                </Box>
                            </Grid>
                        {/* //SOP-75 feedback points L1 Commented by PCS [to remove request grid from ticket grid]*/}
                            {/* <Grid item lg={12} md={12} xs={12}>
                                <Box bgcolor={'white'} mt={3} p={2} >
                                    <Typography variant='h3'>
                                       Request(s)
                                         
                                    </Typography>
                                    <EnhancedTable
                                        headCells={TicketTypeRequestHeadCell}
                                        rows={TicketTypeRequesttableData}
                                        rowsPerPage={TicketTypeRequestrowsPerPage}
                                        page={TicketTypeRequestpage}
                                        handleChangePage={TicketTypeRequesthandleChangePage}
                                        handleChangeRowsPerPage={TicketTypeRequesthandleChangeRowsPerPage}
                                        handleRequestSort={handleTicketTypeRequestRequestSort}
                                        order={AssetRequestorder}
                                        orderBy={AssetRequestorderBy}
                                        actionFunction={TicketTypeRequestactionFunction}
                                        isView={true}
                                        isDelete={false}
                                        isEdit={false}
                                        total={TicketTypeRequestcount}
                                        isSearch={false}
                                        HoverColor={'#E7F1FB'}
                                    />
                                </Box> 
                            </Grid> */}
                        {/* //EOF SOP-75 feedback points L1 commented by PCS */}
                        </Grid>
                    </Grid>
                         {/* //SOP-75 feedback points L1 included by PCS [increasing width of My asset grid]*/}

                               <Grid item lg={12} md={12} xs={12}>
    
                                <Box bgcolor={'white'} p={2} mt={3}>
                                    <Typography variant='h3'>
                                        {/* //SOP-75 feedback points L1 modified by PCS [renaming]*/}
                                        {/* My Properties */}
                                        My Assets
                                        {/* //EOF SOP-75 feedback points L1 modified by PCS [renaming]*/}
                                        
                                    </Typography>
                                    <EnhancedTable
                                        headCells={MyPropertyHeadCell}
                                        rows={UserAssettableData}
                                        rowsPerPage={UserAssetrowsPerPage}
                                        page={UserAssetpage}
                                        handleChangePage={UserAssethandleChangePage}
                                        handleChangeRowsPerPage={UserAssethandleChangeRowsPerPage}
                                        handleRequestSort={handleUserAssetSort}
                                        handleSearch={handleSearchMyAssets}//SOP-92 Included By PCS [search in 'My Assets' grid]
                                        order={UserAssetorder}
                                        orderBy={UserAssetorderBy}
                                        actionFunction={UserAssetactionFunction}
                                        //SOP-92 Modified by PCS [enable view icon]
                                        // isView={false}
                                           isView={true}
                                         //SOP-92 Modified by PCS 
                                        isDelete={false}
                                        isEdit={false}
                                        total={UserAssetcount}
                                        //SOP-92 Modified By PCS [enable search function]
                                       // isSearch={false}
                                          isSearch={true}
                                        //EOF SOP-92 Modified by PCS [enable search function]
                                        HoverColor={'#E7F1FB'}
                                        />
                                </Box>                                
                            
                                </Grid>
                    {/* //EOF SOP-75 feedback points L1 [increasing width of My asset grid]*/}



                       {/* (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - HR Grid - start */}
                       {/* Grid display who have user rights */}
                        {isHRRequestVisible && (
                            <Grid item lg={12} md={12} xs={12}>
                                <Typography variant='h2'>
                                {/* //SOP-75 feedback points L1 modified by PCS [renaming] */}
                                    {/* HR Hire Request */}
                                       Hire Request
                                {/* //EOF SOP-75 feedback points L1 modified by PCS [renaming] */}

                                </Typography>
                                <Grid container>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box bgcolor={'white'} p={2} mt={3}>
                                            <Typography variant='h3'>
                                             {/* My Hire Request (s) */}
                                            </Typography>
                                            <EnhancedTable
                                                headCells={HRHireRequestHeadCell}
                                                rows={HRHireRequesttableData}
                                                rowsPerPage={HRHireRequestrowsPerPage}
                                                page={HRHireRequestpage}
                                                handleChangePage={HRHireRequesthandleChangePage}
                                                handleChangeRowsPerPage={HRHireRequesthandleChangeRowsPerPage}
                                                handleRequestSort={handleHRHireRequestIncidentRequestSort}
                                                handleSearch={handleSearchHireRequests}//SOP-92 Included By PCS [search in 'Hire Requests' grid]
                                                order={HRHireRequestorder}
                                                orderBy={HRHireRequestorderBy}
                                                actionFunction={HRHireRequestactionFunction}
                                                isView={true}
                                                isDelete={false}
                                                isEdit={false}
                                                total={HRHireRequestcount}
                                                //SOP-92 Modified By PCS [to enable search function]
                                                // isSearch={false}
                                                isSearch={true}
                                                //EOF SOP-92 Modified By PCS
                                                HoverColor={'#E7F1FB'}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {/* (SOP-65) HR - Hire Request - Request form to fill the hire details by HR or Manager - HR Grid - end */}

                </Grid>
                </Box> 

                {/* Included by sanjana SOP-26  to call raise a ticket page*/}
                    {/* Add Ticket dialog box */}
                    <Box>
                    
                        {/* //SOP-49 - TS - commented - start
                            // Asset Management-Ticketing-Create Page */}
                        
                            {/* <Dialog  open={AddTicketOpen}  >
                                <RaiseATicketPage 
                                handleAddTicketClose={handleAddTicketClose}
                                getTicketlistsearch={getTicketTypeIncidentList} 
                                getTicketTypeRequestList={getTicketTypeRequestList}
                                />
                            </Dialog> */}
                           {/* <RaiseATicketPage/> */}
                        
                        {/* //SOP-49 - TS - commented - end */}
                    
                    </Box>
                    {/* End of Add Ticket dialog box */}


                
                {/* SOP-55 - TS  commented - start*/}
                {/* // Asset Management-Ticketing-View and Edit Page */}
                    {/* Edit Ticket dialog box */}
                    {/* <Box>
                        <Dialog  open={EditTicketOpen} >
                            <EditRaiseATicketPage 
                            handleEditTicketClose={handleEditTicketClose}
                            getTicketlistsearch={getTicketTypeIncidentList} 
                            getTicketTypeRequestList={getTicketTypeRequestList}
                            TicketId={EditTicketId}  
                            />
                        </Dialog>
                    </Box>
                    {/* End of Edit Ticket */}

                    {/* View Ticket dialog box */}
                     {/* <Box>
                        <Dialog  open={ViewTicketOpen}  >
                            <ViewRaiseATicketPage 
                                handleViewTicketClose={handleViewTicketClose}
                                handleEditTicketOpen={handleEditTicketOpen} TicketId={ViewTicketId} 
                            />
                        </Dialog>
                </Box> */}
                
                {/* SOP-55 - TS  commented - end*/}
                
            {/* End of View Ticket */}
                {/* End of SOP-26 */}
        
        </Box>             
    </MainContainerWrapper>
    )
}

export default HomePage;