// Included by sanjana SOP-15 Asset Management - Masters -Categories Master
//SOP-22 Page modified By PCS[for chip alignment]
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    InputAdornment,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem,
    Grid,
    Paper,
    Divider,
    Tooltip,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import MainContainerWrapper from '../../atic-common-helpers/layout/wrappers/MainContainerWrapper';
import { BiSolidCategory } from "react-icons/bi";
import { MdCategory } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// Included by sanjana SOP-40 imports
import { TbDatabaseImport } from "react-icons/tb";
import { RiMailSettingsFill } from "react-icons/ri";
import { FaPeopleRoof } from "react-icons/fa6";
import { BsPersonCheck } from "react-icons/bs";
// End of SOP-40

// Included by sanjana SOP-46 imports
import { BiImageAdd } from "react-icons/bi";
// End of SOP-46

// Included by sanjana SOP-56 imports
import { VscTypeHierarchySub } from "react-icons/vsc";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
// End of SOP-56

//SOP-75 feedback points L1 inlcuded By PCS
import { BiSolidLayer } from "react-icons/bi";

//SOP-88 Included By PCS [View User page rights]
import { FaUserCheck } from "react-icons/fa";

//SOP-97 Included by PCS [Ticketing -Masters]
import { FaCodeBranch } from "react-icons/fa";
import { LuGitBranchPlus } from "react-icons/lu";
import { FaPhoneAlt } from "react-icons/fa";
import { FaExclamationCircle } from "react-icons/fa";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { TiTick } from "react-icons/ti";
import { FaCircleInfo } from "react-icons/fa6";
//EOF SOP-97 Included by PCS [Ticketing -Masters]


const BackOfficePage =()=>{

    // Use Navigate
    const navigate = useNavigate();

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    ////console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId;
    let UserId = GlobalData.GlobalUserId;

    // Included by sanjana SOP-56 
    // use state
    const [HierarchyCreateDataLength, setHierarchyCreateDataLength] = useState(Number);
    const [DeleteConfirmationBoxopen, setDeleteConfirmationBoxOpen] = React.useState(false);
    
    // to open delete box
    const handleDeleteConfirmationBoxOpen = () => {
      setDeleteConfirmationBoxOpen(true);
    };
  
    // to close delete box
    const handleDeleteConfirmationBoxClose = () => {
      setDeleteConfirmationBoxOpen(false);
    };

    // if already hierarcy is exist it will throw a alert and
    // with confirmation it will navigate to create new hierarchy 
    // else navigate to create new hierarchy
    const handleHierarchyConfiguration = () =>{

        // console.log("HierarchyCreateDataLength",HierarchyCreateDataLength)

        if(HierarchyCreateDataLength > 0){
            handleDeleteConfirmationBoxOpen()
        }
        else{
            navigate('/add_hierarchy_page_step1')
        }
    }
    
    // To get Hierarchy details as per the id
    const GetHierarchyListById = () => {
        commonService
          .postService(`${process.env.REACT_APP_BACKEND_URL}/hrhierarchy/get_hr_hierarchy_list_by_id`, {
            CompanyId: CompanyId,
          })
          .then((res) => {
            const FormatedHierarchyData = res.data.data;
            // console.log("FormatedHierarchyData",FormatedHierarchyData)
            setHierarchyCreateDataLength(res.data.data.length)
          })
          .catch((error) => {
            Failed(error.response.message);
          });
      };
    
    // to delete hierarchy 
    const HierarchyDelete =()=>{

        const HierarchyDeletePayload ={
            CompanyId:CompanyId
        }

        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/hrhierarchy/hr_hierarachy_delete`, HierarchyDeletePayload)
        .then((res) => {
          if (res.status === 200) {
              navigate('/add_hierarchy_page_step1')
          }
          }).catch((error) => {
              console.error("Error:", error);
          });
    }

        // UseEffect declaration
        useEffect(() => {
            GetHierarchyListById();
          }, []);

    // End of SOP-56

// Design start
    return(
        <>
        <MainContainerWrapper headingText='Back Office'>
            <Box>
            <Box display={'flex'} justifyContent={'center'} >
                <Box bgcolor={'white'} p={3}  borderRadius={3}
                sx={{
                    height:'auto',
                    width:{
                        lg:1500,
                        md:500,
                        xs:700
    
                    }
                }}>
                    {/* Asset  */}
                    <Box >
                        {/* Included by sanjana SOP-40  to add heading*/}
                        <Box>
                            <Typography variant='h2'>
                                Asset
                            </Typography>
                        </Box>
                        {/* End of SOP-40 */}

                        <Grid container mt={2}>

                            {/* Card one Categories */}
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/categories')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                    // Modified by sanjana SOP-40
                                                    // height:50, width:50,
                                                    height:40, width:52,
                                                    // End of SOP-40
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                    <BiSolidCategory style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#4169e1',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Categories
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>
                            {/* End of Card one */}

                            {/* Card two Sub-Categories */}                                                        
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#F6F3FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/sub_categories')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                    // Modified by sanjana SOP-40
                                                    // height:50, width:50,
                                                    height:40, width:52,
                                                    // End of SOP-40
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#D9D3FF'}>
                                                    <MdCategory style={{height:30, width:30, color:'#7D6BCB', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#7D6BCB',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Sub-Categories
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>                                
                            {/* End of Card two */}

            {/* //SOP-75 feedback points L1 included by PCS [Including Assets here]*/}
                               {/* Card three Assets */}
                               <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/assets')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                   height:40, width:52,
                                                    
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                    <BiSolidLayer style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#4169e1',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Assets
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>
                           {/* End of Card three */}
            {/* //EOF SOP-75 feedback points L1 included by PCS */}

                            {/* Included by sanjana SOP-40 Card four Import Asset */}   
                            <Tooltip title='Bulk import of assetes from CSV file'>
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E2F6E9'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/import_asset_csv')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                    height:40, width:52,
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#C3E8D1'}>
                                                    <TbDatabaseImport style={{height:30, width:30, color:'#348D50', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#348D50',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Import Asset
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>       
                            </Tooltip>                                                     
                            {/* End of SOP-40 Card four */}   

                        </Grid>
                    </Box>
                    {/* End of Asset  */}

                    {/* Included by sanjana SOP-40 */}
                    <Divider sx={{mt:3, border:'0.1px solid grey'}}/>
                    {/* End of SOP-40 */}
                    

                    {/* Employee  */}
                    <Box mt={3} >
                        {/* Included by sanjana SOP-40 */}
                        <Box>
                            <Typography variant='h2'>
                                Employee
                            </Typography>
                        </Box>
                        {/* End of SOP-40 */}

                        {/* SOP-20 Included By PCS  [Department]*/}
                        <Grid container mt={2}>

                            {/* Card one department */}
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/department')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                    // Modified by sanjana SOP-40
                                                    // height:50, width:50,
                                                    height:40, width:52,
                                                    // End of SOP-40
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                    <FaPeopleRoof style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#4169e1',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Department
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>
                            {/* End of Card one */}

                            {/* SOP-21 Included By PCS [Designation]*/}
                            {/* Card two designation */}                                                        
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#F6F3FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/designation')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                    // Modified by sanjana SOP-40
                                                    // height:50, width:50,
                                                    height:40, width:52,
                                                    // End of SOP-40
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#D9D3FF'}>
                                                    <BsPersonCheck style={{height:30, width:30, color:'#7D6BCB', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#7D6BCB',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Designation
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>                                
                            {/* End of Card two */}
  
                         {/* SOP-88 Included By PCS [View User Page rights]*/}
                            {/*  View User page Rights */}                                                        
                            <Grid item  lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={260} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/view_user_pagerights')
                                }}
                                >
                                        <Box>
                                            <Box ml={1} width={260} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                    height:40, width:40,
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                    <FaUserCheck style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                    </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#4169e1',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                      View User page rights
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>   
                            {/* End of Card View User page Rights */}
                         {/* EOF SOP-88 Included By PCS [View User page rights]*/}

                      </Grid>
                        {/* EOF SOP-20 Included By PCS */}
                    </Box>
                    {/* End of Employee  */}

                    {/* Included by sanjana SOP-40 */}
                    <Divider sx={{mt:3, border:'0.1px solid grey'}}/>
                    {/* End of SOP-40 */}

                    {/* Configuration  */}
                    <Box mt={3} >
                    {/* Included by sanjana SOP-40 */}
                    <Box>
                        <Typography variant='h2'>
                            {/* Modified by sanjana SOP-46 to rename as Company Configuration */}
                            {/* Configuration */}
                            Company Configuration
                            {/* End of SOP-46 */}
                        </Typography>
                    </Box>
                    {/* End of SOP-40 */}

                    {/* SOP-22 Included By PCS [Email Configuration Company]*/}

                        <Grid container mt={2}>

                            {/* Card one Email Configuration Company */}
                            <Grid item  lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/email_configuration_company')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                    // Modified by sanjana SOP-40
                                                    // height:50, width:50,
                                                    height:40, width:52,
                                                    // End of SOP-40
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                    <RiMailSettingsFill style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#4169e1',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Email
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>
                            {/* End of Card one */}

                            {/* Included by sanjana SOP-46  */}
                            {/* Card two upload company logo */}                                                        
                            <Grid item  lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={260} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#F6F3FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/upload_company_logo')
                                }}
                                >
                                        <Box>
                                            <Box ml={1} width={260} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                    height:40, width:40,
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#D9D3FF'}>
                                                    <BiImageAdd style={{height:30, width:30, color:'#7D6BCB', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#7D6BCB',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                      Upload Company Logo  
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>                                
                            {/* End of Card two */}
                            {/* End of SOP-46 */}
      
                            
                            {/* Included by sanjana SOP-56 Card three Hierarchy Configuration */} 
                                {/* //SOP-77 feedback points Commented by PCS [removing Hierarchy configuration] */}
                                {/* <Grid item lg={2.5} md={12} sx={{mt:{
                                    lg:0,
                                    md:3,
                                    xs:3
                                },
                                ml:{
                                    lg:8,
                                    md:0,
                                    xs:0
                                }
                                }} display={'flex'} >
                                    <Box width={260} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E2F6E9'}
                                    sx={{
                                        '&:hover':{
                                            boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                            , cursor:'pointer'
                                        }
                                    }}
                                    onClick={()=>{
                                        // navigate('/add_hierarchy_page_step1')
                                        handleHierarchyConfiguration();
                                    }}
                                    >
                                            <Box>
                                                <Box ml={2} width={260} display={'flex'} justifyContent={'center'}>
                                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                        height:40, width:52,
                                                    '&:hover':{
                                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                    }
                                                    }} mt={0.5} borderRadius={100} bgcolor={'#C3E8D1'}>
                                                        <VscTypeHierarchySub style={{height:30, width:30, color:'#348D50', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                    </Box>
                                                    <Box ml={1} width={260}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                        <Typography
                                                        sx={{
                                                            fontSize:{
                                                                lg:'1rem',  
                                                                md:'1rem',
                                                                xs:'1rem'
                                                            },
                                                            color:'#348D50',
                                                            fontWeight:'bold'
                                                        }}
                                                        >
                                                            Hierarchy Configuration
                                                        </Typography>
                                                    </Box>

                                                </Box>
                                            </Box>
                                        </Box>
                                </Grid>      */}
                                {/* //EOF SOP-77 feedback points Commented by PCS [removing Hierarchy configuration]   */}
                            {/* Dialouge box to get conformation to delete */}

                            <Box>
                            <Dialog
                            open={DeleteConfirmationBoxopen} >
                                <Box p={3}>
                                    <Typography fontSize={'1.2rem'} display={'flex'} justifyContent={'center'} alignItems={'center'}  >
                                    It will clear the existing hierarchy chart,
                                    </Typography>
                                    <Typography fontSize={'1.2rem'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                                    Do you want to continue?
                                    </Typography>
                                </Box>

                                <Box p={3}>
                                <Box mt={0} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Button
                                        sx={{
                                            background: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                    
                                            },
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
            
                                        onClick={()=>{   
                                            handleDeleteConfirmationBoxClose();                             
                                        }} 
                                    >
                                        No
                                    </Button>
            
                                    <Button
                                        sx={{
                                            background: '#25425F',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding:{
                                                lg: "0 70px",
                                                md: "0 70",
                                                xs:'0 50px'
                                            },
                                            "&:hover":{
                                                backgroundColor:'#25425F',
                                                color:'white',
                                            },
                                            color: 'white',
                                            height: "3rem",
                                            boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                                        }}
                                        type='submit'
                                        onClick={()=>{
                                            HierarchyDelete()                                            
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </Box>
                                </Box>
                                
                            </Dialog>

                            </Box>

                            {/* End of SOP-56 Card three */} 

                            {/* SOP-23 Included By PCS [Email Configuration Intopz]*/}
                            {/* Card two Email Configuration Intopz */}                                                        
                            {/* <Grid item lg={4} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={300} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#F6F3FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                    navigate('/email_configuration_intopz')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={300} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                    // height:50, width:50,
                                                    height:40, width:52,
                                                '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#D9D3FF'}>
                                                    <RiMailSettingsFill style={{height:30, width:30, color:'#7D6BCB', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={300}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#7D6BCB',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Email Configuration Intopz
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>                                 */}
                            {/* End of Card two */}
                            {/* SOP-23 Included By PCS */}

  
                        </Grid>
                    {/* SOP-22 Included By PCS */}

                    </Box>
                    {/* End of Configuration  */}

                {/* //SOP-97 Included By PCS [Ticketing Masters] */}
                    <Divider sx={{mt:3, border:'0.1px solid grey'}}/>
                    <Box mt={3} >
                        <Box>
                            <Typography variant='h2'>
                                Ticketing
                            </Typography>
                        </Box>
                       
                    <Grid container mt={2}>
                        {/* Card one categories */}
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                  navigate('/ticketing_categories')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                  height:40, width:52,
                                                  '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                <FaCodeBranch  style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#4169e1',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Categories
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>
                        {/* End of Card one */}

                        {/* Card two sub-categories */}
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                  navigate('/ticketing_sub_categories')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                  height:40, width:52,
                                                  '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                <LuGitBranchPlus style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#4169e1',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Sub-Categories
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>
                        {/* End of Card two */}

                        {/* Card three contact type */}
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                  navigate('/ticketing_contact_type')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                  height:40, width:52,
                                                  '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                <FaPhoneAlt style={{height:23, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#4169e1',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                        Contact Type
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>
                        {/* End of Card three */}

                        {/* Card Four priority */}
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                lg:0,
                                md:3,
                                xs:3
                            }}} display={'flex'} >
                                <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                sx={{
                                    '&:hover':{
                                        boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                        , cursor:'pointer'
                                    }
                                }}
                                onClick={()=>{
                                  navigate('/ticketing_priority')
                                }}
                                >
                                        <Box>
                                            <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                  height:40, width:52,
                                                  '&:hover':{
                                                    boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                }
                                                }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                <FaExclamationCircle  style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                </Box>
                                                <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography
                                                    sx={{
                                                        fontSize:{
                                                            lg:'1rem',  
                                                            md:'1rem',
                                                            xs:'1rem'
                                                        },
                                                        color:'#4169e1',
                                                        fontWeight:'bold'
                                                    }}
                                                    >
                                                      Priority
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                            </Grid>
                        {/* End of Card Four */}
                    </Grid>

                    <Grid container mt={2}>
                        {/* Card five impact */}
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                        lg:0,
                                        md:3,
                                        xs:3
                                    }}} display={'flex'} >
                                        <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                        sx={{
                                            '&:hover':{
                                                boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                                , cursor:'pointer'
                                            }
                                        }}
                                        onClick={()=>{
                                        navigate('/ticketing_impact')
                                        }}
                                        >
                                                <Box>
                                                    <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                        height:40, width:52,
                                                        '&:hover':{
                                                            boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                        }
                                                        }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                        <HiQuestionMarkCircle style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                        </Box>
                                                        <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                            <Typography
                                                            sx={{
                                                                fontSize:{
                                                                    lg:'1rem',  
                                                                    md:'1rem',
                                                                    xs:'1rem'
                                                                },
                                                                color:'#4169e1',
                                                                fontWeight:'bold'
                                                            }}
                                                            >
                                                            Impact
                                                            </Typography>
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            </Box>
                            </Grid>
                        {/* End of Card five */}
                       
                        {/* Card six Closure information */}
                            <Grid item lg={2.5} md={12} sx={{mt:{
                                        lg:0,
                                        md:3,
                                        xs:3
                                    }}} display={'flex'} >
                                        <Box width={200} height={50} display={'flex'} justifyContent={'center'} borderRadius={50} bgcolor={'#E5F6FF'}
                                        sx={{
                                            '&:hover':{
                                                boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
                                                , cursor:'pointer'
                                            }
                                        }}
                                        onClick={()=>{
                                        navigate('/ticketing_closure_information')
                                        }}
                                        >
                                                <Box>
                                                    <Box ml={2} width={200} display={'flex'} justifyContent={'center'}>
                                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                                                        height:40, width:52,
                                                        '&:hover':{
                                                            boxShadow:'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                                        }
                                                        }} mt={0.5} borderRadius={100} bgcolor={'#B7E6FF'}>
                                                        {/* <BiSolidCategory style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                     */}
                                                        <FaCircleInfo  style={{height:30, width:30, color:'#4169e1', display:'flex', justifyContent:'center', alignItems:'center'}} />                                    
                                                        
                                                        
                                                        </Box>
                                                        <Box ml={1} width={200}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                                            <Typography
                                                            sx={{
                                                                fontSize:{
                                                                    lg:'1rem',  
                                                                    md:'1rem',
                                                                    xs:'1rem'
                                                                },
                                                                color:'#4169e1',
                                                                fontWeight:'bold'
                                                            }}
                                                            >
                                                            Closure Information
                                                            </Typography>
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            </Box>
                            </Grid>
                        {/* End of seven  */}
                    </Grid>
                    </Box>
               

                {/* //EOF SOP-97 Included by PCS [Ticketing Masters] */}
                    
                </Box>
            </Box>
            </Box>
        


        </MainContainerWrapper>
        
        </>
    )
// Design end

}

export default BackOfficePage;
// End of SOP-15