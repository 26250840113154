// SOP-97 Included by PCS [Ticketing Masters - Priority]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
   
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import Swal from "sweetalert2";

// Page start
const AddTicketingPriority = 
// To get handleAddPriorityClose & getprioritylistsearch from priority  page
    ({
        handleAddPriorityClose,
        getprioritylistsearch
    }: {
        handleAddPriorityClose: (event: React.MouseEvent) => void,
        getprioritylistsearch:  (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

    // SOP-97 Inlcuded By PCS
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    //console.log("GlobalData",GlobalData)
    let CompanyId = GlobalData.GlobalCompanyId;
    const UserId = GlobalData.GlobalUserId;

    const [PriorityError, setPriorityError]=useState(false)
    const [PriorityValue, setPriorityValue]=useState('')
    const [PriorityErrorMessage, setPriorityErrorMessage]=useState('')

    //[to avoid duplicate Priority type name]
      const [PriorityValueEndTrim, setPriorityValueEndTrim]=useState('')
    

    //  Handle Priority  while typing Priority  and validations
     const handlePriorityChange = (event:any) => {
         const trimmedPriorityValue = event.target.value.trim();
         setPriorityValue(event.target.value);

         //[to avoid duplicate Priority name]
         setPriorityValueEndTrim(trimmedPriorityValue);
        
        if (trimmedPriorityValue === '') {
            setPriorityError(true);
            setPriorityErrorMessage("Priority is required");
                                           } 
        else if( trimmedPriorityValue.length >255 ){
            setPriorityError(true);
            setPriorityErrorMessage("Max 255 characters");
                                                      }
        else {
            setPriorityError(false);
            setPriorityErrorMessage("");
             }
                                                  };

    // Exist Priority  name API
    const GetPriorityNameExist = async () =>
   {   
        
        const  ExistPriorityNamePayload = {
            ExistPriorityName:PriorityValueEndTrim,
            PriorityId:null,
            CompanyId:CompanyId //SOP-97 Included By PCS
                                         }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_priority_name_exists`,ExistPriorityNamePayload);
        //console.log(res.data.data);
        return res.data.data || [];
           } 
        catch (err) {
        console.log( "co",err); 
                   }
    };


    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddPriorityClose && typeof handleAddPriorityClose === 'function') {
            handleAddPriorityClose(event);
        }
    };

    // to render getprioritylistsearch function after closing dialouge box
    const handlegetprioritylistsearch= (event: React.MouseEvent) => {
        if (getprioritylistsearch && typeof getprioritylistsearch === 'function') {
            getprioritylistsearch(event);
        }
    };

    // To add Priority type

     // Define a type for errors
     type Errors = {
        priority?:boolean;
    };
    const HandleAddPriority = async (event:any)=>
    {
         event.preventDefault();
         let errors: Errors = {};

            const PriorityNameList = await GetPriorityNameExist();
            if (PriorityNameList.length > 0) {
                setPriorityError(true);
                setPriorityErrorMessage("Priority already exists");
                errors.priority = true;
                return;
                                            }
        
            const trimmedPriorityValue = PriorityValue.trim();
            if (trimmedPriorityValue === '') {
                errors.priority = true;
                setPriorityErrorMessage("Priority is required");
            } 
            else if (trimmedPriorityValue.length > 255) {
                errors.priority = true;
                setPriorityErrorMessage("Max 255 characters");
            }
    
            if (Object.keys(errors).length > 0) {
                // If there are errors, update the state with all the errors
                setPriorityError(errors.priority || false);
                return;
                                            }
    
        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Year}-${Month}-${Day}`;
       // console.log("FormattedDate",FormattedDate)
    
        const AddPriorityPayload = {
             SaveUpdateKey: "create",
             PriorityName: PriorityValue,
             PriorityId: null,
            //SOP-97 Included By PCS
            CompanyId:CompanyId,
            Active: "Y",
            CreatedDateTime: null,
            CreatedBy: UserId,
            IsRemove: null
            //EOF SOP-97 Included By PCS
                                 }
    
        //console.log("Add payload", AddPriorityPayload);

          Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor:"#25425f",
                allowOutsideClick: false,
                })
                .then((result) =>
                {
                    if (result.isConfirmed)
                    {
                    commonService
                            .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_priority_create_update`, AddPriorityPayload)
                            .then((res) => {
                            if (res.status === 200) {
                                Success("Priority created successfully");
                                handleClose(event);
                                handlegetprioritylistsearch(event);
                            }
                            }).catch((error) => {
                                console.error("Error:", error);
                            });
                    } 
                    else  
                    {
                    return
                    }
                });

       
    }
   
    // UseEffect Declaration
    useEffect(() => {
         }, []);
     
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => 
    {
    if (event.key === 'Enter') {
        event.preventDefault();
                                }
    };

    return(
        <>
         <Box>
        <Box display={'flex'} justifyContent={'center'}>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:900,
                    md:600,
                    xs:700

                }}}
            >
            <Box p={3}>
                <Typography variant='h1'>
                Add Ticketing Priority
                </Typography>
            </Box>
            <Box p={3}>
            <form   noValidate>
            <Box>
            <TextField
                onKeyDown={handleKeyDown} 
                autoFocus
                type='text'
                variant="standard"
                label='Priority'
                name='Priority'
                style={{ margin: '0 10px' }}
                fullWidth
                required
                error={PriorityError}
                onChange={handlePriorityChange}
                value={PriorityValue}
                helperText={PriorityErrorMessage}
            /> 
            </Box>
            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Button
                sx={{
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding:{
                        lg: "0 70px",
                        md: "0 70",
                        xs:'0 50px'

                    },
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                onClick={handleClose} 
            >
            Back
            </Button>
            <Button
                sx={{
                    background: '#25425F',
                    border: 'none',
                    borderRadius: '5px',
                    padding:{
                        lg: "0 70px",
                        md: "0 70",
                        xs:'0 50px'

                    },
                    "&:hover":{
                        backgroundColor:'#25425F',
                        color:'white',
                    },
                    color: 'white',
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                    type='submit'

                onClick={(event)=>{
                    HandleAddPriority(event);
                }}
                >
                    Save
                </Button>
                </Box>
            </form>
            </Box>
            </Box>
            </Box>
            </Box>
           
        </>
    )

}

export default AddTicketingPriority;

// EOF SOP-97 Included by PCS [Ticketing Masters - Priority]