
// SOP-96 Included by PCS [HireRequest Approval Configuration]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
    FormHelperText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Dayjs } from 'dayjs';
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";

const HireRequestApprovalHirearchy = () => {
        
    const navigate = useNavigate();
    const {id} = useParams();
    const DepartmentId = id

    //Employee List
    const [EmployeeListOptions, setEmployeeListOptions] = useState<OptionType[]>([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>('');
    // console.log('EmployeeListOptions',EmployeeListOptions)
    const [draggedEmployees, setDraggedEmployees] = useState<any[]>([]); // State for drag and drop employees
    // console.log('draggedEmployees',draggedEmployees)
    const [EmployeeError, setEmployeeError]=useState(false)
    const [EmployeeErrorMessage, setEmployeeErrorMessage]=useState('')

    //Department Name
    const [DepartmentName, setDepartmentName]=useState('')
     
    type OptionType = {
        name: string;
        value: string;
    };
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

   // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    const CompanyId = GlobalData.GlobalCompanyId;
    const UserId = GlobalData.GlobalUserId;
    const UserName = GlobalData.GlobalUserName;

    //Employee List dropdown
    const GetEmployeeList = async () =>
        {
         const EmployeeLisPayload ={
                CompanyId:CompanyId,
                active:"Y"}
           
            try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/getEmployeeListwithDesignation`,EmployeeLisPayload);
                let formattedEmployeeList = res.data.data.map((item:any) => {
                const { employee_id, employeenamewithdesignation } = item;
                return {
                    name: employeenamewithdesignation,
                    value: employee_id,
                };
                });
                setEmployeeListOptions(formattedEmployeeList)
            } 
            catch (err) {
                console.log( "co",err);
                }
        };

    //To get department name from Departmentid
    const getDepartmentById = async () => 
        {
        const DepartmentListPayload ={
        DepartmentId:DepartmentId,
        CompanyId:CompanyId
                }
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/master/get_department_list_by_id`,DepartmentListPayload);
            setDepartmentName(res.data.data[0].departmentname)
            } 
            catch (err) {
            console.log( "co",err);
            }
        };
    
   // To remove an employee from the list 
    const handleRemoveEmployee = (index: number) => {
        const updatedEmployees = [...draggedEmployees]; // Make a shallow copy of the array

        // Check if there is only one employee in the list before removing
            if (updatedEmployees.length === 1) {
            Swal.fire({
                icon: 'warning',
                text: 'At least one employee should remain in the approval level!',
                confirmButtonColor: '#25425f',
            });
            return; 
            }
        updatedEmployees.splice(index, 1); // Remove 1 item at the specified index
        setDraggedEmployees(updatedEmployees); // Update state with the modified array
    };

    // Handle Drag and Drop End
    const onDragEnd = (result: any) => {
        const { destination, source } = result;
        if (!destination) return;
        const reorderedEmployees = [...draggedEmployees];
        const [movedEmployee] = reorderedEmployees.splice(source.index, 1);
        reorderedEmployees.splice(destination.index, 0, movedEmployee);
        setDraggedEmployees(reorderedEmployees);
    };

    //Departmentwise - Hire Request approval hierarchy levels
    const getDepartmentHierarchyLevel = async () => 
     {
        const Payload = {
            CompanyId: CompanyId,
            DepartmentId: DepartmentId,
            };
        try {
                const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_department_hirerequest_hirearchyapproval`, Payload);
                const hierarchyData = res.data.data;
                // Sort the hierarchy by level_id to ensure correct order
                console.log('hierarchyDatafrom resposne',hierarchyData)
                const sortedHierarchy = hierarchyData.sort((a:any, b:any) => a.level_id - b.level_id);
                console.log('sortedHierarchy ',sortedHierarchy)
                // Map the sorted data into draggedEmployees array
                const employees = sortedHierarchy.map((item:any) => ({
                    value: item.employee_id,  
                    name: item.employeename_with_designation,  
                }));
                setDraggedEmployees(employees);  // Set the state with the employees array
             }
        catch (error) {
            console.error("Error fetching department hierarchy:", error);
                      }
    };

    useEffect(() => {
        GetEmployeeList();
        getDepartmentById();
        getDepartmentHierarchyLevel(); //to get hierarchy level for department
    
    }, []); 

   //To  save the hierarchy level starts ..
    const SaveHierarchyLevel =async ()=>
    {
        // the data from 'draggedEmployees' array is sent as array of objects
        const hierarchyPayload = 
            {
            data: draggedEmployees.map((employee, index) => ({
                SaveUpdateKey: 'create',
                CompanyId: CompanyId,
                DepartmentId: DepartmentId,
                LevelId: index + 1, // Level ID starts at 1
                EmployeeId: employee.value,
                CreatedBy: UserId,
            }))
            };

            console.log('hierarchyPayload',hierarchyPayload)
        try {
                Swal.fire({
                    html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    cancelButtonColor: '#d33',
                    confirmButtonColor:"#25425f",
                    allowOutsideClick: false,
                    })
                .then((result) =>
                {
                    if (result.isConfirmed)
                    {
                        commonService.postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/create_update_hirerequest_approval_hierarchy`,hierarchyPayload).then((res) => {
                            if (res.status === 200) {
                            Success("Hierarchy approval level updated successfully")
                            navigate('/department');
                        }})
                       .catch((error) => {
                        console.error("Error:", error);
                                          })
                    }
                    else  
                    {
                        return
                    }
                });
            } 
            catch (error:any) 
            {
            console.error('Error during API call:', error.response ? error.response.data.message : error.message);
            }
    }
   //EOF To  save the hierarchy level ends ..


    return (
        <Box sx={{ padding: { xs: 2, sm: 3, md: 4 }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ width: { xs: '100%', sm: '100%', lg: '100%' },backgroundColor: 'white', borderRadius: 3, padding: 3 }}>
            <Typography variant="h3" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
             Approval Hierarchy Level - Department [{DepartmentName}] 
            </Typography>
            <Grid container spacing={2} mt={1.5} justifyContent="left">
             {/* Employee Dropdown */}
                <Grid item mt={2} ml={4} xs={12} sm={6} md={6} lg={3}>
                    <Autocomplete
                    id="employee-select"
                    options={EmployeeListOptions as OptionType[]}
                    filterOptions={filterOptions}
                    getOptionLabel={(option: unknown) => (option as OptionType).name}
                    value={EmployeeListOptions.find((option: OptionType) => option.value === selectedEmployeeId) || null}
                    onChange={(event, newValue) => {
                    if (newValue)
                    {
                        // Check if the employee is already in the draggedEmployees list
                        const isDuplicate = draggedEmployees.some(employee => employee.value === newValue.value);
                        if (isDuplicate) {
                            Swal.fire({
                                icon: 'warning',
                                text: 'Employee already added',
                                    })
                                        } 
                        else {
                            setSelectedEmployeeId(newValue.value);
                            setEmployeeError(false);
                            setEmployeeErrorMessage('');
                            setDraggedEmployees(prev => [...prev, newValue]); // Add the new employee
                            }
                    }
                    else 
                    {
                    setSelectedEmployeeId('');
                    // setEmployeeError(true);
                    // setEmployeeErrorMessage('Employee is required');
                    }
                }}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    error={EmployeeError}
                    helperText={EmployeeErrorMessage}
                    required
                    label="Employee"
                    variant="standard"
                    fullWidth
                    />
                )}
                />
            </Grid>
            {/* Approval Levels (Drag and Drop Section) */}
                <Grid item xs={12} mt={2} ml={4} sm={6} md={6} lg={4} justifyContent="right" sx={{ border: '1px solid #ccc', borderRadius: 2, padding: 2 }}>
                <Typography variant="h3" color={'#2f4f4f'} gutterBottom textAlign={'center'}>Approval Levels</Typography>
                <Box sx={{ height: 300, overflowY: 'auto' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="approvalLevels" direction="vertical">
                        {(provided) => (
                            <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            display="flex"
                            justifyContent="flex-start"
                            sx={{
                                flexWrap: 'wrap',
                                flexDirection:"column" ,
                                gap: 2,
                                width: '100%',
                            }}
                            >
                        {draggedEmployees.map((employee, index) => (
                            <Draggable key={employee.value} draggableId={employee.value.toString()} index={index}>
                            {(provided) => (
                                <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                         backgroundColor: '#e0e0e0',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                        width:  '100%' , 
                                        alignItems: 'center',
                                        cursor: 'move',
                                        transition: 'transform 0.2s ease'
                                    }}
                                    >
                                    <div style={{display:'flex'}}>
                                    {/* Add Level label based on the index */}
                                    <Typography variant="body2" sx={{ marginTop:0.5}} >
                                    Level   {index + 1}   -
                                    </Typography>
                                    <Typography sx={{ marginLeft: 1,marginTop:0.75}} variant="body1">
                                    {employee.name}
                                    </Typography>
                                    {/* Delete button */}
                                    <CloseIcon
                                        onClick={() => handleRemoveEmployee(index)}
                                            sx={{ color: 'red', padding: 0 ,marginLeft: 1,marginTop:0.5}}
                                    >
                                    </CloseIcon>
                                      </div>
                                    </Box>
                               )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            </Box>
                        )}
                        </Droppable>
                        
                    </DragDropContext>
                    </Box>
                </Grid>
                <Grid item xs={12} mt={2} ml={4} sm={6} md={6} lg={8} justifyContent="right" >

                 {/* Only show the note when there are at least 2 employees */}
                {draggedEmployees.length >= 2 && (
                        <Box>
                        <Typography variant="body2" sx={{ color: 'blue', marginTop: 1 }}>
                        <span style={{ fontWeight: 400 }}>Note</span>  :  To re-arrange the order, drag and move the position to the desired level
                        </Typography>
                        </Box>
                         )}
                </Grid>
            </Grid>
        </Box>
        
       
        <Grid container 
            justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start' }}
           mt={2}>
            {/* Back Button */}
            <Grid  item  xs={12} sm={12} md={6} lg={2} >
                <Button
                  sx={{
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: { xs: '0 50px', sm: '0 70px' },
                    height: '3rem',
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
                    width: '100%',
                  }}
                  onClick={() => {
                    navigate('/department');
                  }}
                >
                  Back
                </Button>
            </Grid>
            <Grid  item  xs={12} sm={12} md={6} lg={6} ></Grid>
            {/* Add Button */}
            <Grid justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-end' }}
               item xs={12} sm={12} md={6} lg={2} >
                <Button
                  sx={{
                    background: '#25425F',
                    borderRadius: '5px',
                    padding: { xs: '0 50px', sm: '0 70px' },
                    height: '3rem',
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
                    color: 'white',
                    width: '100%',
                    "&:hover": {
                      backgroundColor: '#25425F',
                      color: 'white',
                    }
                  }}
                  type="submit"
                  onClick={() => {
                     SaveHierarchyLevel();
                   
                  }}
                >
                  Save
                </Button>
            </Grid>
        </Grid>


        </Box>
       
      )
}
export default HireRequestApprovalHirearchy;

//SOP-96 Hire request Approval Hirearchy Configuration
