// SOP-76 feedback points L2 (7) included By PCS Hire Request (Approvals dialog box)
// Imports
import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
    Dialog,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import deleteAlert from '../../component/HOC/deleteAlert';
import dayjs from 'dayjs';
//SOP-96 Included by PCS
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";


const HireRequestApprovalsPage=
// To get handleApproveApprovalsPageClose & getMyPendingApprovalsList from catgories page
({
    handleApproveApprovalsPageClose,
    getMyPendingApprovalsList,
    getMyApprovedList,
    HireRequestId
}: {
    handleApproveApprovalsPageClose: (event: React.MouseEvent) => void,
    getMyPendingApprovalsList: () => void,
    getMyApprovedList: () => void,
    HireRequestId:any
})=>{

    //Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId
    const UserId = GlobalData.GlobalUserId;
    let EmployeeId = GlobalData.GlobalEmployeeId
    let GlobalUserName = GlobalData.GlobalUserName   //SOP-96 Included By PCS

  
    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleApproveApprovalsPageClose && typeof handleApproveApprovalsPageClose === 'function') {
             handleApproveApprovalsPageClose(event);
            }
    };

     // to render getMyPendingApprovalsList function after approving request
     const handlegetMyPendingApprovalsList= () => {
        if (getMyPendingApprovalsList && typeof getMyPendingApprovalsList === 'function') {
            getMyPendingApprovalsList();
        }
    };

    // to render getMyApprovedList function after approving request
    const handlegetMyApprovedList= () => {
        if (getMyApprovedList && typeof getMyApprovedList === 'function') {
            getMyApprovedList();
        }
    };

//SOP-96 Included By PCS [Multi Level Approval]

   const navigate = useNavigate();

   // Head cells of HireRequestApprovals
   const HireRequestApprovalsHeadCell = [
    {
        id: "level_id",
        numeric: false,
        disablePadding: true,
        label: "Level",
        isSort: true,
        isBold: true,
    },        
    {
        id: "employee_name",
        numeric: false,
        disablePadding: false,
        label: "Approving Authority",
        isSort: true,
        isBold: true,
   },
   {
        id: "departmentname",
        numeric: false,
        disablePadding: true,
        label: "Department",
        isSort: true,
        isBold: true,
    },
    {
        id: "approval_status",
        numeric: false,
        disablePadding: false,
        label: "Approval status",
        isSort: true,
        isBold: true,
    },
   {
        id: "approval_remarks",
        numeric: false,
        disablePadding: false,
        label: "Remarks",
        isSort: true,
        isBold: true,
       
    },];

    const [cells, setCells] = useState<any>(HireRequestApprovalsHeadCell);

    let  [HireRequestsApprovalsorder, setHireRequestsApprovalsOrder] = React.useState<"desc" | "asc">("desc");
    let  [HireRequestsApprovalsorderBy, setHireRequestsApprovalsOrderBy] = React.useState("");
    let  [HireRequestsApprovalstableData, setHireRequestsApprovalstableData] = React.useState([]);
    const [HireRequestsApprovalspage, setHireRequestsApprovalspage] = React.useState<number>(0);
    const [HireRequestsApprovalLevelCount, setHireRequestsApprovalLevelCount] = useState<number>(0);
    const [HireRequestsApprovalsrowsPerPage, setHireRequestsApprovalsrowsPerPage] = React.useState<number>(10);
    const [SaveButtondisable, setSaveButtondisable] = useState(true);

//To display in header
    const [JobProfile, setJobProfile] = useState('');
    const [Department, setDepartment] = useState('');
    const [RequestedBy, setRequestedBy] = useState('');
    const [HiringManager, setHiringManager] = useState('');
    const [TypeOfEmployment, setTypeOfEmployment] = useState('');

//To get details on page load  
    const [EmployeeLevelId, setEmployeeLevelId] = useState<number>(0);//to get Employee id of the logged in employee
    const [MaxLevel, setMaxLevel] = useState<number>(0);//to get max level for the request
    const [EmployeeStatus, setEmployeeStatus] = useState(''); //to get logged in employee  status
    const [PreviousLevelStatus, setPreviousLevelStatus] = useState(''); //to get previous level status

    // use state for Approve Status Fields
    const [ApproveRemarksError, setApproveRemarksError]=useState(false)
    const [ApproveRemarksValue, setApproveRemarksValue]=useState('')
    const [ApproveRemarksErrorMessage, setApproveRemarksErrorMessage]=useState('')
    const [ApproveOptions, setApproveOptions] = useState<OptionType[]>([]);
    const [selectedApproveId, setSelectedApproveId] = useState<any | null>('');
    const [ApproveError, setApproveError]=useState(false)
    const [ApproveErrorMessage, setApproveErrorMessage]=useState('')
    
    type OptionType = {
        name: string;
        value: string;
    };

    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

//To get Request details in header
    const getHireRequestDetailsByIdforHeader =() => 
    {       
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/get_hire_request_approved_list_by_id`, {
            CompanyId:CompanyId,
            RequestId:HireRequestId
                            })
        .then((res: any) => 
        {
        const HireRequestDetails =res.data.data[0]
        console.log("Hire Request Headign details ..",HireRequestDetails[0] )
            setJobProfile(HireRequestDetails.hirerequest_hiring_jobcode_title)
            setDepartment(HireRequestDetails.departmentname)
            setRequestedBy(HireRequestDetails.fullname)
            setHiringManager(HireRequestDetails.hiringmanager)
            setTypeOfEmployment(HireRequestDetails.sys_hr_employment_type_name)
        })
    };
  
//To get Request details  in Grid with all levels..
    const GetHireRequestApprovalHierarchy = () => 
        {       
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_hirerequest_id_all_approval_levels`, {
                HireRequestId:HireRequestId,
                CompanyId:CompanyId,
                SortColumnName: HireRequestsApprovalsorderBy,
                SortColumnDirection: HireRequestsApprovalsorder,
                StartIndex: HireRequestsApprovalspage + 1,
                PageSize: HireRequestsApprovalsrowsPerPage,
            })
            .then((res: any) => 
            {
             const formattedTableData= res.data.data.map((item:any)=>{
            return {...item,
                id:item.hirerequest_id,
            }
            }) 
            //  console.log("HireRequests Multi Level Approval Grid ..",res.data.data)

            //SOP-96 [to get  details of the request]
            const hireRequests = res.data.data; 
            // To Find the maximum level_id 
            const maxLevel = Math.max(...hireRequests.map((item: any) => item.level_id));
            setMaxLevel(maxLevel)
            if (hireRequests) 
            {
                const employeeRequest = hireRequests.find((request: any) => request.employee_id === EmployeeId);
                if (employeeRequest) 
                {
                    // To get current level ID and status
                    const EmployeeLevel = employeeRequest.level_id;
                    const EmployeeStatus = employeeRequest.approval_status;
                    setEmployeeLevelId(EmployeeLevel);
                    setEmployeeStatus(EmployeeStatus)

                    // If EmployeeLevel is 1, check EmployeeStatus,
                    if (EmployeeLevel === 1) 
                    {
                            // if Approved or Rejected disable the button
                            if (EmployeeStatus === 'Approved' || EmployeeStatus === 'Rejected') {
                            setSaveButtondisable(true); 
                            }
                            // Pending enable  the button 
                            else if (EmployeeStatus === 'Pending') 
                            {
                            setSaveButtondisable(false);  // Enable the button if status is Pending
                            }
                    } 
                    //Employee level other than 1
                    else{
                            // check EmployeeStatus and PrevLevelStatus
                            if (EmployeeStatus === 'Pending')
                            {
                                // Find the previous level request
                                const previousLevelRequest = hireRequests.find((request: any) => request.level_id === EmployeeLevel - 1);
                                if (previousLevelRequest)
                                {
                                    const PrevLevelStatus = previousLevelRequest.approval_status;
                            // If PrevLevelStatus is 'Pending, rejected or Not Requested' disable the button
                                    if (PrevLevelStatus === 'Pending' || PrevLevelStatus === 'Rejected' || PrevLevelStatus === 'Not Requested') {
                                        setSaveButtondisable(true); 
                                    }
                                // If PrevLevelStatus is 'Approved' enable the button
                                    else if (PrevLevelStatus === 'Approved') {
                                        setSaveButtondisable(false);  // Enable button if previous level status is Approved
                                    }
                                }
                                else 
                                {
                                    console.log(`No request found for previous level (level ${EmployeeLevel - 1})`);
                                }
                            }
                        }
                }            
                
            }
            //EOF SOP-96 
            setHireRequestsApprovalstableData(formattedTableData);
            if(res.data.data[0]===undefined)
            {
            setHireRequestsApprovalLevelCount(0);
            }
            else
            {
            setHireRequestsApprovalLevelCount(res.data.data[0].total_count);
            }
            })
        .catch((error) => 
            {
            if (error.response.status === 404)
            {
                setHireRequestsApprovalstableData([]);
            }
            Failed(error.response.message);
            });
        };
   
// Pagination Handler of table
    const HireRequestsApprovalshandleChangePage = (event: unknown, newPage: number) => {
            setHireRequestsApprovalspage(newPage);
        };
        
// Rows Per page Handler
    const HireRequestsApprovalshandleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
            ) => {
                setHireRequestsApprovalsrowsPerPage(+event.target.value);
                setHireRequestsApprovalspage(0);
        };

// Sort Handler
    const handleHireRequestsApprovalsSort = (event: any, property: any) =>
            {
            const isAsc = HireRequestsApprovalsorderBy === property && HireRequestsApprovalsorder === "asc";
            HireRequestsApprovalsorder = isAsc ? "desc" : "asc";
            setHireRequestsApprovalsOrder(HireRequestsApprovalsorder);
            HireRequestsApprovalsorderBy = property;
            setHireRequestsApprovalsOrderBy(HireRequestsApprovalsorderBy);
            };
              
// Action function of table icons
    const HireRequestsApprovalsactionFunction = (id: string, actionText: string) => 
        {

            console.log('id is..',id)
            // if (actionText === "Approve") {
            //      handleApproveApprovalsPageOpen(id)
            // };

        };

// To get Approve sts list to fill ApproveStatus drop down
    const GetApproveList = async () => { 
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/get_hirerequest_approve_status_list`);
            // console.log("Approve 123 " ,res.data.data);
            let formattedApprove = res.data.data.map((item:any) => {
            const { sys_approve_status_id, sys_approve_status_name } = item;
            return {
                name: sys_approve_status_name,
                value: sys_approve_status_id,
            };
            });
            setApproveOptions(formattedApprove)
        } catch (err) {
            //console.log( "co",err);
        }
        };

// Define a type for errors
        type Errors = {
        approveSts?:boolean;
        apprveRemarks?:boolean;
        };

// Handle Approve while typing Approve and validations
    const handleApproveRemarksChange = (event:any) => {
    setApproveRemarksValue(event.target.value);
    const trimmedApproveRemarksValue =  event.target.value
    if( trimmedApproveRemarksValue.length !== 255 ){
        setApproveRemarksError(false);
        setApproveRemarksErrorMessage("");
    }
    };

// To validate Approve Requests in all levels
    const ApproveHireRequestLevels =(event:any)=> 
    {
            event.preventDefault()
            let errors: Errors = {};
            if (selectedApproveId === '' || selectedApproveId === null || selectedApproveId === undefined) {
                    errors.approveSts = true;
                    setApproveErrorMessage("Approve Status is required")
                    setApproveError(true)
                }
            
            const trimmedApproveRemarksValue = ApproveRemarksValue.trim()
            if( trimmedApproveRemarksValue.length >255 ){
                setApproveRemarksError(true);
                setApproveRemarksErrorMessage("Max 255 characters");
            }
            else {
                setApproveRemarksError(false);
                setApproveRemarksErrorMessage("");
            }

        let ApproveShortName;
            if(selectedApproveId === 1){
                ApproveShortName= 'Approved'
            }
        if(selectedApproveId === 2){
                ApproveShortName= 'Rejected'
            }
        if (Object.keys(errors).length > 0) {
                // If there are errors, update the state with all the errors
                setApproveError(errors.approveSts || false);
                setApproveRemarksError(errors.apprveRemarks || false);
                return;
            }
            const HireRequestApprovalPayload={
                SaveUpdateKey: "update",
                CompanyId: CompanyId,
                HireRequestId:HireRequestId,
                LevelId:EmployeeLevelId,
                ApprovalStatus: ApproveShortName, 
                ApprovalRemarks: ApproveRemarksValue,
            }
            // console.log('HireRequestApprovalPayload',HireRequestApprovalPayload)
            commonService
            .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/set_hr_hirerequest_approval_details`,HireRequestApprovalPayload)
            .then((res) => {
                if (res.status === 200)
                {
                    Success("Hire Request Approval Status Updated Successfully");
                    // To call 'ApproveHireRequest' on request gets reject or when the max level is reached

                    if (EmployeeLevelId === MaxLevel || HireRequestApprovalPayload.ApprovalStatus === 'Rejected') {
                          ApproveHireRequest(HireRequestApprovalPayload.ApprovalStatus); 
                        }

                    // If the approval status is "Approve", update the next level's status to "Pending"
                        // This updates the next level status to 'Pending'
                        if (selectedApproveId === 1) {
                            updateNextLevelStatus(EmployeeLevelId); 
                        }
                        handleClose(event);         
                
                }
            }).catch((error) => {
                console.error("Error Hire Request Approval:", error);
            });
    }

//To Update next level's staus
    const updateNextLevelStatus = (currentLevelId:number) => 
    {
       // Find the next level id in the HireRequestsApprovalstableData
        const nextLevelId = currentLevelId + 1; // Assuming levels are sequential
        const nextLevel = HireRequestsApprovalstableData.find(
            (item: any) => item.level_id === nextLevelId
        );
        if (nextLevel) 
        {
            // Update the next level's approval status to 'Pending'
            commonService
                .postService(`${process.env.REACT_APP_BACKEND_URL}/HRNewHireRequest/set_hr_hirerequest_approval_details`, {
                    SaveUpdateKey: "update",
                    CompanyId: CompanyId,
                    HireRequestId: HireRequestId,
                    LevelId: nextLevelId,
                    ApprovalStatus: 'Pending', 
                    
                })
                .then((response) => {
                    console.log('Next level status updated to Pending:', response);
                })
                .catch((error) => {
                    console.error('Error updating next level status:', error);
                });
        }
    };

//Final approving after all levels approved
const ApproveHireRequest =(ApprovalStatus:any)=>
{
     // event.preventDefault()
    let errors: Errors = {};
     const HireRequestApprovalPayload={
             SaveUpdateKey: "update",
            CompanyId: CompanyId,
            RequestId:HireRequestId,
            RequestApproveStatus: ApprovalStatus==='Approved'?'A':'R', 
            RequestApproveByEmployeeId:null,
            RequestApproveRemarks: '',
            RequestStatusId:ApprovalStatus==='Approved'?3:2}
     commonService
    .postService(`${process.env.REACT_APP_BACKEND_URL}/myapprovals/my_approval_hire_request_update`, HireRequestApprovalPayload)
    .then((res) => {
      if (res.status === 200) {
        Success("Final Status Updated Successfully");

        //navigate('/myapprovals')
        

        // handleClose();
        handlegetMyPendingApprovalsList();
        handlegetMyApprovedList();
        
      }
      }).catch((error) => {
          console.error("Error Hire Request Approval:", error);
      });
}

//Use Effect
    useEffect(() => {
        GetApproveList();//SOp-96 Included By PCS
        GetHireRequestApprovalHierarchy();//SOP-96 Includedd By PCS
        getHireRequestDetailsByIdforHeader();//SOP-96 Included By PCS
    }, [HireRequestsApprovalsrowsPerPage,HireRequestsApprovalspage,HireRequestsApprovalsorderBy,HireRequestsApprovalsorder]); 


//SOP-96 Modified By PCS [Existing design changed- for multi level approval]
return(<>
<Box>
{/* //Header Section starts*/}
<Box  bgcolor={'white'}sx={{width:'95%',marginTop:'4%',marginLeft:'4%'}}>

{/* //Header Section */}
<div style={{display: 'flex', flexDirection: 'row'}}>
<Grid mb={2} 
    container
    spacing={{ xs: 2, md: 2 }}
    columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
    rowSpacing={{ xs: 2, md: 1 }}
    columnSpacing={{ xs: 2, md: 3 }}
>

<Grid item xs={8} sm={8} md={8} lg={1.5}>
<div style={{display: 'flex', flexDirection: 'column'}}>

  <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word',fontWeight: 'bold',fontSize: {lg: '1.1rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
    Request Id
  </Typography>
  <Typography sx={{  wordWrap: 'break-word',
        overflowWrap: 'break-word',fontSize: {lg: '1.0rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
    {HireRequestId}
  </Typography>
</div>
  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={2}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word',  fontWeight: 'bold',fontSize: {lg: '1.1rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
      Job Profile
    </Typography>
    <Typography sx={{fontSize: {lg: '1.0rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black',
        wordWrap: 'break-word',
        overflowWrap: 'break-word'}}>
      {JobProfile}
    </Typography>
  </div>

  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={1.5}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word',fontWeight: 'bold',fontSize: {lg: '1.1rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
      Department
    </Typography>
    <Typography sx={{  wordWrap: 'break-word',
        overflowWrap: 'break-word', fontSize: {lg: '1.0rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
      {Department}
    </Typography>
  </div>
  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={2}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography sx={{  wordWrap: 'break-word',
        overflowWrap: 'break-word',fontWeight: 'bold', fontSize: {lg: '1.1rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
      Requested By
    </Typography>
    <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word', fontSize: {lg: '1.0rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
      {RequestedBy}
    </Typography>
  </div>
  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={2}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
  <Typography sx={{  wordWrap: 'break-word',
        overflowWrap: 'break-word',fontWeight: 'bold',fontSize: {lg: '1.1rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
    Hiring Manager
  </Typography>
  <Typography sx={{  wordWrap: 'break-word',
        overflowWrap: 'break-word',fontSize: {lg: '1.0rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
    {HiringManager}
  </Typography>
  </div>
  </Grid>
  <Grid item xs={8} sm={8} md={8} lg={2.5}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
  <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word',fontWeight: 'bold', fontSize: {lg: '1.1rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'#002984'}}>
    Type Of Employment
  </Typography>
  <Typography sx={{ wordWrap: 'break-word',
        overflowWrap: 'break-word',fontSize: {lg: '1.0rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>
    {TypeOfEmployment}
  </Typography>
</div>
  </Grid>
</Grid>
</div>
</Box>
{/* //Header Section Ends*/}

{/* //Grid Section starts*/}
<Box sx={{marginTop:'4%',marginLeft:'3%'}}>
        <EnhancedTable
            headCells={HireRequestApprovalsHeadCell}
            rows={HireRequestsApprovalstableData}
            rowsPerPage={HireRequestsApprovalsrowsPerPage}
            page={HireRequestsApprovalspage}
            handleChangePage={HireRequestsApprovalshandleChangePage}
            handleChangeRowsPerPage={HireRequestsApprovalshandleChangeRowsPerPage}
            handleRequestSort={handleHireRequestsApprovalsSort}
            order={HireRequestsApprovalsorder}
            orderBy={HireRequestsApprovalsorderBy}
            actionFunction={HireRequestsApprovalsactionFunction}
            isView={false}
            isDelete={false}
            isEdit={false}
            total={HireRequestsApprovalLevelCount}
            isSearch={false}
            HoverColor={'#E7F1FB'}
            />
</Box>
{/* //Grid Section ends*/}

{/* Validation Section starts */}     
<Typography sx={{marginLeft:'3%',fontweight:'bold',fontSize: {lg: '1.1rem', md: '0.9rem', xs: '0.75rem'}, textAlign: 'left', color:'black'}}>Approval Level for 
<span style={{ color: '#002984' }}> <b>{GlobalUserName}</b> </span>        
</Typography>
<form noValidate>
<Box p={3} sx={{marginLeft:'4%'}}>
<Grid container >
    <Grid item ml={2} mt={2} lg={3} md={12} xs={12} >
        <Autocomplete
            id="combo-box-demo"
            options={ApproveOptions as OptionType[]}
            filterOptions={filterOptions}
            getOptionLabel={(option: unknown) => (option as OptionType).name}
            value={ApproveOptions.find((option: OptionType) => option.value === selectedApproveId) || null}
            onChange={(event, newValue) => {
            if (newValue) {
                setSelectedApproveId(newValue.value);
                setApproveError(false);
                setApproveErrorMessage("");
            } else {
                setSelectedApproveId('');
                setApproveError(true);
                setApproveErrorMessage("Approve status is required");
            }
        }}
        renderInput={(params) => 
        <TextField {...params}
        error={ApproveError}
        autoFocus
        helperText={ApproveErrorMessage}
        required label="Approve Status" 
        variant="standard" />}
        />
    </Grid>
    <Grid item ml={2} mt={3} lg={3.5} md={12} xs={12} >
        <TextField 
            id="outlined-basic" 
            label="Remarks" 
            variant="outlined" 
            name='ApproveRemarks'
            fullWidth
            multiline
            rows={3}
            error={ApproveRemarksError}
            onChange={handleApproveRemarksChange}
            value={ApproveRemarksValue}
            helperText={ApproveRemarksErrorMessage}                                           
            />
    </Grid>
    <Grid item ml={2} mt={3} lg={4} md={12} xs={12} >
     <Button
            sx={{
                backgroundColor: SaveButtondisable ? '#D3D3D3': (PreviousLevelStatus !== 'A' ? '#25425F' : '#D3D3D3'),  // Color when button is enabled
                border: 'none',
                borderRadius: '5px',
                padding:{
                    lg: "0 80px",
                    md: "0 80",
                    xs:'0 50px'},
                "&:hover":{
                    backgroundColor:'#25425F',
                    color:'white',
                },
                color: 'white',
                height: "3.5rem",
                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
            }}
            type='submit'
            onClick={(event)=>{
                ApproveHireRequestLevels(event);
            }}
            disabled={SaveButtondisable}
        >
        Save
     </Button>
    </Grid>
</Grid>
</Box>
</form>
{/* Validation Section ends*/}   
{/* Back button Section */}   
<Grid container >
<Grid item  ml={4} mt={1.5} lg={3} md={3} xs={3} >
<Box>
    <Button
    sx={{
    marginBottom:'20px',
    background: 'white',
        border: 'none',
        borderRadius: '5px',
        padding:{
            lg: "0 70px",
            md: "0 70",
            xs:'0 50px'},
        height: "3rem",
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    }}
    onClick={handleClose} 
>
    Back
    </Button>
</Box>
{/* Back button Section ends*/}  
</Grid>
</Grid>
</Box>
</> )
{/* //EOF SOP-96 Modified By PCS [Existing design changed- multi level approval] */}
   
}
export default HireRequestApprovalsPage;
// EOF SOP-76 feedback points  L2 (7) Included By PCS - Hire Request (Approvals dialog box)