// SOP-97 Included by PCS [Ticketing Masters- Impact]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
   
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete , { createFilterOptions }  from '@mui/material/Autocomplete';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Success } from '../../atic-common-helpers/helpers/toast.helper';
import Swal from "sweetalert2";


// Page start
const AddTicketingImpact = 
// To get handleAddImpactClose & getimpactlistsearch from impact  page
    ({
        handleAddImpactClose,
        getimpactlistsearch
    }: {
        handleAddImpactClose: (event: React.MouseEvent) => void,
        getimpactlistsearch:  (event: React.MouseEvent) => void
    }) =>{

    // use navigate and use state declaration
    const navigate = useNavigate();

    // SOP-97 Inlcuded By PCS
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId;
    const UserId = GlobalData.GlobalUserId;


    const [ImpactError, setImpactError]=useState(false)
    const [ImpactValue, setImpactValue]=useState('')
    const [ImpactErrorMessage, setImpactErrorMessage]=useState('')

    //[to avoid duplicate Impact type name]
      const [ImpactValueEndTrim, setImpactValueEndTrim]=useState('')
    

    //  Handle Impact  while typing Impact  and validations
     const handleImpactChange = (event:any) => {
         const trimmedImpactValue = event.target.value.trim();
         setImpactValue(event.target.value);

         //[to avoid duplicate Impact name]
         setImpactValueEndTrim(trimmedImpactValue);
        
        if (trimmedImpactValue === '') {
            setImpactError(true);
            setImpactErrorMessage("Impact is required");
                                           } 
        else if( trimmedImpactValue.length >255 ){
            setImpactError(true);
            setImpactErrorMessage("Max 255 characters");
                                                      }
        else {
            setImpactError(false);
            setImpactErrorMessage("");
             }
                                                  };

    // Exist Impact  name API
    const GetImpactNameExist = async () =>
    {   
        const  ExistImpactNamePayload = {
            ExistImpactName:ImpactValueEndTrim,
            ImpactId:null,
            CompanyId:CompanyId //SOP-97 Included By PCS
                                         }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_impact_name_exists`,ExistImpactNamePayload);
        return res.data.data || [];
           } 
        catch (err) {
        console.log( "co",err); 
                   }
    };


    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleAddImpactClose && typeof handleAddImpactClose === 'function') {
            handleAddImpactClose(event);
        }
    };

    // to render getimpactlistsearch function after closing dialouge box
    const handlegetimpactlistsearch= (event: React.MouseEvent) => {
        if (getimpactlistsearch && typeof getimpactlistsearch === 'function') {
            getimpactlistsearch(event);
        }
    };

    // To add Impact type
        // Define a type for errors
     type Errors = {
        impact?:boolean;
    };

    const HandleAddImpact = async (event:any)=>
    {
         event.preventDefault();
         let errors: Errors = {};

            const ImpactNameList = await GetImpactNameExist();
            if (ImpactNameList.length > 0) {
                setImpactError(true);
                setImpactErrorMessage("Impact already exists");
                errors.impact = true;
                return;
                                            }
        
            const trimmedImpactValue = ImpactValue.trim();
            if (trimmedImpactValue === '') {
                errors.impact = true;
                setImpactErrorMessage("Impact is required");
            } 
            else if (trimmedImpactValue.length > 255) {
                errors.impact = true;
                setImpactErrorMessage("Max 255 characters");
            }
    
            if (Object.keys(errors).length > 0) {
                // If there are errors, update the state with all the errors
                setImpactError(errors.impact || false);
                return;
                                            }
    
        const todayDate = new Date();
        const Year = todayDate.getFullYear();
        const Month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
        const Day = todayDate.getDate().toString().padStart(2, '0');
        const FormattedDate = `${Year}-${Month}-${Day}`;
       // console.log("FormattedDate",FormattedDate)
    
        const AddImpactPayload = {
             SaveUpdateKey: "create",
             ImpactName: ImpactValue,
             ImpactId: null,
            //SOP-97 Included By PCS
            CompanyId:CompanyId,
            Active: "Y",
            CreatedDateTime: null,
            CreatedBy: UserId,
            IsRemove: null
            //EOF SOP-97 Included By PCS
                                 }
    
        //console.log("Add payload", AddImpactPayload);

          Swal.fire({
                html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                cancelButtonColor: '#d33',
                confirmButtonColor:"#25425f",
                allowOutsideClick: false,
                })
                .then((result) =>
                {
                    if (result.isConfirmed)
                    {
                        commonService
                        .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_impact_create_update`, AddImpactPayload)
                        .then((res) => {
                        if (res.status === 200) {
                            Success("Impact created successfully");
                            handleClose(event);
                            handlegetimpactlistsearch(event);
                        }
                        }).catch((error) => {
                            console.error("Error:", error);
                        });
                    } 
                    else  
                    {
                        return
                    }
                });

       
    }
   
    // UseEffect Declaration
    useEffect(() => {
         }, []);
     
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => 
        {
            if (event.key === 'Enter') {
                event.preventDefault();
                                        }
        };

    return(
        <>
         <Box>
        <Box display={'flex'} justifyContent={'center'}>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:900,
                    md:600,
                    xs:700

                }}}
            >
            <Box p={3}>
                <Typography variant='h1'>
                Add Ticketing Impact
                </Typography>
            </Box>
            <Box p={3}>
            <form  noValidate>
            <Box>
            <TextField
                onKeyDown={handleKeyDown} 
                autoFocus
                type='text'
                variant="standard"
                label='Impact'
                name='Impact'
                style={{ margin: '0 10px' }}
                fullWidth
                required
                error={ImpactError}
                onChange={handleImpactChange}
                value={ImpactValue}
                helperText={ImpactErrorMessage}
            /> 
            </Box>
            <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Button
                sx={{
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding:{
                        lg: "0 70px",
                        md: "0 70",
                        xs:'0 50px'

                    },
                    
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}

                onClick={handleClose} 
            >
            Back
            </Button>
            <Button
            sx={{
                background: '#25425F',
                border: 'none',
                borderRadius: '5px',
                padding:{
                    lg: "0 70px",
                    md: "0 70",
                    xs:'0 50px'

                },
                "&:hover":{
                    backgroundColor:'#25425F',
                    color:'white',
                },
                color: 'white',
                height: "3rem",
                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
            }}
                type='submit'

            onClick={(event)=>{
                HandleAddImpact(event);
            }}
            >
                Save
            </Button>
            </Box>
        </form>
        </Box>

        </Box>
        </Box>
        </Box>
           
        </>
    )

}

export default AddTicketingImpact;

// EOF SOP-97 Included by PCS [Ticketing Masters- Impact]