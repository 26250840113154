// SOP-97 Included by PCS [Ticketing Masters- Impact]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
   
} from '@mui/material';
import Typography from '@mui/material/Typography';

import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import './EditTicketingImpact.scss'
import Swal from "sweetalert2";


// Page start
const EditTicketingImpact = 
// To get handleEditImpactClose & getimpactlistsearch from impact page
({
    handleEditImpactClose,
    getimpactlistsearch,
    ImpactId
}: {
    handleEditImpactClose: (event: React.MouseEvent) => void,
    getimpactlistsearch: (event: React.MouseEvent) => void,
    ImpactId: any;
}) =>{

    //  use state declaration
    const [ImpactError, setImpactError]=useState(false)
    const [ImpactValue, setImpactValue]=useState('')
    const [ImpactErrorMessage, setImpactErrorMessage]=useState('')
    const [isActive, setIsActive] = useState(true); //SOP-97 Included By PCS

    //[ to avoid duplicate Impact type]
    const [ImpactValueEndTrim, setImpactValueEndTrim]=useState('')

    //SOP-97 Inlcuded By PCS
    const handleToggleActive = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };
    //EOF SOP-97 Included By PCS
   
 
    //  Handle Impact Type while typing Impact and validations
    const handleImpactChange = (event:any) => {
        const trimmedImpactValue = event.target.value.trim();
        setImpactValue(event.target.value);

         //[ to avoid duplicate Impact ]
         setImpactValueEndTrim(trimmedImpactValue);
        

        if (trimmedImpactValue === '') {
            setImpactError(true);
            setImpactErrorMessage("Impact is required");
        } 
        else if( trimmedImpactValue.length >255 ){
            setImpactError(true);
            setImpactErrorMessage("Max 255 characters");
        }
        else {
            setImpactError(false);
            setImpactErrorMessage("");
        }
    };


    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditImpactClose && typeof handleEditImpactClose === 'function') {
            handleEditImpactClose(event);
        }
    };


    // to render handlegetimpactlistsearch function after closing dialouge box
    const handlegetimpactlistsearch= (event: React.MouseEvent) => {
        if (getimpactlistsearch && typeof getimpactlistsearch === 'function') {
            getimpactlistsearch(event);
        }
    };

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId; //SOP-97 Included by PCS
    const UserId = GlobalData.GlobalUserId;//SOP-97 Included by PCS
   
  

    // To get Impact  details as per the id
    const GetImpactListById =()=>{
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_impact_list_by_id`, {
            ImpactId:ImpactId,
            CompanyId:CompanyId//SOP-97 Inlcuded By PCS
           
         })
         .then((res: any) => 
         { 
           // console.log("res",res)
            const ImpactData = res.data.data[0]
            setImpactValue(ImpactData.sys_impact_name)
            //SOP-97 Inlcuded By PCS
            if(ImpactData.active === 'Y'){
                setIsActive(true)
            }
            else{
                setIsActive(false)
            }
             //EOF SOP-97 Inlcuded By PCS
           
         }).catch((error) =>  
         { 
          Failed(error.response.message);
         });
    }

        // Exist Impact name API
        const GetImpactNameExist = async () => {   
        
            const  ExistImpactNamePayload = 
            {
               ExistImpactName:ImpactValueEndTrim,
               ImpactId:ImpactId,
               CompanyId:CompanyId //SOP-97 Included By PCS
      
            }
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_impact_name_exists`,ExistImpactNamePayload);
            // console.log(res.data.data);
            return res.data.data || [];
            } 
            catch (err) {
            console.log( "co",err); 
            }
        };


    // To Update Impact 

     // Define a type for errors
     type Errors = {
        impact?:boolean;
                  };
    const HandleUpdateImpact = async (event:any)=>
    {
        event.preventDefault();
    
        let errors: Errors = {};

        const ImpactNameList = await GetImpactNameExist();
        if (ImpactNameList.length > 0) {
            setImpactError(true);
            setImpactErrorMessage("Impact already exists");
            errors.impact = true;
            return;
        }
    
        const trimmedImpactValue = ImpactValue.trim();
        if (trimmedImpactValue === '') {
            errors.impact = true;
            setImpactErrorMessage("Impact is required");
        } else if (trimmedImpactValue.length > 255) {
            errors.impact = true;
            setImpactErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setImpactError(errors.impact || false);
            return;
        }

         //SOP-97 Inlcuded By PCS
         let EditActive ;
    
         if( isActive === true){
            EditActive = "Y"
         }
         else{
             EditActive='N'
         }
         //EOF SOP-97 Inlcuded By PCS
    
        const UpdateImpactPayload =
        {
             SaveUpdateKey: "update",
             ImpactName: ImpactValue,
             ImpactId: ImpactId,
             //SOP-97 Included by PCS
            CompanyId:CompanyId,
            Active: EditActive,
            LastModifiedBy: UserId,
            IsRemove: null
            //EOF SOP-97 Included by PCS
        }
    
        //console.log("Update Payload", UpdateImpactPayload);
    

           Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
            if (result.isConfirmed)
            {
                commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_impact_create_update`, UpdateImpactPayload)
                    .then((res) => {
                        if (res.status === 200) {
                            Success("Impact Updated successfully");
                            handleClose(event);
                            handlegetimpactlistsearch(event);
                        }
                        }).catch((error) => {
                            console.error("Error:", error);
                        });
            } 
            else  
            {
            return
            }
        });

    }
    
    // UseEffect declaration
    useEffect(() => {
        GetImpactListById();
      }, []); 
 
 const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

// Design start
    return(
    <Box>
    <Box>
    <Box display={'flex'} justifyContent={'center'}>
    <Box bgcolor={'white'} borderRadius={3}
    sx={{
        width:{
            lg:900,
            md:600,
            xs:700

        }}}
    >
    <Box p={3}>
        <Typography variant='h1'>
        Edit Ticketing Impact
        </Typography>
    </Box>
    <Box p={3}>
    <form  noValidate>
        <Grid container spacing={2}>
        <Grid item lg={6} md={6} xs={12}>
        <Box>
        <TextField
        onKeyDown={handleKeyDown}
        autoFocus
        type='text'
        variant="standard"
        label='Impact'
        name='Impact '
        style={{ margin: '0 10px' }}
        fullWidth
        required
        error={ImpactError}
        onChange={handleImpactChange}
        value={ImpactValue}
        helperText={ImpactErrorMessage}
        /> 
        </Box>
        </Grid>
        </Grid>

    {/*//SOP-97 Included By PCS  */}
        <Box mt={2} ml={1}>
        <Typography className="label_design" sx={{mt:1.5}}>
        <label style={{fontSize:'0.7rem'}}>Active State</label>
        </Typography>
        <label className="switchToggle">
        <input
            type="checkbox"
            checked={isActive}
            onChange={handleToggleActive} 
        />
        <span className="sliderToggle round"></span>
        </label>
        </Box> 
    {/* EOF SOP-97 Included By PCS  */}  
        <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Button
            sx={{
                background: 'white',
                border: 'none',
                borderRadius: '5px',
                padding:{
                    lg: "0 70px",
                    md: "0 70",
                    xs:'0 50px'
                },
                
                height: "3rem",
                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
            }}
            onClick={handleClose} 
            >
            Back
            </Button>

            <Button
            sx={{
                background: '#25425F',
                border: 'none',
                borderRadius: '5px',
                padding:{
                    lg: "0 70px",
                    md: "0 70",
                    xs:'0 50px'
                },
                "&:hover":{
                    backgroundColor:'#25425F',
                    color:'white',
                },
                color: 'white',
                height: "3rem",
                boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
            }}
            type='submit'
            onClick={(event)=>{
                HandleUpdateImpact(event);
            }}
            >
            Update
            </Button>
            </Box>
        </form>
    </Box>
    </Box>
    </Box>
    </Box>
    </Box>
    )
}


export default EditTicketingImpact;
// EOF SOP-97 Included by PCS [Ticketing Masters - Impact]