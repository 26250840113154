// SOP-97 Included by PCS [Ticketing Masters]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    TextField,
    Grid,
   
} from '@mui/material';
import Typography from '@mui/material/Typography';

import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import './EditTicketingContactType.scss'
import Swal from "sweetalert2";


// Page start
const EditTicketingContactType = 
// To get handleEditContactTypeClose & getcontactlistsearch from contact page
({
    handleEditContactTypeClose,
    getcontacttypelistsearch,
    ContactTypeId
}: {
    handleEditContactTypeClose: (event: React.MouseEvent) => void,
    getcontacttypelistsearch: (event: React.MouseEvent) => void,
    ContactTypeId: any;
}) =>{

    //  use state declaration
    const [ContactTypeError, setContactTypeError]=useState(false)
    const [ContactTypeValue, setContactTypeValue]=useState('')
    const [ContactTypeErrorMessage, setContactTypeErrorMessage]=useState('')
    const [isActive, setIsActive] = useState(true); //SOP-97 Included By PCS
    

    //[ to avoid duplicate contact type]
    const [ContactTypeValueEndTrim, setContactTypeValueEndTrim]=useState('')

    //SOP-97 Inlcuded By PCS
       const handleToggleActive = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };
    //EOF SOP-97 Included By PCS
 
    //  Handle Contact Type while typing Contact Type  and validations
    const handleContactTypeChange = (event:any) => {
        const trimmedContactTypeValue = event.target.value.trim();
        setContactTypeValue(event.target.value);

         //[ to avoid duplicate Contact Type ]
         setContactTypeValueEndTrim(trimmedContactTypeValue);
        

        if (trimmedContactTypeValue === '') {
            setContactTypeError(true);
            setContactTypeErrorMessage("Contact Type is required");
        } 
        else if( trimmedContactTypeValue.length >255 ){
            setContactTypeError(true);
            setContactTypeErrorMessage("Max 255 characters");
        }
        else {
            setContactTypeError(false);
            setContactTypeErrorMessage("");
        }
    };

    // To render handle close function to close dialouge box
    const handleClose = (event: React.MouseEvent) => {
        if (handleEditContactTypeClose && typeof handleEditContactTypeClose === 'function') {
            handleEditContactTypeClose(event);
        }
    };

    // to render handlegetcontacttypelistsearch function after closing dialouge box
    const handlegetcontacttypelistsearch= (event: React.MouseEvent) => {
        if (getcontacttypelistsearch && typeof getcontacttypelistsearch === 'function') {
            getcontacttypelistsearch(event);
        }
    };

    // Global data from session storage
    const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
    const GlobalData = JSON.parse(GlobalDataFromSession);
    let CompanyId = GlobalData.GlobalCompanyId; //SOP-97 Included by PCS
    const UserId = GlobalData.GlobalUserId;//SOP-97 Included by PCS
   

    // To get Contact Type  details as per the id
    const GetContactTypeListById =()=>{
        commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_contactType_list_by_id`, {
            ContactTypeId:ContactTypeId,
            CompanyId:CompanyId//SOP-97 Inlcuded By PCS
           
         })
         .then((res: any) => 
         { 
            //console.log("res",res)
            const ContactTypeData = res.data.data[0]
            setContactTypeValue(ContactTypeData.sys_contact_type_name)
            //SOP-97 Inlcuded By PCS
            if(ContactTypeData.active === 'Y'){
                setIsActive(true)
            }
            else{
                setIsActive(false)
            }
            //EOF SOP-97 Inlcuded By PCS
           
         }).catch((error) =>  
         { 
          Failed(error.response.message);
         });
    }

        // Exist Contact Type name API
        const GetContactTypeNameExist = async () => {   
        
            const  ExistContactTypeNamePayload = 
            {
               ExistContactTypeName:ContactTypeValueEndTrim,
               ContactTypeId:ContactTypeId,
               CompanyId:CompanyId //SOP-97 Included By PCS
            }
            try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_contactType_name_exists`,ExistContactTypeNamePayload);
            // console.log(res.data.data);
            return res.data.data || [];
            } 
            catch (err) {
            console.log( "co",err); 
            }
        };


    // To Update contact type

     // Define a type for errors
     type Errors = {
        contacttype?:boolean;
                  };
    const HandleUpdateContactType = async (event:any)=>
    {
        event.preventDefault();
    
        let errors: Errors = {};

        const ContactTypeNameList = await GetContactTypeNameExist();
        if (ContactTypeNameList.length > 0) {
            setContactTypeError(true);
            setContactTypeErrorMessage("Contact Type already exists");
            errors.contacttype = true;
            return;
        }
    
        const trimmedContactTypeValue = ContactTypeValue.trim();
        if (trimmedContactTypeValue === '') {
            errors.contacttype = true;
            setContactTypeErrorMessage("Contact type is required");
        } else if (trimmedContactTypeValue.length > 255) {
            errors.contacttype = true;
            setContactTypeErrorMessage("Max 255 characters");
        }
    
        if (Object.keys(errors).length > 0) {
            // If there are errors, update the state with all the errors
            setContactTypeError(errors.contacttype || false);
            return;
        }

         //SOP-97 Inlcuded By PCS
         let EditActive ;
    
         if( isActive === true){
            EditActive = "Y"
         }
         else{
             EditActive='N'
         }
         //EOF SOP-97 Inlcuded By PCS

    
        const UpdateContactTypePayload =
        {
             SaveUpdateKey: "update",
             ContactTypeName: ContactTypeValue,
             ContactTypeId: ContactTypeId,
              //SOP-97 Included by PCS
              CompanyId:CompanyId,
              Active: EditActive,
              LastModifiedBy: UserId,
              IsRemove: null
             //EOF SOP-97 Included by PCS
        }
        // console.log("Update Payload", UpdateContactTypePayload);
         Swal.fire({
            html: '<span style="font-size: 1.3rem;">Are you sure to save ? </span>',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#d33',
            confirmButtonColor:"#25425f",
            allowOutsideClick: false,
            })
        .then((result) =>
        {
            if (result.isConfirmed)
            {
                commonService
                    .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_contactType_create_update`, UpdateContactTypePayload)
                    .then((res) => {
                        if (res.status === 200) {
                            Success("Contact type Updated successfully");
                            handleClose(event);
                            handlegetcontacttypelistsearch(event);
                        }
                        }).catch((error) => {
                            console.error("Error:", error);
                        });
            } 
            else  
            {
            return
            }
      });

    }
    

    // UseEffect declaration
    useEffect(() => {
        GetContactTypeListById();
      }, []); 
 
 const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

// Design start
    return(
        <Box>
            <Box>
            <Box display={'flex'} justifyContent={'center'}>
            <Box bgcolor={'white'} borderRadius={3}
            sx={{
                width:{
                    lg:900,
                    md:600,
                    xs:700

                }}}
            >
            <Box p={3}>
                <Typography variant='h1'>
                Edit Ticketing Contact type
                </Typography>
            </Box>
            <Box p={3}>
            <form  noValidate>
                <Grid container spacing={2}>
                <Grid item lg={6} md={6} xs={12}>
                            <Box>
                                <TextField
                                    onKeyDown={handleKeyDown}
                                    autoFocus
                                    type='text'
                                    variant="standard"
                                    label='Contact Type'
                                    name='Contact Type'
                                    style={{ margin: '0 10px' }}
                                    fullWidth
                                    required
                                    error={ContactTypeError}
                                    onChange={handleContactTypeChange}
                                    value={ContactTypeValue}
                                    helperText={ContactTypeErrorMessage}
                                /> 
                                </Box>
                </Grid>
                </Grid>
                {/*//SOP-97 Included By PCS  */}
                <Box mt={2} ml={1}>
                <Typography className="label_design" sx={{mt:1.5}}>
                <label style={{fontSize:'0.7rem'}}>Active State</label>
                </Typography>
                <label className="switchToggle">
                <input
                    type="checkbox"
                    checked={isActive}
                    onChange={handleToggleActive} 
                />
                <span className="sliderToggle round"></span>
                </label>
                </Box> 
                {/* SOP-97 Included By PCS  */} 
                <Box mt={5} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Button
                sx={{
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding:{
                        lg: "0 70px",
                        md: "0 70",
                        xs:'0 50px'

                    },
                    
                    height: "3rem",
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                }}
                onClick={handleClose} 
                >
                 Back
                </Button>

                <Button
                    sx={{
                        background: '#25425F',
                        border: 'none',
                        borderRadius: '5px',
                        padding:{
                            lg: "0 70px",
                            md: "0 70",
                            xs:'0 50px'
                        },
                        "&:hover":{
                            backgroundColor:'#25425F',
                            color:'white',
                        },
                        color: 'white',
                        height: "3rem",
                        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
                    }}
                    type='submit'
                    onClick={(event)=>{
                        HandleUpdateContactType(event);
                    }}
                >
                    Update
                    
                </Button>
                </Box>
                </form>
                </Box>
                </Box>
                </Box>
            </Box>
        </Box>
    )
// Design end

}
// Page end

export default EditTicketingContactType;
// EOF SOP-97 Included by PCS [Ticketing Masters]