// SOP-97 Included by PCS [Ticketing Masters - Impact]

import { useState,useRef,useEffect } from 'react'; // Import useState hook
import React from 'react';
import {
    Box,
    Button,
    Dialog,
} from '@mui/material';

import { useNavigate } from "react-router-dom";
import MainContainerWrapper from "../../atic-common-helpers/layout/wrappers/MainContainerWrapper";
import AddTicketingImpact from './AddTicketingImpact';
import EnhancedTable from '../../atic-common-helpers/component/tables/selectableTable';
import { commonService } from '../../atic-common-helpers/helpers/common.service';
import { Failed, Success } from '../../atic-common-helpers/helpers/toast.helper';
import EditTicketingImpact from './EditTicketingImpact';
import deleteAlert from '../../component/HOC/deleteAlert';//SOP-97 Included By PCS


const TicketingImpact =()=>{

     // Use navigate
      const navigate = useNavigate();

       // useState
       let  [order, setOrder] = React.useState<"desc" | "asc">("desc");
       let  [orderBy, setOrderBy] = React.useState("");
       let  [tableData, setTableData] = React.useState([]);
       const [page, setPage] = React.useState<number>(0);
       const [count, setCount] = useState<number>(0);
       const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
       const [AddImpactOpen, setAddImpactOpen] = React.useState(false);
       const [EditImpactOpen, setEditImpactOpen] = React.useState(false);
       const [EditImpactId, setEditImpactId]= useState('')
      
      //  To open add dialouge box
          const handleAddImpactOpen = () => {
            setAddImpactOpen(true);
                                            };
    
        // To close add dialouge box
        
          const handleAddImpactClose = () => {
               setAddImpactOpen(false);
                                             };

        // To open edit dialouge box
       
          const handleEditImpactOpen = (id:any) => {
              setEditImpactOpen(true);
              setEditImpactId(id)
                                                    };
    
        // To close edit dialouge box
       
          const handleEditImpactClose = () => {
              setEditImpactOpen(false);
                                              };

    // Head cell of Impact type
     const ImpactHeadCell = [
        
          {
            id: "sys_impact_name",
            numeric: false,
            disablePadding: false,
            label: "Impact ",
            isSort: true,
            isBold: true,
          },
          //SOP-97 Included By PCS
         {
          id: "active",
          numeric: false,
          disablePadding: false,
          label: "Active",
          isSort: true,
          isBold: true,
          
        },
        //EOF SOP-97 Included By PCS
          
          {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Actions",
            isSort: false,
          },
       ];

      const [cells, setCells] = useState<any>(ImpactHeadCell);

      //  Global data from session storage
       const GlobalDataFromSession: any = sessionStorage.getItem("GlobalVariable");
       const GlobalData = JSON.parse(GlobalDataFromSession);
       let CompanyId = GlobalData.GlobalCompanyId;//SOP-97 Included By PCS
       const UserId = GlobalData.GlobalUserId;//SOP-97 Included By PCS
      

      //  To get Impact  list search API  
       const getimpactlistsearch = () => 
       {
             // Initializing Variable 
              let ImpactName = null;
              let bActiveState = ""; //SOP-97 Included by PCS
      
       for (const item of cells)
        {
            if (item.id === "sys_impact_name") {
              ImpactName = item.value;
                                               }
            //SOP-97 Included by PCS
                 if (item.id === "active") 
                  {
                    bActiveState = item.value;
                  }
              //EOF SOP-97 Included by PCS
        }

         commonService
         .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_impact_list_search`, {
            SearchImpact:ImpactName,
            SearchActive:  bActiveState, //SOP-97 Included by PCS
            SortColumnName: orderBy,
            SortColumnDirection: order,
            StartIndex: page + 1,
            PageSize: rowsPerPage,
            CompanyId:CompanyId //SOP-97 Included by PCS
          
         })
         .then((res: any) => 
         {
          // console.log("response is..",res)
         const formattedTableData= res.data.data.map((item:any)=>
          {
          return {...item,
                    id:item.sys_impact_id
                  }
          }) 
          // console.log("formattedTableData",formattedTableData)
          setTableData(formattedTableData);
          if(res.data.data[0]===undefined)
            {
              setCount(0);
            }
          else
            {
              setCount(res.data.data[0].total_count);
            }
         })
       .catch((error) => 
         {
           if (error.response.status === 404)
           {
             setTableData([]);
           }
          Failed(error.response.message);
         });
       };

       
    // Sort Handler
    const handleRequestSort = (event: any, property: any) =>
    {
    const isAsc = orderBy === property && order === "asc";
    order = isAsc ? "desc" : "asc";
    setOrder(order);
    orderBy = property;
    setOrderBy(orderBy);
    };


    //SOP-97 Included by PCS Handle Delete
      const handleDeleteFleet = (id: string) => {
        deleteAlert({
          title: "Delete",
          message: "Are you sure?",
          confirmButtonText: "Yes",
          onConfirmed: DeleteCategory,
          data: id,
        });
    };

    const handleChangeList = () => {
      if (page === 0) getimpactlistsearch();
      else setPage(0);
    };

    const DeleteCategory = (id: string) =>{
        commonService
        .postService(`${process.env.REACT_APP_BACKEND_URL}/adminportalMasters/ticketing_impact_create_update`, 
        {
          SaveUpdateKey: null,
          ImpactName: null,
          ImpactId: id,
          CompanyId:CompanyId,
          Active: null,
          CreatedDateTime: null,
          CreatedBy: UserId,
          IsRemove: 'Y'
        })
        .then((res: any) => {
          if (res.status === 200) {
              Success("Impact Deleted Successfully");
              handleChangeList();
              getimpactlistsearch();
          }
        })
        .catch((err) => {
          Failed("Reference information is found in the transaction table");
         
        });
     }
   //EOF SOP-97 Included By PCS

    // Action function of table icons
    const actionFunction = (id: string, actionText: string) => 
    {
        if (actionText === "edit"){
            handleEditImpactOpen(id);
        };
        if (actionText === "delete") handleDeleteFleet(id)//SOP-97 Included By PCS
       
    };
  
  // Search Handler of table
    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
      {
            const search = [...cells];
            const index = cells.findIndex((c: any) => c.id === e.target.name);
            if (index !== -1)
              {
                search[index].value = e.target.value;
              }
            //console.log("search",search)
              setCells(search);
              handleChangePage(setPage,0);
              getimpactlistsearch();
      };


    // Pagination Handler of table
    const handleChangePage = (event: unknown, newPage: number) => 
      {
       setPage(newPage);
      };

    // Rows Per page Handler
      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
          ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    useEffect(() => {
        getimpactlistsearch();
      }, [rowsPerPage, page, orderBy, order]); 
     

// Design start
    return(
        <>
        <MainContainerWrapper 
        headingText="Ticketing Impact"
        handleClick={() =>{
           handleAddImpactOpen()
        }}
        
        buttonText="Add Ticketing Impact"
        isSearch={false}
        isCreateButton={true}
        buttonIcon={<span>&#43;</span>}
        >
            <Box>
            <Box bgcolor={'white'}>
            <Box>
            <EnhancedTable
                headCells={ImpactHeadCell}
                rows={tableData}
                rowsPerPage={rowsPerPage}
                page={page}
                handleSearch={handleSearch}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                actionFunction={actionFunction}
                isEdit={true}
                isDelete={true}//SOP-97 Included By PCS
                total={count}
                isSearch={true}
                />
            </Box>

        </Box>
          {/* Add Impact type dialog box */}
            <Box>
                <Dialog  open={AddImpactOpen}  >
                    <AddTicketingImpact handleAddImpactClose={handleAddImpactClose}
                    getimpactlistsearch={getimpactlistsearch} />
                </Dialog>
            </Box>
            {/* End of Add impact type */}

            {/* Edit impact type dialog box */}
            <Box>
                <Dialog  open={EditImpactOpen}  >
                    <EditTicketingImpact handleEditImpactClose={handleEditImpactClose}
                    getimpactlistsearch={getimpactlistsearch} ImpactId={EditImpactId}  />
                </Dialog>
            </Box>
            {/* End of Edit Impact type */}
          </Box>
          <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
          <Button
              sx={{
                  background: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding:{
                      lg: "0 70px",
                      md: "0 70",
                      xs:'0 50px'

                  },
                  height: "3rem",
                  boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
              }}

              onClick={()=>{
                navigate('/backoffice')
              }} 
          >
              Back
          </Button>

          </Box>
        </MainContainerWrapper>
        </>
    )
// Design end
}

export default TicketingImpact;

// EOF SOP-97 Included by PCS [Ticketing Masters- Impact]
